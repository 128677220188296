import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import OpportunityService from "../../../app/service/opportunityService";
import { toast } from "react-toastify";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";
import {
  StepResourceTable,
  StepView,
} from "../../../app/common/commonControls";
import ProjectModal from "./projectModal";
import InitiativesModal from "./initiativesModal";
import PlanStepModal from "./stepModal";
import { PlusIcon } from "@heroicons/react/24/outline";
import Pulse from "../../../app/pulse/pulse";
interface Props {
  show: boolean;
  closeModal: (refresh?: boolean) => void;
  templates: OpportunityPlanResourceTaskTemplateItem[];
  resources: any[];
  resourceType?: string;
}

const defaultSteps = [
  {
    id: 1,
    name: "Resources",
    href: "#",
    status: commonService.stepStatus.CURRENT,
  },
  {
    id: 2,
    name: "Details",
    href: "#",
    status: commonService.stepStatus.UPCOMING,
  },
  {
    id: 3,
    name: "Select Template",
    href: "#",
    status: commonService.stepStatus.UPCOMING,
  },
  {
    id: 4,
    name: "Confirmation",
    href: "#",
    status: commonService.stepStatus.UPCOMING,
  },
];

export default function CloudResourcesModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCommandParameters, setSelectedCommandParameters] = useState<
    CommandParameterItem[]
  >([]);
  const [isNextButtonDisabled, setIsNextButtonDisabled] =
    useState<boolean>(false);

  const cancelButtonRef = useRef(null);
  const [templateValue, setTemplateValue] = useState<number | undefined>();
  const sequenceOrder: number = 1;

  const modalTitle = "Add Resource";
  const [note, setNote] = useState<string>("");
  const [isPageOneValid, setIsPageOneValid] = useState<boolean>(false);
  const [isPageThreeValid, setIsPageThreeValid] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [selectedTemplateDetail, setSelectedTemplateDetail] = useState<
    OpportunityPlanResourceTaskTemplateItem | any
  >({});

  const [steps, setSteps] = useState<any[]>([]);
  const [selectedStep, setSelectedStep] = useState<any>(defaultSteps[0]);
  const [projects, setProjects] = useState<ProjectItem[]>([]);

  useEffect(() => {
    setOpen(props.show);
    if (props?.show) {
      initStep();
      getAllProject();
    }
  }, [props.show]);

  const initStep = () => {
    setSteps(JSON.parse(JSON.stringify(defaultSteps)));
  };

  const getPlanList = (
    projectList: ProjectItem[],
    projectData: string
  ): OpportunityPlanItem[] => {
    return (
      (projectList || []).find((x) => x.id === projectData)?.opportunityPlan ||
      []
    );
  };

  const getStepList = (
    planList: OpportunityPlanItem[],
    planData: string
  ): OpportunityPlanStepItem[] => {
    return (
      (planList || []).find((x) => x.id === planData)?.opportunityPlanStep || []
    );
  };

  const getResourceList = (
    stepList: OpportunityPlanStepItem[],
    stepData: string
  ): OpportunityPlanResourceItem[] => {
    return (
      (stepList || []).find((x) => x.id === stepData)
        ?.opportunityPlanResources || []
    );
  };

  const getAllProject = (data?: any) => {
    setLoading(true);
    OpportunityService.getAllProject(commonService.getAccountId())
      .then((response: any) => {
        const result: ProjectItem[] = response?.data?.result || [];
        setProjects(result);
        if (data) {
          const projectData = data?.project;
          const planData = data?.plan;
          const stepData = data.step;

          setSelectedProject(projectData);
          const planList = getPlanList(result, projectData);
          setPlans(planList);

          setSelectedPlan(planData);
          const stepList = getStepList(planList, planData);
          setPlanSteps(stepList);

          setSelectedPlanStep(stepData);
          if (stepData) {
            const resourceList = getResourceList(stepList, stepData);
            setStepResources(parseResource(resourceList));
          }
        }
        setLoading(false);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  useEffect(() => {
    setIsPageThreeValid(!!selectedTemplate && !!templateValue);
    const selected = (props.templates || []).find(
      (x) => x.id === selectedTemplate
    );
    setSelectedTemplateDetail(selected ?? {});
    if (selected) {
      setSelectedCommandParameters(selected.commandParameters);
      if (selectedCommandParameters) {
        setIsNextButtonDisabled(
          selectedCommandParameters.some(
            (item: CommandParameterItem) =>
              item.isRequired && !item.isAutoFilled && !item.value
          )
        );
      }
    }
  }, [
    selectedTemplate,
    templateValue,
    selectedCommandParameters,
    isNextButtonDisabled,
  ]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const onStepSelect = (step: any) => {
    if (step?.id === 2 || step?.id === 3) {
      if (!isPageOneValid) {
        return;
      }
    }
    if (step?.id === 4) {
      if (!isPageOneValid || !isPageThreeValid) {
        return;
      }
    }
    setSelectedStep(step);
    steps.forEach((x) => {
      if (x.id === step.id) {
        x.status = commonService.stepStatus.CURRENT;
      } else if (x.id < step.id) {
        x.status = commonService.stepStatus.COMPLETE;
      } else {
        x.status = commonService.stepStatus.UPCOMING;
      }
    });
  };

  const onNext = () => {
    if (selectedStep?.id === 1) {
      if (!isPageOneValid) {
        return;
      } else {
        onStepSelect(steps[selectedStep.id]);
      }
    } else if (selectedStep?.id === 2) {
      onStepSelect(steps[selectedStep.id]);
    } else if (selectedStep?.id === 3) {
      if (!isPageThreeValid) {
        return;
      } else {
        onStepSelect(steps[selectedStep.id]);
      }
    }
  };

  const goBack = () => {
    onStepSelect(steps[selectedStep.id - 2]);
  };

  const addResource = () => {
    if (isPageOneValid && isPageThreeValid && !loading) {
      const resourceIds = (stepResources || [])
        .filter((x) => x.isNew)
        .map((x) => x.resourceId);
      if (resourceIds?.length > 0) {
        const body: CreateOrUpdateOpportunityPlanResourceRequest = {
          opportunityPlanStepId: selectedPlanStep || "",
          cspResourceIds: resourceIds,
          isActive: true,
        };
        setLoading(true);
        OpportunityService.createOpportunityPlanResource(body)
          .then((response: any) => {
            const result: OpportunityPlanResourceItem[] =
              response?.data?.result || [];
            addResourceTask(result);
          })
          .catch((error) => {
            toast.error(error.message);
            setLoading(false);
          });
      } else {
        addResourceTask([]);
      }
    }
  };

  const addResourceTask = (result: OpportunityPlanResourceItem[]) => {
    const requestList: CreateOrUpdateOpportunityPlanResourceTaskRequest[] = [];
    (stepResources || []).forEach((s: any) => {
      let id = "";
      let isValidRequest = true;
      if (s.isNew) {
        id =
          (result || []).find(
            (x) =>
              x.cspResourceId?.toString()?.toLowerCase() ===
              s.resourceId?.toString()?.toLowerCase()
          )?.id ?? "";
      } else {
        id = s?.resourceData?.id || "";
        const task = (s?.resourceData?.opportunityPlanResourceTask || []).find(
          (o: OpportunityPlanResourceTaskItem) =>
            o.opportunityPlanResourceTaskTemplateId ===
            selectedTemplateDetail?.id
        );
        isValidRequest = task?.value?.toString() !== templateValue?.toString();
      }
      if (isValidRequest) {
        const request: CreateOrUpdateOpportunityPlanResourceTaskRequest = {
          opportunityPlanResourceId: id,
          opportunityPlanResourceTaskTemplateId: selectedTemplateDetail?.id,
          hours: selectedTemplateDetail?.hours,
          value: templateValue || 0,
          sequenceOrder: sequenceOrder || 0,
          shouldBeCompletedInSequence: false,
          isActive: true,
          note: note || "",
          commandParameters: selectedCommandParameters,
        };
        requestList.push(request);
      }
    });
    if (requestList?.length > 0) {
      setLoading(true);
      OpportunityService.createOpportunityPlanResourceTask(requestList)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            closeModal(true);
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    } else {
      closeModal(true);
    }
  };

  const handleCommandParameterChange = (
    parameterName: string,
    value: string
  ) => {
    if (selectedCommandParameters) {
      const updatedParameters = [...selectedCommandParameters];
      let target = updatedParameters.find((x) => x.name === parameterName);
      if (target) {
        target.value = value;
      }

      setSelectedCommandParameters(updatedParameters);
      setIsNextButtonDisabled(
        updatedParameters.some(
          (item: CommandParameterItem) =>
            item.isRequired && !item.isAutoFilled && !!!item.value
        )
      );
    }
  };

  const [plans, setPlans] = useState<OpportunityPlanItem[]>([]);
  const [planSteps, setPlanSteps] = useState<OpportunityPlanStepItem[]>([]);

  const [selectedProject, setSelectedProject] = useState<string>();
  const [selectedPlan, setSelectedPlan] = useState<string>();
  const [selectedPlanStep, setSelectedPlanStep] = useState<string>();
  const [stepResources, setStepResources] = useState<any[]>([]);

  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showStepModal, setShowStepModal] = useState(false);

  const openProjectModal = () => {
    setShowProjectModal(true);
  };

  const closeProjectModal = () => {
    setShowProjectModal(false);
  };

  const openPlanModal = () => {
    setShowPlanModal(true);
  };

  const closePlanModal = () => {
    setShowPlanModal(false);
  };

  const openStepModal = () => {
    setShowStepModal(true);
  };

  const closeStepModal = () => {
    setShowStepModal(false);
  };

  const onChangeProject = (project: string) => {
    setSelectedProject(project);
    setSelectedPlan("");
    setSelectedPlanStep("");
    setPlans(getPlanList(projects, project) || []);
  };

  const onChangePlan = (plan: string) => {
    setSelectedPlan(plan);
    setSelectedPlanStep("");
    setPlanSteps(getStepList(plans, plan) || []);
  };

  const onChangeStep = (step: string) => {
    setSelectedPlanStep(step);
    if (step) {
      setStepResources(parseResource(getResourceList(planSteps, step) || []));
    }
  };

  useEffect(() => {
    setIsPageOneValid(
      !!selectedProject && !!selectedPlan && !!selectedPlanStep
    );
  }, [selectedProject, selectedPlan, selectedPlanStep]);

  const parseResource = (result: any[]) => {
    const resourceData: any[] = [];
    (props?.resources || []).forEach((x) => {
      const currentResource = result.find(
        (s) =>
          s.cspResourceId?.toString()?.toLowerCase() ===
          x.resourceId?.toString()?.toLowerCase()
      );
      const data: any = {
        ...x,
        isNew: !Boolean(currentResource),
        resourceData: currentResource,
      };
      resourceData.push(data);
    });
    return resourceData;
  };

  const isDisabled = () => loading || !isPageOneValid || !isPageThreeValid;

  const isNextBtnDisabled = () =>
    (selectedStep?.id === 1 && !isPageOneValid) ||
    (selectedStep?.id === 3 && !isPageThreeValid) ||
    (selectedStep?.id === 3 && isNextButtonDisabled);

  const refreshDetails = (data?: any) => {
    getAllProject(data);
  };

  const mainModalDisplay = () => {
    return showProjectModal || showPlanModal || showStepModal ? "none" : "initial";
  }

  return (
    <>
      {showProjectModal && (
        <ProjectModal
          show={showProjectModal}
          closeModal={closeProjectModal}
          fromResourceModal={true}
          refreshProject={refreshDetails}
        />
      )}
      {showPlanModal && (
        <InitiativesModal
          show={showPlanModal}
          closeModal={closePlanModal}
          project={{ id: selectedProject } as any}
          fromResourceModal={true}
          refreshProject={refreshDetails}
        />
      )}
      {showStepModal && (
        <PlanStepModal
          show={showStepModal}
          closeModal={closeStepModal}
          step={selectedStep}
          plan={{ id: selectedPlan, projectId: selectedProject } as any}
          fromResourceModal={true}
          refreshProject={refreshDetails}
        />
      )}

      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel
          className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6"
          style={{
            display: mainModalDisplay()
          }}
        >
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                {modalTitle}
              </Dialog.Title>
              <div className="mt-2">
                <StepView steps={steps} onStepSelect={onStepSelect} />
                <div className="space-y-6 mt-8 px-4">
                  {selectedStep?.id === 1 && (
                    <div>
                      <div className="mt-2">
                        {loading ? (
                          <Pulse show={loading} />
                        ) : (
                          <div>
                            <form className="space-y-6">
                              <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3 flex">
                                  <div className="w-full">
                                    <label
                                      htmlFor="project"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Choose Project
                                    </label>
                                    <div className="mt-1 mb-1">
                                      <select
                                        id="project"
                                        name="project"
                                        className={commonService.classNames(
                                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                          !selectedProject
                                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                            : ""
                                        )}
                                        value={selectedProject}
                                        required
                                        onChange={(e) =>
                                          onChangeProject(e?.target?.value)
                                        }
                                      >
                                        <option value={""} key={"option1"}>
                                          ---Choose Project---
                                        </option>
                                        {(projects || []).map(
                                          (project: ProjectItem) => (
                                            <option
                                              value={project.id}
                                              key={project.id}
                                            >
                                              {project.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    {!selectedProject && (
                                      <p
                                        className="mt-2 text-sm text-red-600"
                                        id="order-error"
                                      >
                                        Project is required.
                                      </p>
                                    )}
                                  </div>
                                  <button
                                    type="button"
                                    className="ml-4 relative rounded-md bg-indigo-600  text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-6"
                                    onClick={() => openProjectModal()}
                                    style={{
                                      top: "2rem",
                                    }}
                                  >
                                    <PlusIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                                <div className="sm:col-span-3 flex">
                                  <div className="w-full">
                                    <label
                                      htmlFor="plan"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Choose Plan
                                    </label>
                                    <div className="mt-1 mb-1">
                                      <select
                                        id="plan"
                                        name="plan"
                                        className={commonService.classNames(
                                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                          !selectedPlan
                                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                            : ""
                                        )}
                                        value={selectedPlan}
                                        required
                                        onChange={(e) =>
                                          onChangePlan(e?.target?.value)
                                        }
                                      >
                                        <option value={""} key={"option2"}>
                                          ---Choose Plan---
                                        </option>
                                        {(plans || []).map(
                                          (plan: OpportunityPlanItem) => (
                                            <option
                                              value={plan.id}
                                              key={plan.id}
                                            >
                                              {plan.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    {!selectedPlan && (
                                      <p
                                        className="mt-2 text-sm text-red-600"
                                        id="order-error"
                                      >
                                        Plan is required.
                                      </p>
                                    )}
                                  </div>
                                  {selectedProject && (
                                    <button
                                      type="button"
                                      className="ml-4 relative rounded-md bg-indigo-600  text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-6"
                                      onClick={() => openPlanModal()}
                                      style={{
                                        top: "2rem",
                                      }}
                                    >
                                      <PlusIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  )}
                                </div>
                                <div className="sm:col-span-3 flex">
                                  <div className="w-full">
                                    <label
                                      htmlFor="step"
                                      className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                      Choose Step
                                    </label>
                                    <div className="mt-1 mb-1">
                                      <select
                                        id="step"
                                        name="step"
                                        className={commonService.classNames(
                                          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                          !selectedPlanStep
                                            ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                            : ""
                                        )}
                                        value={selectedPlanStep}
                                        required
                                        onChange={(e) =>
                                          onChangeStep(e?.target?.value)
                                        }
                                      >
                                        <option value={""} key={"option3"}>
                                          ---Choose Step---
                                        </option>
                                        {(planSteps || []).map(
                                          (step: OpportunityPlanStepItem) => (
                                            <option
                                              value={step.id}
                                              key={step.id}
                                            >
                                              {step.name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                    {!selectedPlanStep && (
                                      <p
                                        className="mt-2 text-sm text-red-600"
                                        id="order-error"
                                      >
                                        Step is required.
                                      </p>
                                    )}
                                  </div>

                                  {selectedProject && selectedPlan && (
                                    <button
                                      type="button"
                                      className="ml-4 relative rounded-md bg-indigo-600  text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-6"
                                      onClick={() => openStepModal()}
                                      style={{
                                        top: "2rem",
                                      }}
                                    >
                                      <PlusIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {selectedStep?.id === 2 && (
                    <div>
                      <div className="mt-2">
                        <div>
                          <StepResourceTable data={stepResources || []} resourceType={props?.resourceType} />
                        </div>
                      </div>
                    </div>
                  )}

                  {selectedStep?.id === 3 && (
                    <div id="cloud-template-sec-id">
                      <div className="mt-2">
                        <div>
                          <form className="space-y-6">
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="cloudResTemplate"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Choose Template
                                </label>
                                <div className="mt-1 mb-1">
                                  <select
                                    id="cloudResTemplate"
                                    name="cloudResTemplate"
                                    className={commonService.classNames(
                                      "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                      !selectedTemplate
                                        ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                        : ""
                                    )}
                                    value={selectedTemplate}
                                    required
                                    onChange={(e) =>
                                      setSelectedTemplate(e?.target?.value)
                                    }
                                  >
                                    <option value={""} key={"option1"}>
                                      ---Choose Template---
                                    </option>
                                    {props.templates.map(
                                      (
                                        template: OpportunityPlanResourceTaskTemplateItem
                                      ) => (
                                        <option
                                          value={template.id}
                                          key={template.id}
                                        >
                                          {template.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                {!selectedTemplate && (
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="order-error"
                                  >
                                    Template is required.
                                  </p>
                                )}
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="cloudResValue"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Value
                                </label>
                                <div className="mt-2">
                                  <input
                                    id="cloudResValue"
                                    name="cloudResValue"
                                    type="number"
                                    required
                                    value={templateValue}
                                    onChange={(e) =>
                                      setTemplateValue(e?.target?.value as any)
                                    }
                                    className={commonService.classNames(
                                      "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                      !templateValue
                                        ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                        : ""
                                    )}
                                  />
                                </div>
                                {!templateValue && (
                                  <p
                                    className="mt-2 text-sm text-red-600"
                                    id="order-error"
                                  >
                                    Value is required.
                                  </p>
                                )}
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="cloudResNote"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Note
                                </label>
                                <div className="mt-2">
                                  <input
                                    id="cloudResNote"
                                    name="cloudResNote"
                                    type="text"
                                    required
                                    value={note}
                                    onChange={(e) =>
                                      setNote(e?.target?.value as any)
                                    }
                                    className={commonService.classNames(
                                      "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    )}
                                  />
                                </div>
                              </div>

                              {selectedTemplateDetail?.id && (
                                <>
                                  <div className="sm:col-span-6" id="cloudResDesc">
                                    <div className="sm:gap-4 sm:px-0 flex">
                                      <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Description:
                                      </dt>
                                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {selectedTemplateDetail?.description}
                                      </dd>
                                    </div>
                                  </div>
                                  <div className="sm:col-span-6" id="cloudResCommand">
                                    <div className="sm:gap-4 sm:px-0 flex">
                                      <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Base Command:
                                      </dt>
                                      {selectedTemplateDetail?.command && (
                                        <dd className="mt-1 text-sm leading-6 text-gray-100 bg-gray-900 sm:col-span-2 sm:mt-0 rounded-lg px-3 py-1">
                                          {selectedTemplateDetail?.command}
                                        </dd>
                                      )}
                                    </div>
                                  </div>

                                  <div className="sm:col-span-6" id="cloudResParams">
                                    <div className="sm:gap-4 sm:px-0 flex">
                                      <dt className="text-sm font-medium leading-6 text-gray-900">
                                        Command Parameters:
                                      </dt>
                                    </div>
                                  </div>

                                  {selectedTemplateDetail?.commandParameters.map(
                                    (item: CommandParameterItem) =>
                                      item.isAutoFilled ? null : (
                                        <div
                                          key={item.name}
                                          className="sm:col-span-6 flex items-center cloudResParams"
                                        >
                                          <div className="w-32 pr-4">
                                            <dt className="text-sm font-medium leading-6 text-gray-900 text-left">
                                              {item.name}
                                              {item.isRequired && (
                                                <span className="text-red-500">
                                                  *
                                                </span>
                                              )}
                                              :
                                            </dt>
                                          </div>
                                          <dd
                                            className="mt-1 text-sm leading-6 text-gray-100 sm:col-span-2 sm:mt-0 rounded-lg px-3 py-1"
                                            id="cloudResourceCommandBox"
                                          >
                                            <input
                                              type="text"
                                              value={item.value}
                                              id="cloudResourceCommandText1"
                                              onChange={(e) =>
                                                handleCommandParameterChange(
                                                  item.name,
                                                  e.target.value
                                                )
                                              }
                                              className={commonService.classNames(
                                                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                              )}
                                              required={item.isRequired}
                                            />
                                          </dd>
                                        </div>
                                      )
                                  )}
                                </>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedStep?.id === 4 && (
                    <div>
                      <div className="mt-2">
                        <div>
                          <div>
                            <div className="px-4 sm:px-0">
                              <h3 className="text-base font-semibold leading-7 text-gray-900">
                                Selected Resources:
                              </h3>
                            </div>
                            <StepResourceTable data={stepResources || []} resourceType={props?.resourceType} />
                            <div className="px-6 sm:px-0 mt-4 cloudResSec">
                              <h3 className="text-base font-semibold leading-7 text-gray-900">
                                Selected Template:
                              </h3>
                            </div>

                            <div className="mt-6 border-t border-gray-100" id="cloudResConfirm">
                              <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                  <dt className="text-sm font-medium leading-6 text-gray-900" id="cloudResName">
                                    Name
                                  </dt>
                                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {selectedTemplateDetail?.name}
                                  </dd>
                                  <dt className="text-sm font-medium leading-6 text-gray-900" id="cloudResDesc">
                                    Description
                                  </dt>
                                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {selectedTemplateDetail?.description}
                                  </dd>
                                  <dt className="text-sm font-medium leading-6 text-gray-900" id="cloudResValue">
                                    Value
                                  </dt>
                                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {templateValue}
                                  </dd>
                                  <dt className="text-sm font-medium leading-6 text-gray-900" id="cloudResSeq">
                                    Sequence Order
                                  </dt>
                                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {sequenceOrder}
                                  </dd>
                                </div>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className={commonService.classNames(
              "mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3",
              selectedStep?.id === 1 ? "sm:grid-cols-2" : "sm:grid-cols-3"
            )}
          >
            {selectedStep?.id === 4 && (
              <button
                type="submit"
                disabled={isDisabled()}
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-3",
                  isDisabled() ? "disabled:opacity-75" : ""
                )}
                onClick={() => addResource()}
              >
                <Spinner show={loading} />
                Add
              </button>
            )}
            {selectedStep?.id < steps.length && (
              <button
                type="button"
                id="cloudResourceNextBtn"
                disabled={isNextBtnDisabled()}
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                  selectedStep?.id === 1 ? "sm:col-start-2" : "sm:col-start-3",
                  isNextBtnDisabled() ? "disabled:opacity-75" : ""
                )}
                onClick={() => onNext()}
              >
                Next
              </button>
            )}
            {selectedStep?.id > 1 && (
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
                onClick={() => goBack()}
                id="cloudResourceBackBtn"
              >
                Back
              </button>
            )}
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
              id="cloudResourceCancelBtn"
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}
