import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { CommonLineChart } from "../../common/lineChart";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";
import { FlagIndicator } from "../../../app/common/commonControls";
import { Tooltip } from "react-tooltip";
import { ArrowTopRightOnSquareIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import TooltipText from "../../tooltip";
import { VmAdvisorRecommendationsTooltip } from "../../../app/common/tooltipControl";
import VmAdvisorDetailModal from "./vmAdvisorDetailModal";
import ComplianceModal from "./complianceModal";

interface Props {
  show: boolean;
  closeModal: () => void;
  data: AzureStorageAccountItemDetail | any;
}

export default function StorageAccountModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);

  const [ingressChartData, setIngressChartData] = useState<
    Array<{ date: string; Ingress: number }>
  >([]);
  const [egressChartData, setEgressChartData] = useState<
    Array<{ date: string; Egress: number }>
  >([]);
  const [latencyChartData, setLatencyChartData] = useState<
    Array<{ date: string; Latency: number }>
  >([]);
  const [transactionsChartData, setTransactionsChartData] = useState<
    Array<{ date: string; Transactions: number }>
  >([]);
  const [capacityChartData, setCapacityChartData] = useState<
    Array<{ date: string; Capacity: number }>
  >([]);
  const rangeList: string[] = ["minimum", "average", "maximum"];
  const [selectedRange, setSelectedRange] = useState<string>(rangeList[0]);

  const [storageData, setStorageData] = useState<
    AzureStorageAccountItemDetail
  >(props?.data);

  const [showAdvisorDetailModal, setShowAdvisorDetailModal] = useState<boolean>(false);
  const [advisorDetailsData, setAdvisorDetailsData] = useState<AzureResourceAdvisorRecommendationItemDetail[]>([]);
  const [advisorDetailData, setAdvisorDetailData] = useState<AzureResourceAdvisorRecommendationItemDetail>();
  const [isModalFromTooltip, setIsModalFromTooltip] = useState<boolean>(false);
  const [vmName, setVmName] = useState<string>('');
  
  const [showComplianceModal, setShowComplianceModal] = useState<boolean>(false);
  const [complianceModalData, setComplianceModalData] = useState<any[]>([]);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      init();
    }
  }, [props.show]);

  const init = () => {
    setSelectedRange(rangeList[0]);
    setStorageData(props?.data || {});
    setChart(props?.data || {}, rangeList[0]);
  };

  const setChart = (result: AzureStorageAccountItemDetail, range: string) => {
    const metrics = result?.metrics || [];
    metrics.forEach((x: any) => {
      if (x.name === "Ingress") {
        setIngressChartData(commonService.getChartData(x, range, "Ingress"));
      } else if (x.name === "Egress") {
        setEgressChartData(commonService.getChartData(x, range, "Egress"));
      } else if (x.name === "SuccessE2ELatency") {
        setLatencyChartData(
          commonService.getChartData(x, range, "SuccessE2ELatency")
        );
      } else if (x.name === "Transactions") {
        setTransactionsChartData(
          commonService.getChartData(x, range, "Transactions")
        );
      } else if (x.name === "UsedCapacity") {
        setCapacityChartData(
          commonService.getChartData(x, range, "UsedCapacity")
        );
      }
    });
  };

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const onChangeRange = (range: string) => {
    setSelectedRange(range);
    setChart(storageData, range);
  };

  const onRecommendationClick = (vmName: string, recommendation: AzureResourceAdvisorRecommendationItemDetail) => {
    setVmName(vmName);
    setAdvisorDetailData(recommendation);
    setAdvisorDetailsData([]);
    setIsModalFromTooltip(true);
    openAdvisorDetailodal();
  }

  const onRecommendationCountClick = (vmName: string, recommendations: AzureResourceAdvisorRecommendationItemDetail[]) => {
    setVmName(vmName);
    setAdvisorDetailData({} as any);
    setAdvisorDetailsData(recommendations);
    setIsModalFromTooltip(false);
    openAdvisorDetailodal();
  }

  const openAdvisorDetailodal = () => {
    setShowAdvisorDetailModal(true);
  }

  const closeAdvisorDetailModal = () => {
    setShowAdvisorDetailModal(false);
  };

  const openComplianceModal = (data: any[]) => {
    setComplianceModalData(data || []);
    setShowComplianceModal(true);
  }

  const closeComplianceModal = () => {
    setShowComplianceModal(false);
  };

  return (
    <>
    {showAdvisorDetailModal && (
        <VmAdvisorDetailModal
          show={showAdvisorDetailModal}
          closeModal={closeAdvisorDetailModal}
          vmName={vmName}
          recommendationItems={advisorDetailsData}
          recommendationItem={advisorDetailData as any}
          isModalFromTooltip={isModalFromTooltip}
        />
      )}

      {showComplianceModal && (
        <ComplianceModal
          show={showComplianceModal}
          closeModal={closeComplianceModal}
          complianceData={complianceModalData || []}
        />
      )}

      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-7xl sm:p-6">
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                Storage Account - Details
              </Dialog.Title>
              <div className="mt-2">
                <div>
                  <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
                    <li
                      key="1"
                      className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                    >
                      <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          Basic
                        </div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Name</dt>
                          <dd className="text-gray-700">{storageData?.name}</dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">ID</dt>
                          <dd className="text-gray-700" style={{ overflowWrap: "anywhere" }}>{storageData?.id}</dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Sku</dt>
                          <dd className="text-gray-700">
                            {storageData?.sku?.name} ({storageData?.kind})
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Location</dt>
                          <dd className="text-gray-700">
                            {storageData?.location}
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Access Tier</dt>
                          <dd className="text-gray-700">
                            {storageData?.properties?.accessTier}
                          </dd>
                        </div>
                      </dl>
                    </li>

                    <li
                      key="2"
                      className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                    >
                      <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          Network
                        </div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">HTTPS Only</dt>
                          <dd className="text-gray-700">
                            <FlagIndicator
                              data={
                                storageData?.properties?.supportsHttpsTrafficOnly
                              }
                            />
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Public Access</dt>
                          <dd className="text-gray-700">
                            {storageData?.properties?.publicNetworkAccess}
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Private Endpoint</dt>
                          <dd className="text-gray-700">
                            {
                              storageData?.properties?.privateEndpointConnections
                                ?.length
                            }
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">ACL VNETS</dt>
                          <dd className="text-gray-700">
                            {
                              storageData?.properties?.networkAcls
                                ?.virtualNetworkRules?.length
                            }
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">ACL Default</dt>
                          <dd className="text-gray-700">
                            {storageData?.properties?.networkAcls?.defaultAction}
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Min TLS</dt>
                          <dd className="text-gray-700">
                            {storageData?.properties?.minimumTlsVersion}
                          </dd>
                        </div>
                      </dl>
                    </li>

                    <li
                      key="3"
                      className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                    >
                      <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          Public Blob
                        </div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Public Blob</dt>
                          <dd className="text-gray-700">
                            <FlagIndicator
                              data={
                                storageData?.properties?.allowBlobPublicAccess
                              }
                            />
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">OAuth Default</dt>
                          <dd className="text-gray-700">
                            <FlagIndicator
                              data={
                                storageData?.properties
                                  ?.defaultToOAuthAuthentication
                              }
                            />
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Allow SAS</dt>
                          <dd className="text-gray-700">
                            <FlagIndicator
                              data={storageData?.properties?.allowSharedKeyAccess}
                            />
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">
                            Infrastructure Encryption
                          </dt>
                          <dd className="text-gray-700">
                            <FlagIndicator
                              data={
                                storageData?.properties?.encryption
                                  ?.requireInfrastructureEncryption
                              }
                            />
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Blob Encryption</dt>
                          <dd className="text-gray-700">
                            <FlagIndicator
                              data={
                                storageData?.properties?.encryption?.services
                                  ?.blob?.enabled
                              }
                            />
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">File Encryption</dt>
                          <dd className="text-gray-700">
                            <FlagIndicator
                              data={
                                storageData?.properties?.encryption?.services
                                  ?.file?.enabled
                              }
                            />
                          </dd>
                        </div>
                      </dl>
                    </li>

                    <li
                      key="4"
                      className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                    >
                      <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          FinOps
                        </div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Size</dt>
                            <dd className="text-gray-700">
                              <a href="https://github.com/MicrosoftDocs/azure-docs/blob/main/includes/azure-storage-account-limits-standard.md" target={"_blank"} rel="noreferrer" className="flex items-center">
                                <ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1" />
                              </a>
                            </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Cost</dt>
                          <dd className="text-gray-700">
                            <span
                              className="flex items-center cursor-pointer"
                              data-tooltip-id={"-storage-modal-cost-tooltip"}
                            >
                              ${storageData?.costVal}
                              <InformationCircleIcon
                                aria-hidden="true"
                                className="w-4 h-4 ml-1"
                              />
                            </span>
                            <Tooltip
                              id={"-storage-modal-cost-tooltip"}
                              arrowColor="transparent"
                              place="bottom"
                            >
                              <div style={{}}>
                                {(
                                  Object.keys(storageData?.tooltipVal || {}) || []
                                ).map((tooltipVal: any) => (
                                  <>
                                    <span>
                                      {tooltipVal}: $
                                      {commonService.fixDecimal(
                                        storageData?.tooltipVal[tooltipVal] || 0
                                      )}
                                    </span>{" "}
                                    <br />
                                  </>
                                ))}
                              </div>
                            </Tooltip>
                          </dd>
                        </div>
                      </dl>
                    </li>

                    <li
                      key="5"
                      className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                    >
                      <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          Governance
                        </div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Policy</dt>
                          <dd className="text-gray-700">
                            <>
                              {storageData?.complianceCount && storageData?.nonComplianceCount ? (
                                <div
                                  className="cursor-pointer"
                                  data-tooltip-id={
                                    "-storage-modal-compliance-tooltip"
                                  }
                                  onClick={() => openComplianceModal(storageData?.complianceList)}
                                >
                                  <span className="text-green-700">
                                    {storageData?.complianceCount}
                                  </span>
                                  <span> | </span>
                                  <span className="text-red-700">
                                    {storageData?.nonComplianceCount}
                                  </span>
                                  <Tooltip
                                    id={"-storage-modal-compliance-tooltip"}
                                    arrowColor="transparent"
                                    place="top"
                                  >
                                    <div
                                      style={{}}
                                      className="whitespace-pre-line"
                                    >
                                      {storageData?.complianceTooltipText}
                                    </div>
                                  </Tooltip>
                                </div>
                              ) : (
                                <>
                                  <InformationCircleIcon
                                    aria-hidden="true"
                                    className="w-4 h-4 ml-1"
                                    data-tooltip-id={
                                      "-storage-modal-compliance-empty-tooltip"
                                    }
                                  />
                                  <TooltipText id={"-storage-modal-compliance-empty-tooltip"} tooltipText="No Data Found." />
                                </>
                              )}
                            </>
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Advisor</dt>
                          <dd className="text-gray-700">
                          <div className="cursor-pointer">
                            <VmAdvisorRecommendationsTooltip title="Reliability" vmName={storageData?.name} idText={"-storage-modal-recommendationDetails-reliability-tooltip"} recommendations={storageData?.recommendationDetails?.reliability?.recommendationList || []} onRecommendationClick={onRecommendationClick} onRecommendationCountClick={onRecommendationCountClick} />
                            <span> | </span>
                            <VmAdvisorRecommendationsTooltip title="Security" vmName={storageData?.name} idText={"-storage-modal-recommendationDetails-security-tooltip"} recommendations={storageData?.recommendationDetails?.security?.recommendationList || []} onRecommendationClick={onRecommendationClick} onRecommendationCountClick={onRecommendationCountClick} />
                            <span> | </span>
                            <VmAdvisorRecommendationsTooltip title="Performance" vmName={storageData?.name} idText={"-storage-modal-recommendationDetails-performance-tooltip"} recommendations={storageData?.recommendationDetails?.performance?.recommendationList || []} onRecommendationClick={onRecommendationClick} onRecommendationCountClick={onRecommendationCountClick} />
                            <span> | </span>
                            <VmAdvisorRecommendationsTooltip title="Operational Excellence" vmName={storageData?.name} idText={"-storage-modal-recommendationDetails-operationalExcellence-tooltip"} recommendations={storageData?.recommendationDetails?.operationalExcellence?.recommendationList || []} onRecommendationClick={onRecommendationClick} onRecommendationCountClick={onRecommendationCountClick} />
                            <span> | </span>
                            <VmAdvisorRecommendationsTooltip title="Cost" vmName={storageData?.name} idText={"-storage-modal-recommendationDetails-cost-tooltip"} recommendations={storageData?.recommendationDetails?.cost?.recommendationList || []} onRecommendationClick={onRecommendationClick} onRecommendationCountClick={onRecommendationCountClick} />
                          </div>
                          </dd>
                        </div>
                      </dl>
                    </li>

                    <li
                      key="6"
                      className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                    >
                      <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          Additional Info
                        </div>
                      </div>
                      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Provisioning State</dt>
                          <dd className="text-gray-700">
                            {storageData?.properties?.provisioningState}
                          </dd>
                        </div>
                        {(Object.keys(storageData?.properties?.primaryEndpoints || {})).map(
                                (key: string) => (
                                  <div className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">Primary Endpoints: {key}</dt>
                                    <dd className="text-gray-700">
                                      {(storageData?.properties?.primaryEndpoints as any)[key]}
                                    </dd>
                                  </div>
                                )
                              )}
                        
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Allow Cross Tenant Replication</dt>
                          <dd className="text-gray-700">
                          <FlagIndicator
                              data={
                                storageData?.properties?.allowCrossTenantReplication
                              }
                            />
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Status Of Primary</dt>
                          <dd className="text-gray-700">
                            {
                              storageData?.properties?.statusOfPrimary
                            }
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Creation Time</dt>
                          <dd className="text-gray-700">
                            {new Date(storageData?.properties?.creationTime || '').toLocaleDateString()}
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Hns Enabled</dt>
                          <dd className="text-gray-700">
                          <FlagIndicator
                              data={
                                storageData?.properties?.isHnsEnabled
                              }
                            />
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Dns Endpoint Type</dt>
                          <dd className="text-gray-700">
                            {storageData?.properties?.dnsEndpointType}
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Nfs V3 Enabled</dt>
                          <dd className="text-gray-700">
                          <FlagIndicator
                              data={
                                storageData?.properties?.isNfsV3Enabled
                              }
                            />
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Sftp Enabled</dt>
                          <dd className="text-gray-700">
                          <FlagIndicator
                              data={
                                storageData?.properties?.isSftpEnabled
                              }
                            />
                          </dd>
                        </div>
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Local User Enabled</dt>
                          <dd className="text-gray-700">
                          <FlagIndicator
                              data={
                                storageData?.properties?.isLocalUserEnabled
                              }
                            />
                          </dd>
                        </div>

                      </dl>
                    </li>

                    <li
                      key="7"
                      className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                    >
                      <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                          Tags
                        </div>
                      </div>

                      <p className="gap-x-4 px-6 py-4">
                        {Object.keys(storageData?.tags || {}).map((key: any) => (
                          <span className="text-sm leading-6 mr-2" key={key}>
                            <span className="mb-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              {`${key} : ${(storageData?.tags || {})[key]}`}
                            </span>
                          </span>
                        ))}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="mt-6 border-t border-gray-100">
                  <div>
                    <label
                      htmlFor="storage-timeZone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Range
                    </label>
                    <div className="mt-1 mb-1">
                      <select
                        id="storage-timeZone"
                        name="timezone"
                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                        value={selectedRange}
                        onChange={(e) => onChangeRange(e?.target?.value)}
                      >
                        {rangeList.map((range: string) => (
                          <option value={range} key={range}>
                            {range}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                        <div className="flex items-center gap-x-4 bg-white p-2">
                          <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                            Ingress
                          </p>
                        </div>
                        <div
                          className="flex shrink-0 items-center gap-x-4"
                          style={{ width: "100%", height: "125px" }}
                        >
                          <CommonLineChart
                            chartData={ingressChartData}
                            xAxisKey="date"
                            lineKey="Ingress"
                          />
                        </div>
                      </div>

                      <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                        <div className="flex items-center gap-x-4 bg-white p-2">
                          <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                            Egress
                          </p>
                        </div>
                        <div
                          className="flex shrink-0 items-center gap-x-4"
                          style={{ width: "100%", height: "125px" }}
                        >
                          <CommonLineChart
                            chartData={egressChartData}
                            xAxisKey="date"
                            lineKey="Egress"
                          />
                        </div>
                      </div>

                      <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                        <div className="flex items-center gap-x-4 bg-white p-2">
                          <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                            SuccessE2ELatency
                          </p>
                        </div>
                        <div
                          className="flex shrink-0 items-center gap-x-4"
                          style={{ width: "100%", height: "125px" }}
                        >
                          <CommonLineChart
                            chartData={latencyChartData}
                            xAxisKey="date"
                            lineKey="SuccessE2ELatency"
                          />
                        </div>
                      </div>

                      <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                        <div className="flex items-center gap-x-4 bg-white p-2">
                          <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                            Transactions
                          </p>
                        </div>
                        <div
                          className="flex shrink-0 items-center gap-x-4"
                          style={{ width: "100%", height: "125px" }}
                        >
                          <CommonLineChart
                            chartData={transactionsChartData}
                            xAxisKey="date"
                            lineKey="Transactions"
                          />
                        </div>
                      </div>

                      <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                        <div className="flex items-center gap-x-4 bg-white p-2">
                          <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                            Capacity
                          </p>
                        </div>
                        <div
                          className="flex shrink-0 items-center gap-x-4"
                          style={{ width: "100%", height: "125px" }}
                        >
                          <CommonLineChart
                            chartData={capacityChartData}
                            xAxisKey="date"
                            lineKey="UsedCapacity"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}
