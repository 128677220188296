import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import OpportunityService from "../../../app/service/opportunityService";
import ConfirmationModal from "../../reports/modal/confirmationModal";
import ResourceDetialsTable from "../details/resourceDetailsTable";
import { ModalWrapper } from "../../common/modalWrapper";
import { TabsView } from "../../../app/common/commonControls";
interface Props {
  show: boolean;
  closeModal?: any;
  templates: OpportunityPlanResourceTaskTemplateItem[] | undefined;
  isEdit?: boolean;
  resource: OpportunityPlanResourceItem | undefined;
  task: OpportunityPlanResourceTaskItem;
  date?: string;
}

const tabs = [
  { name: "Template", href: "#", current: true },
  { name: "Resource", href: "#", current: false },
];

const deleteTaskModal = {
  title: "Delete Task",
  message: "Are you sure you want to delete the Task",
};

const errorState: any = {
  value: { hasError: false, required: true },
};

export default function TemplateModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [hasError, setHasError] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(errorState))
  );
  const [templates, setTemplates] = useState<
    OpportunityPlanResourceTaskTemplateItem[]
  >([]);
  const [selectedCommandParameters, setSelectedCommandParameters] = useState<
    CommandParameterItem[]
  >(props.task?.opportunityPlanResourceTaskTemplate?.commandParameters || []);
  const [isNextButtonDisabled, setIsNextButtonDisabled] =
    useState<boolean>(false);

  const cancelButtonRef = useRef(null);

  const modalTitle = props?.isEdit ? `Edit Task:  ${props.task?.opportunityPlanResourceTaskTemplate?.name}` : "Add Task";
  const modalBtnText = props?.isEdit ? "Update" : "Create";

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      init();
      checkError();
      setSelectedTemplate("");
      setSelectedCommandParameters([]);
      if (!props?.isEdit) {
        const filterTemplates = (props?.templates ?? []).filter((x) => {
          return !(props.resource?.opportunityPlanResourceTask ?? []).find(
            (y) => y.opportunityPlanResourceTaskTemplateId === x.id
          );
        });
        setTemplates(filterTemplates);
      }
      setCurrentTabSelection(tabs[0].name);
      if (props.isEdit) {
        initCommandParameters();
      }
    }
  }, [props.show]);

  const init = () => {
    setTask(initTask());
  };

  const initTask = (): OpportunityPlanResourceTaskItem => {
    if (props.isEdit) {
      return JSON.parse(JSON.stringify(props.task || {})) || {};
    }
    return {
      id: "",
      opportunityPlanResourceId: props.resource?.id ?? "",
      opportunityPlanResourceTaskTemplate: {} as any,
      hours: null as any,
      opportunityPlanResourceTaskTemplateId: "",
      value: undefined as any,
      completedBy: null as any,
      completedOn: null as any,
      isActive: true,
      sequenceOrder:
        (props?.resource?.opportunityPlanResourceTask ?? []).length + 1,
      shouldBeCompletedInSequence: false,
      note: "",
      command: props.task.command,
      commandParameters: props.task.commandParameters,
    };
  };

  useEffect(() => {
    if (props.isEdit) {
      if (selectedCommandParameters) {
        setIsNextButtonDisabled(
          selectedCommandParameters.some(
            (item: CommandParameterItem) =>
              item.isRequired && !item.isAutoFilled && !item.value
          )
        );
      }
    }
  }, [selectedCommandParameters, isNextButtonDisabled]);

  const initCommandParameters = () => {
    const keyValuePairs = props.task?.commandParameters?.split(",") || [];
    const updatedParameters = [
      ...(props.task?.opportunityPlanResourceTaskTemplate?.commandParameters ||
        ""),
    ];

    for (const pair of keyValuePairs) {
      const [key, value] = pair.split(":");
      if (key && value) {
        let target = updatedParameters.find((x) => x.name === key);
        if (target) {
          target.value = value;
        }
      }
    }
    setSelectedCommandParameters(updatedParameters);
  };

  const [task, setTask] = useState<OpportunityPlanResourceTaskItem | any>(
    initTask()
  );
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");

  useEffect(() => {
    checkError();
  }, [task]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const addTemplate = () => {
    const request: CreateOrUpdateOpportunityPlanResourceTaskRequest = {
      opportunityPlanResourceId: task.opportunityPlanResourceId,
      opportunityPlanResourceTaskTemplateId:
        task?.opportunityPlanResourceTaskTemplateId,
      hours: task?.hours,
      value: task.value,
      sequenceOrder: task.sequenceOrder || 0,
      shouldBeCompletedInSequence: task.shouldBeCompletedInSequence || false,
      isActive: task.isActive,
      completedOn: task.completedOn,
      completedBy: task.completedBy,
      note: task.note,
      commandParameters: selectedCommandParameters,
    };
    if (props?.isEdit) {
      request.id = task?.id;
    }
    setLoading(true);
    if (props?.isEdit) {
      OpportunityService.updateOpportunityPlanResourceTask(request)
        .then((response: any) => onGetResult(response))
        .catch((e: any) => {
          commonService.showResponseError(e);
          setLoading(false);
        });
    } else {
      OpportunityService.createOpportunityPlanResourceTask([request])
        .then((response: any) => onGetResult(response))
        .catch((e: any) => {
          setLoading(false);
          commonService.showResponseError(e);
        });
    }
  };

  const onGetResult = (response: any) => {
    setLoading(false);
    commonService.showMessage(response?.data || {});
    if (response?.data?.isSuccess) {
      closeModal(true);
    }
  };

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};
    field.hasError = !value;
    const model = JSON.parse(JSON.stringify(task));
    model[fieldName] = value;
    setTask(model);
    error[fieldName] = field;
  };

  const checkError = () => {
    let isError = false;
    for (const key of Object.keys(errorState) || []) {
      if (errorState[key]?.required && !task[key]) {
        isError = true;
      }
    }
    setHasError(isError);
  };

  const onChangeTemplate = (e: string) => {
    setSelectedTemplate(e);
    const currentSelection = (templates || []).find((x) => x.id === e);
    if(currentSelection){
      task.opportunityPlanResourceTaskTemplate = currentSelection;
      task.hours = currentSelection.hours;
      task.opportunityPlanResourceTaskTemplateId = currentSelection.id;
      task.command = currentSelection.command;
      setSelectedCommandParameters(currentSelection.commandParameters)
    }
  };

  const [showDeleteTaskModal, setShowDeleteTaskModal] =
    useState<boolean>(false);
  const [loadingDeleteTask, setLoadingDeleteTask] = useState<boolean>(false);
  const [selectedTaskForDelete, setSelectedTaskForDelete] = useState<string>();
  const [deleteMessage, setDeleteMessage] = useState<string>("");

  const openDeleteTaskModal = () => {
    setDeleteMessage(
      `${deleteTaskModal.message}: ${props?.task?.opportunityPlanResourceTaskTemplate?.name} ?`
    );
    setSelectedTaskForDelete(task.id);
    setShowDeleteTaskModal(true);
  };

  const closeDeleteTaskModal = (e?: any) => {
    e?.preventDefault();
    setShowDeleteTaskModal(false);
  };

  const onDeleteTask = () => {
    if (selectedTaskForDelete) {
      setLoadingDeleteTask(true);
      OpportunityService.deleteOpportunityPlanResourceTask(
        selectedTaskForDelete
      )
        .then((response: any) => {
          commonService.showMessage(response?.data || {});
          setLoadingDeleteTask(false);
          if (response?.data?.isSuccess) {
            closeDeleteTaskModal();
            closeModal(true);
          }
        })
        .catch((e: any) => {
          commonService.showResponseError(e);
          setLoadingDeleteTask(false);
        });
    }
  };

  const [currentTabSelection, setCurrentTabSelection] = useState<string>(
    tabs[0]?.name
  );

  const handleCommandParameterChange = (
    parameterName: string,
    value: string
  ) => {
    if (selectedCommandParameters) {
      const updatedParameters = [...selectedCommandParameters];
      let target = updatedParameters.find((x) => x.name === parameterName);
      if (target) {
        target.value = value;
      }

      setSelectedCommandParameters(updatedParameters);
      setIsNextButtonDisabled(
        updatedParameters.some(
          (item: CommandParameterItem) =>
            item.isRequired && !item.isAutoFilled && !!!item.value
        )
      );
    }
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteTaskModal}
        title={deleteTaskModal.title}
        message={deleteMessage}
        loading={loadingDeleteTask}
        closeModal={(e) => closeDeleteTaskModal(e)}
        save={() => onDeleteTask()}
      />
      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                {modalTitle}
              </Dialog.Title>
              <div className="mt-2">
                <TabsView tabs={tabs} currentTabSelection={currentTabSelection} setCurrentTabSelection={setCurrentTabSelection} />

                <div
                  className={commonService.classNames(
                    currentTabSelection!=="Template" ? "hidden" : ""
                  )}
                >
                  <form className="space-y-6">
                    <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      {!props?.isEdit && (
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="status"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Choose Template
                          </label>
                          <div className="mt-1 mb-1">
                            <select
                              id="status"
                              name="status"
                              className={commonService.classNames(
                                "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                                !selectedTemplate
                                  ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                  : ""
                              )}
                              value={selectedTemplate}
                              required
                              onChange={(e) =>
                                onChangeTemplate(e?.target?.value)
                              }
                            >
                              <option value={""} key={"option1"}>
                                ---Choose Template---
                              </option>
                              {(templates || []).map(
                                (
                                  template: OpportunityPlanResourceTaskTemplateItem
                                ) => (
                                  <option value={template.id} key={template.id}>
                                    {template.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          {!selectedTemplate && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="order-error"
                            >
                              Template is required.
                            </p>
                          )}
                        </div>
                      )}

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="hours"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Hours
                        </label>
                        <div className="mt-2">
                          <input
                            id="templateValue"
                            name="templateValue"
                            type="number"
                            required
                            value={task.hours}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            disabled
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Value
                        </label>
                        <div className="mt-2">
                          <input
                            id="templateValue"
                            name="templateValue"
                            type="number"
                            required
                            value={task.value}
                            onChange={(e) =>
                              onInputChange("value", e?.target?.value as any)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                              error?.value?.hasError
                                ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                : ""
                            )}
                          />
                        </div>
                        {error?.value?.hasError && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="order-error"
                          >
                            Value is required.
                          </p>
                        )}
                      </div>
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="note"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Note
                        </label>
                        <div className="mt-2">
                          <input
                            id="note"
                            name="note"
                            type="text"
                            value={task.note}
                            onChange={(e) =>
                              onInputChange("note", e?.target?.value as any)
                            }
                            className={commonService.classNames(
                              "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            )}
                          />
                        </div>
                      </div>

                      {task?.opportunityPlanResourceTaskTemplate?.id && (
                        <>
                          <div className="sm:col-span-6">
                            <div className="sm:gap-4 sm:px-0 flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900">
                                Description:
                              </dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {
                                  task?.opportunityPlanResourceTaskTemplate
                                    ?.description
                                }
                              </dd>
                            </div>
                          </div>
                          <div className="sm:col-span-6">
                            <div className="sm:gap-4 sm:px-0 flex">
                              <dt className="text-sm font-medium leading-6 text-gray-900">
                                Command:
                              </dt>
                              {task?.opportunityPlanResourceTaskTemplate
                                ?.command && (
                                <dd className="mt-1 text-sm leading-6 text-gray-100 bg-gray-900 sm:col-span-2 sm:mt-0 rounded-lg px-3 py-1">
                                  {task?.command}
                                </dd>
                              )}
                            </div>
                          </div>

                          {selectedCommandParameters.map(
                            (item: CommandParameterItem) =>
                              item.isAutoFilled ? null : (
                                <div
                                  key={item.name}
                                  className="sm:col-span-6 flex items-center"
                                >
                                  <div className="w-32 pr-4">
                                    <dt className="text-sm font-medium leading-6 text-gray-900 text-left">
                                      {item.name}
                                      {item.isRequired && (
                                        <span className="text-red-500">*</span>
                                      )}
                                      :
                                    </dt>
                                  </div>
                                  <dd className="mt-1 text-sm leading-6 text-gray-100 sm:col-span-2 sm:mt-0 rounded-lg px-3 py-1">
                                    <input
                                      type="text"
                                      value={item.value}
                                      onChange={(e) =>
                                        handleCommandParameterChange(
                                          item.name,
                                          e.target.value
                                        )
                                      }
                                      className={commonService.classNames(
                                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      )}
                                      required={item.isRequired}
                                    />
                                  </dd>
                                </div>
                              )
                          )}
                        </>
                      )}
                    </div>
                  </form>
                </div>
                <div
                  className={commonService.classNames(
                    currentTabSelection!=="Resource" ? "hidden" : "",
                    "mt-2"
                  )}
                >
                  <ResourceDetialsTable
                    resource={props?.resource}
                    date={props?.date ?? ""}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={commonService.classNames(
              "mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3",
              props?.isEdit ? "sm:grid-cols-3" : "sm:grid-cols-2"
            )}
          >
            <button
              type="submit"
              disabled={
                hasError ||
                loading ||
                (!props?.isEdit && !selectedTemplate) ||
                isNextButtonDisabled
              }
              className={commonService.classNames(
                "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                hasError || loading || (!props?.isEdit && !selectedTemplate)
                  ? "disabled:opacity-75"
                  : "",
                props.isEdit ? "sm:col-start-3" : "sm:col-start-2"
              )}
              onClick={() => addTemplate()}
            >
              <Spinner show={loading} />
              {modalBtnText}
            </button>
            {props.isEdit && (
              <button
                type="button"
                disabled={loading}
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 sm:col-start-2",
                  loading ? "disabled:opacity-75" : ""
                )}
                onClick={() => openDeleteTaskModal()}
              >
                Delete
              </button>
            )}
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}
