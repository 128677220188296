import { useState } from "react";
import DetailMonthCostCategoryComparison from "./modal/detailMonthCostCategoryComparison";
import iconService from "../../app/common/cloudResourceIconMap";
import commonService from "../../app/service/commonService";
import { StatWithPercentageChange } from "../../app/common/commonControls";

interface Props {
    data: SummaryMonthCostCategoryComparisonModel[],
}



export default function SummaryMonthCostCategoryComparison(props: Readonly<Props>) {
    const [showDetailModal, setShowDetailModal] = useState(false)
    const [selectedDetails, setSelectedDetails] = useState<MonthCostCategoryComparisonResult[]>([]);

    const openDetailModal = (items: MonthCostCategoryComparisonResult[]) => {
        setShowDetailModal(true);
        setSelectedDetails(items);
    }

    const closeDetailModal = () => {
        setShowDetailModal(false);
    }

    return (
        <>
        <DetailMonthCostCategoryComparison show={showDetailModal} data={selectedDetails} closeModal={closeDetailModal}></DetailMonthCostCategoryComparison>
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Category
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Region
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Cost
                                    </th>
                                </tr>
                            </thead>
                             <tbody className="divide-y divide-gray-200">
                                {props.data?.map((item) => (                             
                                    <tr key={item.id}>
                                        <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0 flex items-center">
                                            <img
                                                className="h-6 w-6 flex-none bg-gray-50 mt-4"
                                                src={iconService.IconSelectorWithCategory("azure", item.category)}
                                                alt=""
                                            />
                                            <span className="ml-2 mt-4">{item.category}</span>
                                        </td>

                                        <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0">{item.region}</td>
                                        <td className="whitespace-nowrap py-0 pl-0 pr-0 text-sm font-small text-gray-900 pl-0">
                                           <StatWithPercentageChange stat={commonService.formatToUSCurrency(item.cost)}  prevValue={item.prevCost} currentValue={item.cost} />
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                        <button onClick={() => openDetailModal(item.items)}>Expand</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

