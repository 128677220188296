import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CloudResourceService from "../../app/service/cloudResourceService";
import DelegatedReaderAccessDocumentModal from "../initiatives/modal/delegatedReaderAccessDocumentModal";

export default function AccessReview() {
  const [subscriptionList, setSubscriptionList] = useState<SubscriptionItem[]>(
    []
  );
  const [showDelegatedReaderAccessModal, setShowDelegatedReaderAccessModal] =
    useState<boolean>(false);

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  const getAllSubscriptions = () => {
    CloudResourceService.getAllSubscriptions()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setSubscriptionList(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const openDelegatedReaderAccessModal = () => {
    setShowDelegatedReaderAccessModal(true);
  };

  const closeDelegatedReaderAccessModal = () => {
    setShowDelegatedReaderAccessModal(false);
  };

  return (
    <>
      <DelegatedReaderAccessDocumentModal
        show={showDelegatedReaderAccessModal}
        closeModal={() => closeDelegatedReaderAccessModal()}
        subscriptionList={subscriptionList}
      />
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            We are working on your proposal. Pending access. if you have
            completed this step, it could take up to 24 hours to display.
          </h2>
          <div className="mt-10 flex items-center gap-x-6">
            <button
              onClick={() => openDelegatedReaderAccessModal()}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Learn more <span aria-hidden="true">→</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
