import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Pulse from "../../app/pulse/pulse";
import commonService from "../../app/service/commonService";
import OpportunityService from "../../app/service/opportunityService";
import ConfirmationModal from "../reports/modal/confirmationModal";
import InitiativesModal from "./modal/initiativesModal";
import { Tooltip } from "react-tooltip";
import { PlusIcon } from "@heroicons/react/24/outline";
import ContactModal from "./modal/contactsModal";
import React from "react";

interface Props {
  projectId: string | undefined;
  project: ProjectItem | undefined;
  isLoadingProject: boolean;
}

const deletePlanModal = {
  title: "Delete Plan",
  message: "Are you sure you want to delete the Plan",
};

export default function InitiativesTable(props: Readonly<Props>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [plans, setPlans] = useState<OpportunityPlanItem[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<OpportunityPlanItem>();
  const navigate = useNavigate();
  const [showDeletePlanModal, setShowDeletePlanModal] =
    useState<boolean>(false);
  const [loadingDeletePlan, setLoadingDeletePlan] = useState<boolean>(false);
  const [selectedPlanForDelete, setSelectedPlanForDelete] = useState<string>();
  const [deleteMessage, setDeleteMessage] = useState<string>("");

  const [selectedContact, setSelectedContact] =
    useState<OpportunityPlanContactsItem>();

  useEffect(() => {
    init();
    if (props.project) {
      setPlans(props.project.opportunityPlan);
    }
  }, [props.projectId]);

  const init = () => {
    if (props.projectId) {
      getAllOpportunityPlan(props.projectId);
    }
  };

  const getAllOpportunityPlan = (projectId: string) => {
    setLoading(true);
    OpportunityService.getAllOpportunityPlan(projectId)
    .then((response: any) => {
        setLoading(false);
        const result = response?.data?.result || [];
        setPlans(result);
    })
    .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
    });
};

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const closeDetailModal = (refresh?: boolean) => {
    setShowDetailModal(false);
    if (refresh) {
      init();
    }
  };

  const openPlanModal = () => {
    setIsEdit(false);
    openDetailModal();
  };

  const openDetailModal = () => {
    setShowDetailModal(true);
  };

  const editPlan = (id: string | undefined) => {
    const currentPlan: OpportunityPlanItem | undefined = plans.find(
      (x) => x.id === id
    );
    if (currentPlan) {
      setSelectedPlan(currentPlan);
      setIsEdit(true);
      openDetailModal();
    }
  };

  const closeDeletePlanModal = () => {
    setShowDeletePlanModal(false);
  };

  const openDeletePlanModal = (e: any, plan: OpportunityPlanItem) => {
    e?.preventDefault();
    setDeleteMessage(`${deletePlanModal.message}: ${plan.name} ?`);
    setSelectedPlanForDelete(plan.id);
    setShowDeletePlanModal(true);
  };

  const onDeletePlan = () => {
    if (selectedPlanForDelete) {
      setLoadingDeletePlan(true);
      OpportunityService.deleteOpportunityPlan(selectedPlanForDelete)
        .then((response: any) => {
          commonService.showMessage(response?.data || {});
          setLoadingDeletePlan(false);
          if (response?.data?.isSuccess) {
            closeDeletePlanModal();
            init();
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoadingDeletePlan(false);
        });
    }
  };

  const closeContactModal = (refresh?: boolean) => {
    setShowContactModal(false);
    if (refresh) {
      init();
    }
  };

  const openContactModal = (plan: OpportunityPlanItem) => {
    setIsEdit(false);
    setShowContactModal(true);
    setSelectedPlan(plan);
  };

  const openEditContact = (contact: OpportunityPlanContactsItem,
    plan: OpportunityPlanItem) => {
        editContact(contact, plan);
    }

  const editContact = (
    contact: OpportunityPlanContactsItem,
    plan: OpportunityPlanItem
  ) => {
    setSelectedContact(contact);
    setSelectedPlan(plan);
    setIsEdit(true);
    setShowContactModal(true);
  };

  const fixDecimalVal = (val: number) => {
    return val ? parseFloat(val?.toString()).toFixed(2): 0;
  };

  return (
    <>
      <ContactModal
        show={showContactModal}
        closeModal={closeContactModal}
        isEdit={isEdit}
        plan={selectedPlan}
        contactItem={selectedContact}
        refreshDetails={() => init()}
      />

      {showDetailModal && <InitiativesModal
        show={showDetailModal}
        closeModal={closeDetailModal}
        project={props.project}
        isEdit={isEdit}
        plan={selectedPlan}
      />}
      <ConfirmationModal
        show={showDeletePlanModal}
        title={deletePlanModal.title}
        message={deleteMessage}
        loading={loadingDeletePlan}
        closeModal={() => closeDeletePlanModal()}
        save={() => onDeletePlan()}
      />
      <div className="py-16 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Initiatives
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Track initiatives and progress
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {loading || props.isLoadingProject ? (
                <Pulse show={loading || props.isLoadingProject} />
              ) : (
                <>
                  {plans?.length > 0 ? (
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Stats
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Team
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                          >
                            <span className="sr-only">View</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {plans.map((plan) => (
                          <tr key={plan.id}>
                            <td
                              className="whitespace-nowrap px-3 py-5 align-top"
                              style={{ maxWidth: "10rem" }}
                            >
                              <div className="truncate font-medium text-gray-900">
                                <span
                                className="truncate font-medium text-gray-900 cursor-pointer"
                                onClick={() =>
                                  commonService.parseNavigate(`/initiativesDetail/${plan.id}`, navigate)
                                }
                              >
                                {plan.name}
                              </span>
                              </div>
                              <div className="truncate mt-1 text-gray-500">
                                <span
                                  data-tooltip-id={
                                    plan.id + "-description-tooltip"
                                  }
                                  className="cursor-pointer"
                                  onClick={() => editPlan(plan?.id)}
                                >
                                  {plan.description}
                                </span>
                                <Tooltip
                                  id={plan.id + "-description-tooltip"}
                                  arrowColor="transparent"
                                  place="bottom"
                                >
                                  <div style={{}}>
                                    <span>{plan.description}</span>
                                  </div>
                                </Tooltip>
                              </div>
                            </td>
                            <td
                              className="whitespace-nowrap px-3 py-5 text-sm text-gray-500"
                              style={{ maxWidth: "10rem" }}
                            >
                              <div className="text-gray-900">
                                Savings $
                                {fixDecimalVal(plan?.opportunityPlanStep.reduce(
                                  (totalPlanValue, opportunityPlanStepItem) => {
                                    return (
                                      totalPlanValue +
                                      opportunityPlanStepItem?.opportunityPlanResources.reduce(
                                        (
                                          totalStepValue,
                                          opportunityPlanResourceItem
                                        ) => {
                                          return (
                                            totalStepValue +
                                            opportunityPlanResourceItem?.opportunityPlanResourceTask.reduce(
                                              (
                                                totalResourceValue,
                                                opportunityPlanResourceTaskItem
                                              ) => {
                                                return opportunityPlanResourceTaskItem.completedOn
                                                  ? totalResourceValue +
                                                      opportunityPlanResourceTaskItem.value
                                                  : totalResourceValue;
                                              },
                                              0
                                            )
                                          );
                                        },
                                        0
                                      )
                                    );
                                  },
                                  0
                                ))}{" "}
                                out of $
                                {fixDecimalVal(plan?.opportunityPlanStep.reduce(
                                  (totalPlanValue, opportunityPlanStepItem) => {
                                    return (
                                      totalPlanValue +
                                      opportunityPlanStepItem?.opportunityPlanResources.reduce(
                                        (
                                          totalStepValue,
                                          opportunityPlanResourceItem
                                        ) => {
                                          return (
                                            totalStepValue +
                                            opportunityPlanResourceItem?.opportunityPlanResourceTask.reduce(
                                              (
                                                totalResourceValue,
                                                opportunityPlanResourceTaskItem
                                              ) => {
                                                return (
                                                  totalResourceValue +
                                                  opportunityPlanResourceTaskItem.value
                                                );
                                              },
                                              0
                                            )
                                          );
                                        },
                                        0
                                      )
                                    );
                                  },
                                  0
                                ))}
                              </div>
                              <div className="mt-1 text-gray-500">
                                Work Hrs{" "}
                                {fixDecimalVal(plan?.opportunityPlanStep.reduce(
                                  (totalPlanHours, opportunityPlanStepItem) => {
                                    return (
                                      totalPlanHours +
                                      opportunityPlanStepItem?.opportunityPlanResources.reduce(
                                        (
                                          totalStepHours,
                                          opportunityPlanResourceItem
                                        ) => {
                                          return (
                                            totalStepHours +
                                            opportunityPlanResourceItem?.opportunityPlanResourceTask.reduce(
                                              (
                                                totalResourceHours,
                                                opportunityPlanResourceTaskItem
                                              ) => {
                                                return opportunityPlanResourceTaskItem.completedOn
                                                  ? totalResourceHours +
                                                      opportunityPlanResourceTaskItem.hours
                                                  : totalResourceHours;
                                              },
                                              0
                                            )
                                          );
                                        },
                                        0
                                      )
                                    );
                                  },
                                  0
                                ))}{" "}
                                out of{" "}
                                {fixDecimalVal(plan?.opportunityPlanStep.reduce(
                                  (totalPlanHours, opportunityPlanStepItem) => {
                                    return (
                                      totalPlanHours +
                                      opportunityPlanStepItem?.opportunityPlanResources.reduce(
                                        (
                                          totalStepHours,
                                          opportunityPlanResourceItem
                                        ) => {
                                          return (
                                            totalStepHours +
                                            opportunityPlanResourceItem?.opportunityPlanResourceTask.reduce(
                                              (
                                                totalResourceHours,
                                                opportunityPlanResourceTaskItem
                                              ) => {
                                                return (
                                                  totalResourceHours +
                                                  opportunityPlanResourceTaskItem.hours
                                                );
                                              },
                                              0
                                            )
                                          );
                                        },
                                        0
                                      )
                                    );
                                  },
                                  0
                                ))}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                {plan.status}
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              <div className="isolate flex -space-x-1 overflow-hidden">
                                {plan?.opportunityPlanContacts.map(
                                  (contact) => (
                                    <React.Fragment key={contact.id}>
                                      {contact.externalUserEmail? (
                                        <button
                                          onClick={() =>
                                            openEditContact(contact, plan)
                                          }
                                          data-tooltip-id={
                                            contact.id + "-contact-tooltip"
                                          }
                                          className="relative bg-indigo-600 text-white z-30 inline-block h-6 w-6 rounded-full inline-flex flex items-center justify-center"
                                        >
                                          {contact.externalUserName
                                            ?.toString()[0]
                                            ?.toUpperCase() || ""}
                                        </button>
                                      ) : (
                                        <button
                                        onClick={() =>
                                          openEditContact(contact, plan)
                                        }>
                                        <img
                                          key={contact.id}
                                          className="relative z-30 inline-block h-6 w-6 rounded-full ring-2 ring-white flex"
                                          src={contact.user?.pictureUrl}
                                          alt={contact.user?.firstName || contact.externalUserName}
                                          data-tooltip-id={
                                            contact.id + "-contact-tooltip"
                                          }
                                        />
                                        </button>
                                      )}
                                      <Tooltip
                                        id={contact.id + "-contact-tooltip"}
                                        arrowColor="transparent"
                                        place="bottom"
                                      >
                                        <div style={{}}>
                                          <span>
                                            {contact.externalUserName || contact.user?.firstName}
                                          </span>
                                        </div>
                                      </Tooltip>
                                    </React.Fragment>
                                  )
                                )}
                                <button
                                  type="button"
                                  className="rounded-full p-1 bg-indigo-600 h-6 w-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  onClick={() => openContactModal(plan)}
                                >
                                  <PlusIcon aria-hidden="true" />
                                </button>
                              </div>
                            </td>
                            <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                              <button
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                                onClick={() =>
                                  commonService.parseNavigate(`/initiativesDetail/${plan.id}`, navigate)
                                }
                              >
                                View<span className="sr-only">,{plan.name}</span>
                              </button>

                              <button
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                                onClick={() => editPlan(plan?.id)}
                              >
                                Edit<span className="sr-only">,{plan.name}</span>
                              </button>
                              <button
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                                onClick={(e) => openDeletePlanModal(e, plan)}
                              >
                                Delete<span className="sr-only">,{plan.name}</span>
                              </button>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td
                            colSpan={5}
                            className="relative whitespace-nowrap py-5 text-sm font-medium sm:pr-0"
                          >
                            <button
                              type="button"
                              className=" w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={() => openPlanModal()}
                            >
                              Add
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <button
                      type="button"
                      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => openPlanModal()}
                    >
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                        />
                      </svg>
                      <span className="mt-2 block text-sm font-semibold text-gray-900">
                        Create Initiative
                      </span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
