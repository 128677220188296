import { NavigateFunction, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Header } from "./components/header";
import MonthlyInvoiceReport from "./app/reports/monthlyInvoiceReport";
import InitiativesDashboard from "./app/initiatives/initiativesDashboard";
import InitiativesDetail from "./app/initiatives/initiativesDetail";
import InitiativesDetailStep from "./app/initiatives/initiativesDetailStep";
import { Footer } from "./components/footer";
import Registration from "./app/registration/registration";
import { createContext, useEffect, useState } from "react";
import Settings from "./app/settings/settings";
import Verify from "./app/verify/verify";
import AuthenticationService from "./app/service/authService";
import commonService from "./app/service/commonService";
import { Breadcrumb } from "./components/breadcrumb";
import PreSaleDashboard from "./components/preSale/preSaleDashboard";
import CallToActionPreSale from "./components/preSale/callToActionPreSale";
import PreSaleLoading from "./components/preSale/preSaleLoading";
import PreSalePlanSelection from "./components/preSale/preSalePlanSelection";
import { RedirectComponent } from "./components/redirect";
import AccessReview from "./components/preSale/accessReview";
import ResellerProposal from "./components/reseller/resellerProposal";
import CloudResources from "./components/cloudResource/cloudResources";
import AppRedirect from "./app/redirect/redirect";
import PageNotFound from "./app/pageNotFound/pageNotFound";
import ResellerAccounts from "./components/reseller/resellerAccounts";
import ResellerUnmappedSubscripitons from "./components/reseller/resellerUnmappedSubscriptions";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AccountInfo, EventType, NavigationClient } from "@azure/msal-browser";
import PrivacyPolicy from "./app/privacyPolicy/privacyPolicy";
import Support from "./app/support/support";
import PageLayout from "./app/pageLayout";
import PostRegistration from "./app/post-registration/postRegistration";
import MSRegistration from "./app/ms-registration/msRegistration";
import DynamicQuery from "./components/dynamicQuery/dynamicQuery";
import CloudResourceService from "./app/service/cloudResourceService";

export const MyContext: any = createContext({ isCustomer: false });

const pages = [
  { name: "Step", href: "initiativesDetail", current: false },
  { name: "Detail", href: "initiativesDetailStep", current: true },
];
const App = ({ pca }: any) => {

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get('customerId');

  useEffect(() => {
    if(customerId) {
      commonService.selectedAccount.id = customerId;
    }
  }, []);

  return (
    <ClientSideNavigation pca={pca}>
      <MsalProvider instance={pca}>
        <PageLayout>
          <Pages />
        </PageLayout>
      </MsalProvider>
    </ClientSideNavigation>
  );
};

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */ 
export class CustomNavigationClient extends NavigationClient{
  navigate: NavigateFunction;
  constructor(navigate: NavigateFunction) {
      super();
      this.navigate = navigate;
  }

  /**
   * Navigates to other pages within the same web application
   * You can use the useNavigate hook provided by react-router-dom to take advantage of client-side routing
   * @param url 
   * @param options 
   */
  async navigateInternal(url: string, options: { noHistory: any; }) {
      const relativePath = url.replace(window.location.origin, '');
      if (options.noHistory) {
          this.navigate(relativePath, { replace: true});
      } else {
          this.navigate(relativePath);
      }

      return false;
  }
}

function ClientSideNavigation({ pca, children }: any) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

function Pages() {
  const location = useLocation();
  const [isCustomer, setIsCustomer] = useState(false);
  const { instance } = useMsal();
  const [pageList, setPageList] = useState<any[]>([]);
  const navigate = useNavigate();
  let init = false;

  const dbkProfile = commonService.dbkProfile;

  const resellerRoutes: string[] = ['/resellerProposal', '/resellerAccounts', '/resellerUnmappedSubscripitons', '/settings', '/verifyToken'];

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setBreadCrump(location?.pathname);
  }, [location]);

  const setBreadCrump = (pathname?: string) => {
    const pathName = pathname?.toString()?.split("/")[1] ?? "";
    if (pathName === "initiativesDetail") {
      setFocus(pathName);
      setPageList([pages[0]]);
    } else if (pathName === "initiativesDetailStep") {
      setFocus(pathName);
      setPageList(pages);
    } else {
      setPageList([]);
    }
  };

  const setFocus = (pathName: string) => {
    pages.forEach((x) => {
      x.current = false;
      if (x.href === pathName) {
        x.current = true;
      }
    });
  };

  const setMsalAccount = async (
    data: AccountInfo[],
    navigate: NavigateFunction,
    redirectPath: string
  ) => {

    const accountItemArray: AccountItem[] = commonService.convertAccountInfoArrayToAccountItemArray(data);
    commonService.accountData.accounts = accountItemArray || [];
    // add api call to fetch user info
    if(data[0]?.localAccountId){
      setLoading(true);
      await AuthenticationService.getMsUserById(data[0]?.localAccountId)
      .then((response: any) => {
          const result: User = response?.data?.result;
          if(result){
            var currentUserItem = commonService.convertAccountInfoToCurrentUserItem(data[0]);
            currentUserItem.accountId = result.accountId;
            localStorage.setItem("currentUser", JSON.stringify(currentUserItem));
            localStorage.setItem("userDetail", JSON.stringify(result || ""));
            commonService.accounts = result?.accounts || [];
            
            let path = redirectPath || "/";
            const account: AccountItem | undefined = (result?.accounts || []).find(
              (x) => x.id === (commonService.selectedAccount.id || result?.accountId)
            );
            const customer = account?.type === "Customer";
            if(customer) {
              CloudResourceService.sendPreloadCustomerDataMessage().then((_: any) => {
                setLoading(false);
              }).catch((_: any) => {
                setLoading(false);
              });
            } else {
              setLoading(false);
            }
            setIsCustomer(customer);
            if(!customer && resellerRoutes?.indexOf(path) === -1) {
              path = '/resellerProposal';
            }
            commonService.checkOnBoardProcess(response, navigate, true, path);
          } else {
            setLoading(false);
          }
        })
        .catch((_: any) => {
          setLoading(false);
          navigate("/");
        });
    }
  };

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      
      const redirectPath = location.pathname;
      if ((event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && event.payload.account) {
          /**
           * For the purpose of setting an active account for UI update, we want to consider only the auth 
           * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy 
           * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
           * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if(!init) {
            init = true;
            
            setMsalAccount([event.payload.account], navigate, redirectPath);
          }
        }

        if (event.eventType === EventType.SSO_SILENT_SUCCESS && event.payload.account) {
          if(!init) {
            init = true;
            setMsalAccount([event.payload.account], navigate, redirectPath);
          }
        }
        
    });

    return () => {
        if (callbackId) {
            instance.removeEventCallback(callbackId);
        }
    }
  }, [instance]);

  return (
    <MyContext.Provider value={{ isCustomer, setIsCustomer }}>
      <div className="min-h-screen">
        {(useIsAuthenticated() && ['/register', '/login', '/support', '/privacy', '/postregistration', '/ms-register'].indexOf(location.pathname) === -1) && <Header loading={loading} />}

        <main>
          <Breadcrumb
            pageList={pageList || []}
            pathName={location.pathname || ""}
          />

          <Routes>
           { useIsAuthenticated() && !commonService.loading && !loading &&
              <>
              {isCustomer && 
              (
                <>
                  <Route path="/" element={<MonthlyInvoiceReport />} />
                  <Route
                    path="/Initiatives"
                    element={<InitiativesDashboard />}
                  />
                  <Route
                    path="/initiativesDetail/:id/"
                    element={<InitiativesDetail />}
                  />
                  <Route
                    path="/InitiativesDetailStep/:id/"
                    element={<InitiativesDetailStep />}
                  />
                  <Route
                    path="/newAccount/"
                    element={<CallToActionPreSale />}
                  />
                  <Route
                    path="/newAccountProsessing/"
                    element={<PreSaleLoading />}
                  />
                  <Route
                    path="/newAccountPotential/"
                    element={<PreSaleDashboard />}
                  />
                  <Route
                    path="/newAccountPlanSelection/"
                    element={<PreSalePlanSelection />}
                  />
  
                  <Route path="/accessReview/" element={<AccessReview />} />
  
                  <Route path="/CloudResources/" element={<CloudResources />} />
                  <Route path="/DynamicQuery/" element={<DynamicQuery />} />
                </>
              )} 
  
              <Route path="/settings" element={<Settings />} />
              <Route path="/verifyToken" element={<Verify />} />
              <Route path="/redirectAccount" element={<RedirectComponent />} />
              <Route path="*" element={<PageNotFound />} />
              
              {!isCustomer && (
                <>
                <Route path="/" element={<MonthlyInvoiceReport />} />
                <Route
                  path="/resellerProposal/"
                  element={<ResellerProposal />}
                />
                <Route
                  path="/resellerAccounts/"
                  element={<ResellerAccounts />}
                />
                <Route
                  path="/resellerUnmappedSubscripitons/"
                  element={<ResellerUnmappedSubscripitons />}
                />
                </>
              )}
              </>
           }
            
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/support" element={<Support />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/redirect" element={<AppRedirect />} />
            <Route path="/postregistration" element={<PostRegistration />} />
            <Route path="/ms-register" element={<MSRegistration />} />
          </Routes>

          {(commonService.loading || loading) && 
          <div className="flex justify-center items-center h-full" style={{ height: "calc(100vh - 180px)" }}>
          <img
                    className="h-12 w-auto mt-4 animate-bounce"
                    src={dbkProfile.imgUrl}
                    alt={dbkProfile.name}
                  />
          </div>
          }
        </main>
        <div className="sticky top-[100vh]">{ <Footer />}</div>
      </div>
    </MyContext.Provider>
  );
}


export default App;
