import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ResourcesModal from "../../components/initiatives/modal/resourcesModal";
import TaskStatusUpdateModal from "../../components/initiatives/modal/taskStatusUpdateModal";
import { ResourceBurnRateChart } from "../../components/initiatives/steps/resourceBurnRateChart";
import ConfirmationModal from "../../components/reports/modal/confirmationModal";
import Pulse from "../pulse/pulse";
import commonService from "../service/commonService";
import OpportunityService from "../service/opportunityService";
import ReportService from "../service/reportService";
import Spinner from "../spinner/spinner";
import AzureResourceParser from "../common/azureResourceParser";
import TemplateModal from "../../components/initiatives/modal/templateModal";
import iconService from "../common/cloudResourceIconMap";
import ResourceDetialModal from "../../components/initiatives/modal/resourceDetailModal";
import TaskViewModal from "../../components/initiatives/modal/taskViewModal";
import ResourceExportModal from "../../components/initiatives/modal/resourceExportModal";
import VMDetailModal from "../../components/initiatives/modal/vmModal";
import CloudResourceService from "../service/cloudResourceService";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const statusConfirmModal = {
  title: "Update Status",
  message: "Are you sure you want to update the status?",
};

export default function InitiativesDetailStep() {
  const { id } = useParams();
  const [showResourceModal, setShowResourceModal] = useState<boolean>(false);

  const [allResources, setAllResources] = useState<
    Array<MonthCostCategoryResult>
  >([]);
  const [templates, setTemplates] = useState<
    OpportunityPlanResourceTaskTemplateItem[]
  >([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<OpportunityPlanStepItem | object>({});

  const [resources, setResources] = useState<OpportunityPlanResourceItem[]>([]);
  const [dateRange, setDateRange] = useState<string>(
    commonService.currentDate()
  );

  const [showStatusConfirmationModal, setShowStatusConfirmationModal] =
    useState<boolean>(false);
  const [loadingStatusUpdate, setLoadingStatusUpdate] =
    useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<
    OpportunityPlanResourceTaskItem | any
  >();
  const [showStatusUpdateModal, setShowStatusUpdateModal] =
    useState<boolean>(false);
  const [contact, setContact] = useState<OpportunityPlanContactsItem[]>([]);

  const [showResourceExportModal, setShowResourceExportModal] = useState<boolean>(false);
  const apiCallTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    getOpportunityPlanStep();
    getAllResources();
    getPlanResourceTaskTemplate();
  };

  const getOpportunityPlanContacts = (planId: string) => {
    if (planId) {
      setLoading(true);
      OpportunityService.getOpportunityPlanContacts(planId)
        .then((response: any) => {
          const result = response?.data?.result || [];
          setContact(result);
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const getOpportunityPlanStep = () => {
    if (id) {
      setLoading(true);
      OpportunityService.getOpportunityPlanStep(id || "")
        .then((response: any) => {
          const result = response?.data?.result || {};
          setStep(result);
          if (result?.opportunityPlanId) {
            commonService.planId = result?.opportunityPlanId || '';
            getOpportunityPlanContacts(result?.opportunityPlanId);
          }
          getDailyCost(result);
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const closeResourceModal = (refresh?: boolean) => {
    setShowResourceModal(false);
    if (refresh) {
      init();
    }
  };

  const openResourceModal = () => {
    setShowResourceModal(true);
  };

  const getAllResources = () => {
    setAllResources([]);
    CloudResourceService.getResources()
      .then((response: any) => {
        const result: MonthCostCategoryResult[] = response?.data?.result || [];
        const sortedResult = result.sort((a: MonthCostCategoryResult, b: MonthCostCategoryResult) => b.totalCost - a.totalCost);
        setAllResources(sortedResult);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const getDailyCost = (resources: OpportunityPlanStepItem) => {
    if (dateRange) {
      setResources([]);
      const resourceIds: string[] = (resources.opportunityPlanResources || [])
        .filter((x) => x.cspResourceId !== "test")
        .map((y) => y.cspResourceId);
      const request: DayCostCategoryRequest = {
        customerId: commonService.getAccountId(),
        date: dateRange || "",
        resourceIds: resourceIds,
      };
      setLoading(true);
      ReportService.getDailyCost(request)
        .then((response: any) => {
          const stepData = parseResult(JSON.parse(JSON.stringify(resources?.opportunityPlanResources || [])), response?.data?.result || [])
          mapResourceIcon(stepData);
          setResources(sortResource(stepData || []));
          setLoading(false);
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const parseResult = (stepData: any, result: any) => {
    (stepData as OpportunityPlanResourceItem[]).forEach((x) => {
      x.opportunityPlanResourceTask = (x.opportunityPlanResourceTask || [])
        .sort((a: OpportunityPlanResourceTaskItem, b: OpportunityPlanResourceTaskItem) => {
          return (a?.sequenceOrder ?? 0) - (b?.sequenceOrder ?? 0);
        });
      const chartData = result.find(
        (r: DayCostCategoryResponse) => r.resourceId === x.cspResourceId
      );
      if (chartData) {
        x.dailyData = chartData;
        if (x.dailyData) {
          x.dailyData.timeSeries = generate(x.dailyData.data || []);
          x.dailyData.annotations = [];
        }
      }
    });
    return stepData;
  }

  const sortResource = (p: OpportunityPlanResourceItem[]) => {
    return p.sort((a, b) => {
        const nameA = (a?.dailyData?.data[0]?.resourceName ?? 
          a?.cspResourceId?.split("/")[8])?.toLowerCase() || '';
        const nameB = (b?.dailyData?.data[0]?.resourceName ?? 
          b?.cspResourceId?.split("/")[8])?.toLowerCase() || '';
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
    });
}

  const generate = (data: DayCostCategoryResult[]) => {
    const timeData: any[] = [];
    data.forEach((x) => {
      const currentDate = x.date?.split("T")[0] || "";
      const currentSelection = timeData?.find((t) => t.date === currentDate);
      if (currentSelection) {
        if (currentSelection?.hasOwnProperty(x.meterCategory)) {
          currentSelection[x.meterCategory] += x.cost;
        } else {
          currentSelection[x.meterCategory] = x.cost ?? 0;
        }
      } else {
        let newData: any = {
          date: currentDate,
        };
        newData[x.meterCategory] = x.cost ?? 0;
        timeData.push(newData);
      }
    });
    return timeData || [];
  };

  const getPlanResourceTaskTemplate = () => {
    setTemplates([]);
    OpportunityService.getPlanResourceTaskTemplate()
      .then((response: any) => {
        const result = response?.data?.result || [];
        setTemplates(result);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const onDateChange = (e: string) => {
    setDateRange(e);
  };

  const filter = () => {
    if (dateRange) {
      getDailyCost(step as OpportunityPlanStepItem);
    }
  };

  const onTaskChange = (e: any, task: OpportunityPlanResourceTaskItem) => {
    setSelectedTask(JSON.parse(JSON.stringify(task)));
    if (!e) {
      openStatusConfirmationModal();
    } else {
      openStatusUpdateModal();
    }
  };

  const getCurrentDateTime = (completedOn: string) => {
    const date = new Date(completedOn),
    year = date.getFullYear(),
    month = (date.getMonth() + 1).toString(),
    formatedMonth = (month.length === 1) ? ("0" + month) : month,
    day = date.getDate().toString(),
    formatedDay = (day.length === 1) ? ("0" + day) : day,
    hour = date.getHours().toString(),
    formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
    minute = date.getMinutes().toString(),
    formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
    second = date.getSeconds().toString(),
    formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return year + "-" + formatedMonth + "-" + formatedDay + "T" + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
  }

  const onUpdateStatus = (completedOn: string, isRemoved?: boolean, userId?: string) => {
    if (selectedTask) {
      const request: CreateOrUpdateOpportunityPlanResourceTaskRequest = {
        id: selectedTask.id,
        opportunityPlanResourceId: selectedTask.opportunityPlanResourceId,
        opportunityPlanResourceTaskTemplateId:
          selectedTask?.opportunityPlanResourceTaskTemplateId,
        hours: selectedTask?.hours,
        value: selectedTask.value,
        sequenceOrder: selectedTask.sequenceOrder || 0,
        shouldBeCompletedInSequence:
          selectedTask.shouldBeCompletedInSequence || false,
        isActive: selectedTask.isActive,
        completedOn: (isRemoved ? null : getCurrentDateTime(completedOn)) as any,
        completedBy: (isRemoved ? null : userId) as any,
        commandParameters: selectedTask?.opportunityPlanResourceTaskTemplate?.commandParameters
      };
      setLoadingStatusUpdate(true);
      OpportunityService.updateOpportunityPlanResourceTask(request)
        .then((response: any) => {
          commonService.showMessage(response?.data || {});
          setLoadingStatusUpdate(false);
          if (response?.data?.isSuccess) {
            if (isRemoved) {
              closeStatusConfirmationModal();
            } else {
              closeStatusUpdateModal();
            }
            init();
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoadingStatusUpdate(false);
        });
    }
  };

  const openStatusConfirmationModal = () => {
    setShowStatusConfirmationModal(true);
  };

  const closeStatusConfirmationModal = () => {
    setShowStatusConfirmationModal(false);
  };

  const openStatusUpdateModal = () => {
    setShowStatusUpdateModal(true);
  };

  const closeStatusUpdateModal = () => {
    setShowStatusUpdateModal(false);
  };

  const mapResourceIcon = (data: OpportunityPlanResourceItem[]) => {
    if(data){
      data.forEach(resource => {
        const template = resource.opportunityPlanResourceTask[0]?.opportunityPlanResourceTaskTemplate;
        if(template && template.csp?.toLowerCase() === "azure"){
          const parsedResourceId = new AzureResourceParser({ resourceId: resource.cspResourceId });
          resource.icon = iconService.IconSelectorWithProvider(template.csp, parsedResourceId.resourceProvider, parsedResourceId.resourceType);
        }
        // add map for aws when it's needed
      });
    }
  };

  const [showTemplateModal, setShowTemplateModal] = useState<boolean>(false);
  const [selectedResource, setSelectedResource] = useState<OpportunityPlanResourceItem>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const closeTemplateModal = (refresh?: boolean) => {
    setShowTemplateModal(false);
    if (refresh) {
      init();
    }
  };

  const openTemplateModal = (resource: OpportunityPlanResourceItem) => {
    setSelectedResource(resource);
    setIsEdit(false);
    setShowTemplateModal(true);
  };

  const editTemplate = (e: any, resource: OpportunityPlanResourceItem, task: OpportunityPlanResourceTaskItem) => {
    e?.preventDefault();
    setSelectedResource(resource);
    setSelectedTask(task);
    setIsEdit(true);
    setShowTemplateModal(true);
  }

  const [showResourceDetailModal, setShowResourceDetailModal] = useState<boolean>(false);
  const [selectedResourceForDetail, setSelectedResourceForDetail] = useState<OpportunityPlanResourceItem>();
  const closeResourceDetailModal = (refresh?: boolean) => {
    setShowResourceDetailModal(false);
    if(refresh) {
      init();
    }
  };

  const openResourceDetailModalModal = (e: any, resource: OpportunityPlanResourceItem) => {
    e?.preventDefault();
    setSelectedResourceForDetail(JSON.parse(JSON.stringify(resource || {})));
    setShowResourceDetailModal(true);
  };

  const [showTaskViewModal, setShowTaskViewModal] = useState<boolean>(false);

  const closeTaskViewModal = (refresh?: boolean) => {
    setShowTaskViewModal(false);
  };

  const openTaskViewModal = () => {
    setShowTaskViewModal(true);
  }

  const closeResourceExportModal = (refresh?: boolean) => {
    setShowResourceExportModal(false);
    if (refresh) {
      init();
    }
  };

  const openResourceExportModal = () => {
    setShowResourceExportModal(true);
  };

  const [showVMDetailModal, setShowVMDetailModal] = useState<boolean>(false);

  const openVMDetailModal = (resource: OpportunityPlanResourceItem) => {
    const type = resource.cspResourceId?.split('/')[6];
    if(type === 'Microsoft.Compute') {
      setSelectedResource(resource);
      setShowVMDetailModal(true);
    }
  }

  const closeVMDetailModal = () => {
    setShowVMDetailModal(false);
  };

  const handleOnDragEnd = (result: any) => {

    if (!result.destination) return; 
  
    const resourceIndex = resources.findIndex(resource =>
      resource.opportunityPlanResourceTask.some(task => task.id === result.draggableId)
    );
  
    if (resourceIndex === -1) return;
  
    const newResources = [...resources];
    const resource = newResources[resourceIndex];
    const newTasks = [...resource.opportunityPlanResourceTask];
    const [reorderedTask] = newTasks.splice(result.source.index, 1);
    newTasks.splice(result.destination.index, 0, reorderedTask);
  
    const updatedTasks = newTasks.map((task, index) => ({
      ...task,
      sequenceOrder: index + 1,
    }));
  
    newResources[resourceIndex] = {
      ...resource,
      opportunityPlanResourceTask: updatedTasks,
    };
  
    setResources(newResources);

    if (apiCallTimeout.current) {
      clearTimeout(apiCallTimeout.current);
    }

    apiCallTimeout.current = setTimeout(() => {
      updateTaskOrder(updatedTasks);
    }, 3000);
  };
  
  const updateTaskOrder = (tasks: OpportunityPlanResourceTaskItem[]) => {
    if (tasks) {
      const requestList: CreateOrUpdateOpportunityPlanResourceTaskRequest[] = [];
      tasks.forEach((task) => {
        const request: CreateOrUpdateOpportunityPlanResourceTaskRequest = {
          id: task.id,
          opportunityPlanResourceId: task.opportunityPlanResourceId,
          opportunityPlanResourceTaskTemplateId: task.opportunityPlanResourceTaskTemplateId,
          hours: task.hours,
          value: task.value,
          note: task.note,
          sequenceOrder: task.sequenceOrder || 0,
          shouldBeCompletedInSequence: task.shouldBeCompletedInSequence || false,
          isActive: task.isActive,
          commandParameters: task.opportunityPlanResourceTaskTemplate.commandParameters,
          completedOn: task.completedOn,
          completedBy: task.completedBy
        };
        requestList.push(request);
      })
      
      setLoadingStatusUpdate(true);

      Promise.all(
        requestList.map((request) =>
          OpportunityService.updateOpportunityPlanResourceTask(request)
        )
      )
      .then((responses) => {
        responses.forEach((response) => commonService.showMessage(response?.data || {}));
      })
      .catch((e) => {
        toast.error(`${e?.response?.data?.message}`);
      })
      .finally(() => {
        setLoadingStatusUpdate(false);
      });
    }
  };
  
  return (
    <>
    {showResourceExportModal && (
        <ResourceExportModal
          show={showResourceExportModal}
          closeModal={closeResourceExportModal}
          allResources={resources}
        />
      )}
      <ResourceDetialModal
        show={showResourceDetailModal}
        closeModal={closeResourceDetailModal}
        resource={selectedResourceForDetail}
        date={dateRange}
      />
      {showStatusConfirmationModal && (
        <ConfirmationModal
          show={showStatusConfirmationModal}
          title={statusConfirmModal.title}
          message={statusConfirmModal.message}
          loading={loadingStatusUpdate}
          actionText={"Update"}
          closeModal={() => closeStatusConfirmationModal()}
          save={() => onUpdateStatus(commonService.currentDate(), true)}
        />
      )}
      {showStatusUpdateModal && (
        <TaskStatusUpdateModal
          show={showStatusUpdateModal}
          closeModal={closeStatusUpdateModal}
          users={contact}
          task={selectedTask || {}}
          onUpdateStatus={onUpdateStatus}
        />
      )}
      {showResourceModal && (
        <ResourcesModal
          show={showResourceModal}
          closeModal={closeResourceModal}
          planStepId={id || ""}
          allResources={allResources}
          templates={templates}
          date={dateRange}
        />
      )}
      <TemplateModal
        show={showTemplateModal}
        closeModal={closeTemplateModal}
        templates={templates}
        task={selectedTask || {}}
        resource={selectedResource}
        isEdit={isEdit}
        date={dateRange}
      />
      <TaskViewModal
        show={showTaskViewModal}
        closeModal={closeTaskViewModal}
        resources={resources}
      />
      {showVMDetailModal && (
        <VMDetailModal
          show={showVMDetailModal}
          closeModal={closeVMDetailModal}
          resourceId={selectedResource?.cspResourceId || ''}
        />
      )}
      <div className="py-10">
        <header>
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between">
              <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                Dashboard : {(step as OpportunityPlanStepItem)?.name}
              </h1>
              <div className="w-[34rem] mr-3 flex">
                <input
                  id="startOn"
                  name="startOn"
                  type="date"
                  required
                  value={dateRange}
                  onChange={(e) => onDateChange(e?.target?.value)}
                  className={commonService.classNames(
                    "w-[34rem] mr-2 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                    !dateRange
                      ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                      : ""
                  )}
                />
                <button
                  type="button"
                  disabled={!dateRange || loading}
                  className={commonService.classNames(
                    "mr-2 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
                    !dateRange || loading ? "disabled:opacity-75" : ""
                  )}
                  onClick={() => filter()}
                >
                  <Spinner show={loading} />
                  Filter
                </button>
                {!loading && <button
                  type="button"
                  disabled={resources?.length===0}
                  className={commonService.classNames(
                    "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
                    resources?.length===0 ? "disabled:opacity-75" : ""
                  )}
                  onClick={() => openTaskViewModal()}
                >
                  Task View
                </button>}

                {(!loading && resources?.length > 0) && (
                  <button
                    type="button"
                    className="ml-2 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={() => openResourceExportModal()}
                  >
                    Export
                  </button>
              )}
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="mx-auto sm:px-6 lg:px-8">
            <div className="relative isolate overflow-hidden"></div>
            {loading ? (
              <Pulse show={loading} />
            ) : (
              <>
                {resources?.length > 0 ? (
                  <ul className="divide-y divide-gray-100">
                    {resources.map((resource) => (
                      <li
                        key={resource.id}
                        className="relative flex justify-between gap-x-6 py-5"
                      >
                        <div className="flex min-w-0 gap-x-4 max-w-xs">
                          <img
                            className="h-12 w-12 flex-none bg-gray-50"
                            src={resource.icon}
                            alt=""
                          />
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900">
                              <span onClick={() => openVMDetailModal(resource)}>
                                <span className="inset-x-0 -top-px bottom-0" />
                                {resource?.dailyData?.data[0]?.resourceName ||
                                  resource?.cspResourceId?.split("/")[8]}
                              </span>
                            </p>
                            {resource?.opportunityPlanResourceTask[0]
                              ?.opportunityPlanResourceTaskTemplate?.csp && (
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                <span className="relative truncate hover:underline">
                                  CSP:{" "}
                                  {
                                    resource?.opportunityPlanResourceTask[0]
                                      ?.opportunityPlanResourceTaskTemplate?.csp
                                  }
                                </span>
                              </p>
                            )}
                            {resource?.cspResourceId && (
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                <span className="relative truncate hover:underline">
                                  SUB: {resource?.cspResourceId?.split("/")[2]}
                                </span>
                              </p>
                            )}

                            {resource?.cspResourceId && (
                              <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                <span className="relative truncate hover:underline">
                                  RG: {resource?.cspResourceId?.split("/")[4]}
                                </span>
                              </p>
                            )}
                          </div>
                        </div>
                        <div
                          className="flex flex-col items-center justify-center gap-x-4"
                          style={{ width: "600px", height: "120px" }}
                        >
                          {resource?.dailyData ? (
                            <ResourceBurnRateChart
                              data={resource?.dailyData?.timeSeries || []}
                              annotations={
                                resource?.dailyData?.annotations || []
                              }
                            />
                          ) : (
                            <div className="flex items-center justify-center w-full h-full">
                              <div className="mb-[2.5em]">
                                No available data found
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="flex shrink-0 gap-x-4">
                          <div className="hidden sm:flex sm:flex-col sm:items-end w-60">
                            <fieldset className="w-60">
                              <DragDropContext onDragEnd={handleOnDragEnd}>
                                <Droppable droppableId="tasksDroppable">
                                  {(provided: any) => (
                                    <div
                                      className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200"
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {resource.opportunityPlanResourceTask.map((task, index) => (
                                        <Draggable key={task.id} draggableId={task.id} index={index}>
                                          {(provided: any) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className="relative flex items-start"
                                            >
                                              <div className="min-w-0 flex-1 text-sm leading-6">
                                                <span
                                                  id={`task-${task.opportunityPlanResourceTaskTemplate?.name}`}
                                                  className="select-none font-medium text-gray-900 cursor-pointer"
                                                  onClick={(e) => editTemplate(e, resource, task)}
                                                >
                                                  {index + 1} - {task.opportunityPlanResourceTaskTemplate?.name}
                                                </span>
                                              </div>
                                              <div className="ml-3 flex h-6 items-center">
                                                <input
                                                  id={`task-${task.opportunityPlanResourceTaskTemplate?.name}`}
                                                  name={`task-${task.opportunityPlanResourceTaskTemplate?.name}`}
                                                  type="checkbox"
                                                  checked={!!task.completedOn || !!task.completedBy}
                                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                  onChange={(e) => onTaskChange(e.target.checked, task)}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                              <div>
                                <button
                                  type="submit"
                                  className=" w-full rounded-md bg-indigo-600 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  onClick={() => openTemplateModal(resource)}
                                >
                                  Add
                                </button>
                              </div>
                            </fieldset>
                          </div>

                          <ChevronRightIcon
                            className="h-5 w-5 flex-none text-gray-400 cursor-pointer"
                            aria-hidden="true"
                            onClick={(e) =>
                              openResourceDetailModalModal(e, resource)
                            }
                          />
                        </div>
                      </li>
                    ))}
                    <li
                      key="resourceId"
                      className="relative flex justify-between gap-x-6 py-5"
                    >
                      <button
                        type="button"
                        className=" w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => openResourceModal()}
                      >
                        Add
                      </button>
                    </li>
                  </ul>
                ) : (
                  <button
                    type="button"
                    className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => openResourceModal()}
                  >
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                      />
                    </svg>
                    <span className="mt-2 block text-sm font-semibold text-gray-900">
                      Add Resource
                    </span>
                  </button>
                )}
              </>
            )}
          </div>
        </main>
      </div>
    </>
  );
}
