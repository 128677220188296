import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Spinner from "../../../app/spinner/spinner";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";
import { ModalWrapper } from "../../common/modalWrapper";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import KQLQueryService from "../../../app/service/kqlQueryService";
interface Props {
  show: boolean;
  closeModal?: any;
  query?: KQLQueryItem;
  isEdit?: boolean;
}

const errorState: any = {
  query: { hasError: false, required: true },
  name: { hasError: false, required: true },
  displayName: { hasError: false, required: true },
  description: { hasError: false, required: true },
};

export default function KqlQueryModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [loading, setLoading] = useState<boolean>(false);
  const cancelButtonRef = useRef(null);

  const editQueryName = props.query?.metadata?.name;

  const [isPublic, setIsPublic] = useState<string>("1");
  const [isPlanCompatible, setIsPlanCompatible] = useState<string>("1");

  const [params, setParams] = useState<any>({ param0: "" });
  const [paramCount, setParamCount] = useState<number>(1);

  const [error, setError] = useState<any>(
    JSON.parse(JSON.stringify(errorState))
  );

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      setIsPublic(props?.query?.metadata?.isPublic ? "2" : "1");
      setIsPlanCompatible(props?.query?.metadata?.isPlanCompatible ? "2" : "1");
      init();
      initParams();
    }
  }, [props.show]);

  const initParams = () => {
      const parametersLength = (props?.query?.metadata?.parameters || []).length;
      if(props?.isEdit && parametersLength > 0) {
          let editParams: any = {};
          (props?.query?.metadata?.parameters || []).forEach((x, i) => {
            editParams[`param${i}`] = x;
          });
          setParams(editParams);
          setParamCount(parametersLength || 1);
      }
  }

  const init = () => {
    setQuery(initQuery());
  };

  const initQuery = (): CreateOrUpdateKQLQueryRequest => {
    const queryData = props?.query;
    return {
      query: queryData?.query || "",
      metadata: {
        name: queryData?.metadata?.name || "",
        displayName: queryData?.metadata?.displayName || "",
        description: queryData?.metadata?.description || "",
        isPublic: queryData?.metadata?.isPublic || false,
        isPlanCompatible: queryData?.metadata?.isPlanCompatible || false,
        parameters: queryData?.metadata?.parameters || [],
      },
    };
  };

  const [query, setQuery] =
    useState<CreateOrUpdateKQLQueryRequest>(initQuery());

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const createQuery = () => {
    const request: CreateOrUpdateKQLQueryRequest = JSON.parse(
      JSON.stringify(query)
    );

    request.metadata.isPublic = isPublic === "2";
    request.metadata.isPlanCompatible = isPlanCompatible === "2";

    request.metadata.parameters = [];

    for (let param in params) {
      if (params[param]) {
        request.metadata.parameters.push(params[param]);
      }
    }
    setLoading(true);
    if (props?.isEdit) {
        KQLQueryService.updateKqlQuery(request)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            closeModal(true);
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    } else {
      KQLQueryService.createKqlQuery(request)
        .then((response: any) => {
          setLoading(false);
          commonService.showMessage(response?.data || {});
          if (response?.data?.isSuccess) {
            closeModal(true);
          }
        })
        .catch((e: any) => {
          toast.error(`${e?.response?.data?.message}`);
          setLoading(false);
        });
    }
  };

  const onInputChange = (fieldName: string, value: string) => {
    const field = error[fieldName] || {};

    const model = JSON.parse(JSON.stringify(query));
    if (fieldName === "query") {
      model[fieldName] = value;
    } else {
      model["metadata"][fieldName] = value;
    }
    if (field) {
      field.hasError = !value;
      error[fieldName] = field;
    }
    setQuery(model);
  };

  const addParameters = () => {
    setParamCount((p) => p + 1);
    const paramName = `param${paramCount}`;
    setParams((p: any) => ({ ...p, [paramName]: "" }));
  };

  const onChangeParameter = (e: any) => {
    setParams((p: any) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const removeParameters = (param: string) => {
    setParams((p: any) => {
      delete p[param];
      return { ...p };
    });
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 mb-10 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              {props?.isEdit ? `Edit Query:  ${editQueryName}` : "Add Query"}
            </Dialog.Title>
            <div className="mt-2">
              <div>
                <form className="space-y-6">
                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="query"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Query
                      </label>
                      <div className="mt-2">
                        <textarea
                          rows={4}
                          name="query"
                          id="query"
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.query?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                          required
                          value={query.query}
                          onChange={(e) =>
                            onInputChange("query", e?.target?.value)
                          }
                        />
                      </div>
                      {error?.query?.hasError && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Query is required.
                        </p>
                      )}
                    </div>

                    {!props?.isEdit && <div className="sm:col-span-3">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Name
                      </label>
                      <div className="mt-2">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          required
                          value={query.metadata.name}
                          onChange={(e) =>
                            onInputChange("name", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.name?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.name?.hasError && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Name is required.
                        </p>
                      )}
                    </div>}

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="displayName"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Display Name
                      </label>
                      <div className="mt-2">
                        <input
                          id="displayName"
                          name="displayName"
                          type="text"
                          required
                          value={query.metadata.displayName}
                          onChange={(e) =>
                            onInputChange("displayName", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.displayName?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.displayName?.hasError && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Display Name is required.
                        </p>
                      )}
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="description"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Description
                      </label>
                      <div className="mt-2">
                        <input
                          id="description"
                          name="description"
                          type="text"
                          required
                          value={query.metadata.description}
                          onChange={(e) =>
                            onInputChange("description", e?.target?.value)
                          }
                          className={commonService.classNames(
                            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                            error?.description?.hasError
                              ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                              : ""
                          )}
                        />
                      </div>
                      {error?.description?.hasError && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="order-error"
                        >
                          Description is required.
                        </p>
                      )}
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="public"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Public
                      </label>
                      <div className="mt-2 flex">
                        <div className="flex h-6 items-center mr-6">
                          <input
                            id="public1"
                            name="public"
                            type="radio"
                            value={isPublic}
                            checked={isPublic === "1"}
                            onChange={() => setIsPublic("1")}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                          <label
                            htmlFor="public1"
                            className="ml-3 block text-sm/6 font-medium text-gray-900"
                          >
                            No
                          </label>
                        </div>
                        <div className="flex h-6 items-center">
                          <input
                            id="public2"
                            name="public"
                            type="radio"
                            value={isPublic}
                            checked={isPublic === "2"}
                            onChange={() => setIsPublic("2")}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                          <label
                            htmlFor="public1"
                            className="ml-3 block text-sm/6 font-medium text-gray-900"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="compatible"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Plan Compatible
                      </label>
                      <div className="mt-2 flex">
                        <div className="flex h-6 items-center mr-6">
                          <input
                            id="compatible1"
                            name="isPlanCompatible"
                            type="radio"
                            value={isPlanCompatible}
                            checked={isPlanCompatible === "1"}
                            onChange={() => setIsPlanCompatible("1")}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                          <label
                            htmlFor="compatible1"
                            className="ml-3 block text-sm/6 font-medium text-gray-900"
                          >
                            No
                          </label>
                        </div>
                        <div className="flex h-6 items-center">
                          <input
                            id="compatible2"
                            name="isPlanCompatible"
                            type="radio"
                            value={isPlanCompatible}
                            checked={isPlanCompatible === "2"}
                            onChange={() => setIsPlanCompatible("2")}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                          <label
                            htmlFor="compatible2"
                            className="ml-3 block text-sm/6 font-medium text-gray-900"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="sm:col-span-6">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Parameters
                      </label>
                      <div className="mt-2">
                        {Object.keys(params || {}).map((param, i: number) => (
                          <div className="flex items-center mb-2">
                            <input
                              id={param}
                              name={param}
                              type="text"
                              value={params[param]}
                              onChange={onChangeParameter}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />

                            {Object.keys(params || {})?.length - 1 === i ? (
                              <button
                                type="button"
                                className="ml-2 rounded-full p-1 bg-indigo-600 h-6 w-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={addParameters}
                                disabled={loading}
                              >
                                <PlusIcon aria-hidden="true" />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="ml-2 rounded-full p-1 bg-red-600 h-6 w-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                onClick={() => removeParameters(param)}
                                disabled={loading}
                              >
                                <XMarkIcon aria-hidden="true" />
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                      {/* <button
                        type="button"
                        disabled={loading}
                        className={commonService.classNames(
                          "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
                          loading ? "disabled:opacity-75" : ""
                        )}
                        onClick={() => addParameters()}
                      >
                        Add Parameter
                      </button> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            disabled={loading}
            className={commonService.classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
              loading ? "disabled:opacity-75" : ""
            )}
            onClick={() => createQuery()}
          >
            <Spinner show={loading} />
            {props?.isEdit ? "Update" : "Create"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
