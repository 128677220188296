import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import CloudResourceService from "../../../app/service/cloudResourceService";
import { toast } from "react-toastify";
import Pulse from "../../../app/pulse/pulse";
import commonService from "../../../app/service/commonService";
import { CommonLineChart } from "../../common/lineChart";
import { CommonAreaChart } from "../../common/areaChart";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  show: boolean;
  closeModal: () => void;
  resourceId: string;
  currentSize: string;
  location: string;
}

export default function VMRightSizeModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [loading, setLoading] = useState<boolean>(false);

  const cancelButtonRef = useRef(null);

  const [sizeList, setSizeList] = useState<VmSize[]>([]);
  const [selectedSize, setSelectedSize] = useState<string>("");

  const [loadingComparison, setLoadingComparison] = useState<boolean>(false);

  const [vmInfoData, setVmInfoData] = useState<AzureVmRightSizeInfoItem>();

  const [cpuChartData, setCpuChartData] = useState<
    Array<{ date: string; cpu: number }>
  >([]);
  const [networkChartData, setNetworkChartData] = useState<
    Array<{ date: string; In: number; Out: number }>
  >([]);
  const [availableMemoryBytesChartData, setAvailableMemoryBytesChartData] =
    useState<Array<{ date: string; availableMemoryBytes: number }>>([]);
  const [vmAvailabilityMetricChartData, setVmAvailabilityMetricChartData] =
    useState<Array<{ date: string; vmAvailabilityMetric: number }>>([]);
  const [osDiskBytesChartData, setOsDiskBytesChartData] = useState<
    Array<{
      date: string;
      Read: number;
      Write: number;
    }>
  >([]);
  const [osDiskOperationsChartData, setOsDiskOperationsChartData] = useState<
    Array<{
      date: string;
      Read: number;
      Write: number;
    }>
  >([]);
  const [dataDiskBytesChartData, setDataDiskBytesChartData] = useState<
    Array<{
      date: string;
      Read: number;
      Write: number;
    }>
  >([]);
  const [dataDiskOperationsChartData, setDataDiskOperationsChartData] =
    useState<
      Array<{
        date: string;
        Read: number;
        Write: number;
      }>
    >([]);

  const rangeList: string[] = ["minimum", "average", "maximum"];
  const [selectedRange, setSelectedRange] = useState<string>(rangeList[0]);
  const lookBackList: number[] = [1, 7, 15, 30, 60, 90];
  const [lookBackPeriod, setLookBackPeriod] = useState<number>(1);

  const [percentageFactor, setPercentageFactor] = useState<number>(0);
  const [memoryFactor, setMemoryFactor] = useState<number>(0);
  const [osBytesCurrentCapability, setOsBytesCurrentCapability] =
    useState<number>(0);
  const [osBytesTargetCapability, setOsBytesTargetCapability] =
    useState<number>(0);
  const [osOperationsCurrentCapability, setOsOperationsCurrentCapability] =
    useState<number>(0);
  const [osOperationsTargetCapability, setOsOperationsTargetCapability] =
    useState<number>(0);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      getListAvailableSizes();
    }
  }, [props.show]);

  const getListAvailableSizes = () => {
    setLoading(true);
    CloudResourceService.getListAvailableSizes(props?.resourceId)
      .then((response: any) => {
        setLoading(false);
        const result = response?.data?.result || [];
        setSizeList(result);
      })
      .catch((e: any) => {
        setLoading(false);
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const onChangeSize = (size: string) => {
    if (!loadingComparison) {
      setSelectedSize(size);
      if (size) {
        getVmRightSizeInfo(size, lookBackPeriod);
      }
    }
  };

  const onChangeLookback = (e: any) => {
    setLookBackPeriod(Number(e?.target?.value));
    getVmRightSizeInfo(selectedSize, Number(e?.target?.value));
  };

  const getVmRightSizeInfo = (size: string, lookBackPeriodData: number) => {
    setLoadingComparison(true);
    const customerId = commonService.getAccountId();
    const request: AzureVmRightSizeInfoRequest = {
      customerId: customerId,
      resourceId: props?.resourceId,
      targetSize: size,
      lookBackPeriodDays: lookBackPeriodData,
      addMetric: false,
      resetCache: false,
    };
    CloudResourceService.getVmRightSizeInfo(request)
      .then((response: any) => {
        setLoadingComparison(false);
        const result = response?.data?.result || {};
        setVmInfoData(result);
        setChart(result, selectedRange);
      })
      .catch((e: any) => {
        setLoadingComparison(false);
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const onChangeRange = (range: string) => {
    setSelectedRange(range);
    setChart(vmInfoData as AzureVmRightSizeInfoItem, range);
  };

  const setChart = (result: AzureVmRightSizeInfoItem, range: string) => {
    const vmMetrics = result?.metrics || [];
    vmMetrics.forEach((x: any) => {
      if (x.name === "Percentage CPU") {
        const currentCapabilityValue =
          (result.comparison.currentSize.skuInfo.capabilities || []).find(
            (r) => r.capabilityName === "vCPUsAvailable"
          )?.capabilityValue || 0;
        const targetCapabilityValue =
          (result.comparison.targetSize.skuInfo.capabilities || []).find(
            (r) => r.capabilityName === "vCPUsAvailable"
          )?.capabilityValue || 0;
        const factor = currentCapabilityValue / targetCapabilityValue;
        setPercentageFactor(factor);
        setCpuChartData(
          getChartData3(
            x,
            factor,
            range,
            "Percentage CPU(Current)",
            "Percentage CPU(Target)"
          )
        );
      } else if (x.name === "Network In") {
        const netWorkOutData =
          vmMetrics.find((ts: any) => ts.name === "Network Out")?.timeSeries[0]
            ?.values || [];
        setNetworkChartData(
          commonService.getChartData2(
            x,
            netWorkOutData,
            range,
            "Network In",
            "Network Out"
          )
        );
      } else if (x.name === "Available Memory Bytes") {
        const currentCapabilityValue =
          (result.comparison.currentSize.skuInfo.capabilities || []).find(
            (r) => r.capabilityName === "MemoryGB"
          )?.capabilityValue || 0;
        const targetCapabilityValue =
          (result.comparison.targetSize.skuInfo.capabilities || []).find(
            (r) => r.capabilityName === "MemoryGB"
          )?.capabilityValue || 0;
        const factor = targetCapabilityValue / currentCapabilityValue;
        setMemoryFactor(factor);
        setAvailableMemoryBytesChartData(
          getChartData4(
            x,
            range,
            "Current",
            "Target",
            currentCapabilityValue * 1000,
            targetCapabilityValue * 1000
          )
        );
      } else if (x.name === "VmAvailabilityMetric") {
        setVmAvailabilityMetricChartData(
          commonService.getChartData(x, range, "VM Availability Metric")
        );
      } else if (x.name === "OS Disk Read Bytes/sec") {
        const currentCapabilityValue =
          (result.comparison.currentSize.skuInfo.capabilities || []).find(
            (r) => r.capabilityName === "UncachedDiskBytesPerSecond"
          )?.capabilityValue || 0;
        const targetCapabilityValue =
          (result.comparison.targetSize.skuInfo.capabilities || []).find(
            (r) => r.capabilityName === "UncachedDiskBytesPerSecond"
          )?.capabilityValue || 0;
        setOsBytesCurrentCapability(
          commonService.parseBytes(currentCapabilityValue)
        );
        setOsBytesTargetCapability(
          commonService.parseBytes(targetCapabilityValue)
        );
        const osDiskWriteBytesData =
          vmMetrics.find((ts: any) => ts.name === "OS Disk Write Bytes/sec")
            ?.timeSeries[0]?.values || [];
        setOsDiskBytesChartData(
          commonService.getChartData2(
            x,
            osDiskWriteBytesData,
            range,
            "Read Bytes/sec (MB)",
            "Write Bytes/sec (MB)",
            true
          )
        );
      } else if (x.name === "OS Disk Read Operations/Sec") {
        const currentCapabilityValue =
          (result.comparison.currentSize.skuInfo.capabilities || []).find(
            (r) => r.capabilityName === "UncachedDiskIOPS"
          )?.capabilityValue || 0;
        const targetCapabilityValue =
          (result.comparison.targetSize.skuInfo.capabilities || []).find(
            (r) => r.capabilityName === "UncachedDiskIOPS"
          )?.capabilityValue || 0;
        setOsOperationsCurrentCapability(
          commonService.parseCount(currentCapabilityValue)
        );
        setOsOperationsTargetCapability(
          commonService.parseCount(targetCapabilityValue)
        );
        const osDiskWriteOperationsData =
          vmMetrics.find(
            (ts: any) => ts.name === "OS Disk Write Operations/Sec"
          )?.timeSeries[0]?.values || [];
        setOsDiskOperationsChartData(
          commonService.getChartData2(
            x,
            osDiskWriteOperationsData,
            range,
            "Read Operations/sec (MB)",
            "Write Operations/sec (MB)"
          )
        );
      } else if (x.name === "Data Disk Read Bytes/sec") {
        const dataDiskWriteBytesData =
          vmMetrics.find((ts: any) => ts.name === "Data Disk Write Bytes/sec")
            ?.timeSeries[0]?.values || [];
        setDataDiskBytesChartData(
          commonService.getChartData2(
            x,
            dataDiskWriteBytesData,
            range,
            "Read Bytes/sec (MB)",
            "Write Bytes/sec (MB)",
            true
          )
        );
      } else if (x.name === "Data Disk Read Operations/Sec") {
        const dataDiskWriteOperationsData =
          vmMetrics.find(
            (ts: any) => ts.name === "Data Disk Write Operations/Sec"
          )?.timeSeries[0]?.values || [];
        setDataDiskOperationsChartData(
          commonService.getChartData2(
            x,
            dataDiskWriteOperationsData,
            range,
            "Read Operations/sec (MB)",
            "Write Operations/sec (MB)"
          )
        );
      }
    });
  };

  const getChartData3 = (
    vmMetric: any,
    factor: number,
    range: string,
    key1: string,
    key2: string,
    hasBytes?: boolean
  ) => {
    const timeSeriesData = vmMetric.timeSeries[0]?.values || [];
    let count1 = 0;
    let count2 = 0;
    const arr: any[] = [];
    timeSeriesData.forEach((t: any) => {
      const currentDate = new Date(t?.timeStamp).toLocaleString();
      count1 = t[range] || 0;
      count2 = count1 * factor || 0;
      const data: any = {
        date: currentDate,
      };
      data[key1] = hasBytes
        ? commonService.parseBytes(count1)
        : commonService.parseCount(count1);
      data[key2] = hasBytes
        ? commonService.parseBytes(count2)
        : commonService.parseCount(count2);
      arr.push(data);
    });
    return arr || [];
  };

  const getChartData4 = (
    vmMetric: any,
    range: string,
    key1: string,
    key2: string,
    currentSizeMemory: number,
    targetSizeMemory: number
  ) => {
    const timeSeriesData = vmMetric.timeSeries[0]?.values || [];
    let count1 = 0;
    let count2 = 0;
    const arr: any[] = [];
    timeSeriesData.forEach((t: any, i: number) => {
      const currentDate = new Date(t?.timeStamp).toLocaleString();
      count1 =
        (commonService.parseBytes(t[range] || 0) / currentSizeMemory) * 100;
      count2 =
        (commonService.parseBytes(t[range] || 0) / targetSizeMemory) * 100;
      const data: any = {
        date: currentDate,
      };
      data[key1] = count1;
      data[key2] = count2;
      arr.push(data);
    });
    return arr || [];
  };

  const commitmentPeriodsList: Array<{ name: string; value: string }> = [
    { name: "1 YRS", value: "license1Year" },
    { name: "3 YRS", value: "license3Year" },
  ];
  const [commitmentPeriod, setCommitmentPeriod] = useState<string>(
    commitmentPeriodsList[1].value
  );

  const onChangeCommitmentPeriod = (e: any) => {
    setCommitmentPeriod(e?.target?.value);
  };

  const is1Year = () => {
    return commitmentPeriod === "license1Year";
  };

  const timeFrameList: Array<{ timeFrame: string; value: number }> = [
    { timeFrame: "1 Hour", value: 1 },
    { timeFrame: "1 Day", value: 24 },
    { timeFrame: "1 Week", value: 168 },
    { timeFrame: "1 Month", value: 720 },
    { timeFrame: "1 Year", value: 8640 },
  ];
  const [timeFrame, setTimeFrame] = useState<number>(timeFrameList[0].value);

  const onChangeTimeFrame = (e: any) => {
    setTimeFrame(e?.target?.value);
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-7xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              VM Right Size
            </Dialog.Title>
            <div className="mt-2">
              {loading ? (
                <Pulse show={loading} />
              ) : (
                <>
                  <div className="flex justify-between">
                    <div>
                      <label
                        htmlFor="size"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Size
                      </label>
                      <div className="mt-1 mb-4">
                        <select
                          id="size"
                          name="size"
                          className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                          value={selectedSize}
                          onChange={(e) => {
                            onChangeSize(e?.target?.value);
                          }}
                        >
                          <option value="" key="none">
                            Select Size
                          </option>
                          {sizeList.map((size: VmSize) => (
                            <option value={size.name} key={size.name}>
                              {size.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="lookBackPeriod"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Lookback Period
                      </label>
                      <div className="mt-1 mb-4">
                        <select
                          id="lookBackPeriod"
                          name="lookBackPeriod"
                          className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                          value={lookBackPeriod}
                          onChange={(e) => {
                            onChangeLookback(e);
                          }}
                        >
                          {lookBackList.map((range: number) => (
                            <option value={range} key={range}>
                              {range}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="CommitmentPeriod"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Commitment Period
                      </label>
                      <div className="mt-1 mb-4">
                        <select
                          id="CommitmentPeriod"
                          name="CommitmentPeriod"
                          className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                          value={commitmentPeriod}
                          onChange={(e) => {
                            onChangeCommitmentPeriod(e);
                          }}
                        >
                          {commitmentPeriodsList.map((period: any) => (
                            <option value={period.value} key={period.value}>
                              {period.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="TimeFrame"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Forecast
                      </label>
                      <div className="mt-1 mb-4">
                        <select
                          id="TimeFrame"
                          name="TimeFrame"
                          className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                          value={timeFrame}
                          onChange={(e) => {
                            onChangeTimeFrame(e);
                          }}
                        >
                          {timeFrameList.map((time: any) => (
                            <option value={time.value} key={time.value}>
                              {time.timeFrame}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  {loadingComparison ? (
                    <Pulse show={loadingComparison} />
                  ) : (
                    <>
                      <div>
                        <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
                          <li
                            key="1"
                            className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                          >
                            <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                              <div className="text-sm font-medium leading-6 text-gray-900">
                                Current Size
                              </div>
                            </div>
                            <div className="flex justify-between gap-x-4 py-3 ml-4">
                              Hardware
                            </div>
                            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Name</dt>
                                <dd className="text-gray-700">
                                  {commonService.sizePipe(
                                    vmInfoData?.comparison?.currentSize
                                      ?.hardware?.paygCost?.skuName || ""
                                  )}
                                </dd>
                              </div>
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Price</dt>
                                <dd className="text-gray-700">
                                  $
                                  {commonService.fixDecimal(
                                    (vmInfoData?.comparison?.currentSize
                                      ?.hardware?.paygCost?.retailPrice || 0) *
                                      timeFrame
                                  )}
                                </dd>
                              </div>
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Location</dt>
                                <dd className="text-gray-700">
                                  {
                                    vmInfoData?.comparison?.currentSize
                                      ?.hardware?.paygCost?.location
                                  }
                                </dd>
                              </div>
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">
                                  Reservation Price
                                </dt>
                                <dd className="text-gray-700">
                                  $
                                  {commonService.fixDecimal(
                                    vmInfoData?.comparison?.currentSize
                                      ?.hardware?.reservations[
                                      is1Year() ? 0 : 1
                                    ]?.retailPrice || 0
                                  )}
                                </dd>
                              </div>
                            </dl>

                            <div className="flex justify-between gap-x-4 py-3 ml-4">
                              Operative System
                            </div>
                            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">
                                  Break Even Run Time Percentage
                                </dt>
                                <dd className="text-gray-700">
                                  {commonService.fixDecimal(
                                    ((vmInfoData?.comparison?.currentSize
                                      ?.operativeSystem as any) || {})[
                                      commitmentPeriod
                                    ]?.breakEvenRunTimePercentage * 100
                                  )}
                                  %
                                </dd>
                              </div>
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">
                                  Savings Per Hour
                                </dt>
                                <dd className="text-gray-700">
                                  {commonService.fixDecimal(
                                    (((vmInfoData?.comparison?.currentSize
                                      ?.operativeSystem as any) || {})[
                                      commitmentPeriod
                                    ]?.savingsPerHour || 0) * timeFrame
                                  )}
                                </dd>
                              </div>
                            </dl>

                            <div className="flex justify-between gap-x-4 py-3 ml-4">
                              SKU Info
                            </div>
                            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Family</dt>
                                <dd className="text-gray-700">
                                  {
                                    vmInfoData?.comparison?.currentSize?.skuInfo
                                      ?.family
                                  }
                                </dd>
                              </div>
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Tier</dt>
                                <dd className="text-gray-700">
                                  {
                                    vmInfoData?.comparison?.currentSize?.skuInfo
                                      ?.tier
                                  }
                                </dd>
                              </div>
                            </dl>
                          </li>
                          <li
                            key="2"
                            className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                          >
                            <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                              <div className="text-sm font-medium leading-6 text-gray-900">
                                Target Size
                              </div>
                            </div>
                            <div className="flex justify-between gap-x-4 py-3 ml-4">
                              Hardware
                            </div>
                            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Name</dt>
                                <dd className="text-gray-700">
                                  {commonService.sizePipe(
                                    vmInfoData?.comparison?.targetSize?.hardware
                                      ?.paygCost?.skuName || ""
                                  )}
                                </dd>
                              </div>
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Price</dt>
                                <dd className="text-gray-700">
                                  $
                                  {commonService.fixDecimal(
                                    (vmInfoData?.comparison?.targetSize
                                      ?.hardware?.paygCost?.retailPrice || 0) *
                                      timeFrame
                                  )}
                                </dd>
                              </div>
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Location</dt>
                                <dd className="text-gray-700">
                                  {
                                    vmInfoData?.comparison?.targetSize?.hardware
                                      ?.paygCost?.location
                                  }
                                </dd>
                              </div>
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">
                                  Reservation Price
                                </dt>
                                <dd className="text-gray-700">
                                  $
                                  {commonService.fixDecimal(
                                    vmInfoData?.comparison?.targetSize?.hardware
                                      ?.reservations[is1Year() ? 0 : 1]
                                      ?.retailPrice || 0
                                  )}
                                </dd>
                              </div>
                            </dl>

                            <div className="flex justify-between gap-x-4 py-3 ml-4">
                              Operative System
                            </div>
                            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">
                                  Break Even Run Time Percentage
                                </dt>
                                <dd className="text-gray-700">
                                  {commonService.fixDecimal(
                                    ((vmInfoData?.comparison?.targetSize
                                      ?.operativeSystem as any) || {})[
                                      commitmentPeriod
                                    ]?.breakEvenRunTimePercentage * 100
                                  )}
                                  %
                                </dd>
                              </div>
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">
                                  Savings Per Hour
                                </dt>
                                <dd className="text-gray-700">
                                  {commonService.fixDecimal(
                                    (((vmInfoData?.comparison?.targetSize
                                      ?.operativeSystem as any) || {})[
                                      commitmentPeriod
                                    ]?.savingsPerHour || 0) * timeFrame
                                  )}
                                </dd>
                              </div>
                            </dl>

                            <div className="flex justify-between gap-x-4 py-3 ml-4">
                              SKU Info
                            </div>
                            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Family</dt>
                                <dd className="text-gray-700">
                                  {
                                    vmInfoData?.comparison?.targetSize?.skuInfo
                                      ?.family
                                  }
                                </dd>
                              </div>
                              <div className="flex justify-between gap-x-4 py-3">
                                <dt className="text-gray-500">Tier</dt>
                                <dd className="text-gray-700">
                                  {
                                    vmInfoData?.comparison?.targetSize?.skuInfo
                                      ?.tier
                                  }
                                </dd>
                              </div>
                            </dl>
                          </li>
                        </ul>
                      </div>

                      <div>
                        <label
                          htmlFor="timeZone"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Range
                        </label>
                        <div className="mt-1 mb-1">
                          <select
                            id="timezone"
                            name="timezone"
                            className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                            value={selectedRange}
                            onChange={(e) => onChangeRange(e?.target?.value)}
                          >
                            {rangeList.map((range: string) => (
                              <option value={range} key={range}>
                                {range}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="divide-y divide-gray-100">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                            <div className="flex items-center gap-x-4 bg-white p-2">
                              <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                                Percentage CPU
                              </p>
                            </div>
                            <div
                              className="flex shrink-0 items-center gap-x-4"
                              style={{ width: "100%", height: "125px" }}
                            >
                              <CommonLineChart
                                chartData={cpuChartData}
                                xAxisKey="date"
                                lineKey="Percentage CPU(Current)"
                                lineKey2="Percentage CPU(Target)"
                                factor={percentageFactor}
                                isPercentage={true}
                              />
                            </div>
                          </div>

                          <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                            <div className="flex items-center gap-x-4 bg-white p-2">
                              <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                                Network In/Out
                              </p>
                            </div>
                            <div
                              className="flex shrink-0 items-center gap-x-4"
                              style={{ width: "100%", height: "125px" }}
                            >
                              <CommonLineChart
                                chartData={networkChartData}
                                xAxisKey="date"
                                lineKey="Network In"
                                lineKey2="Network Out"
                              />
                            </div>
                          </div>

                          <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                            <div className="flex items-center gap-x-4 bg-white p-2">
                              <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                                Available Memory Bytes (Percentage)
                              </p>
                            </div>
                            <div
                              className="flex shrink-0 items-center gap-x-4"
                              style={{ width: "100%", height: "125px" }}
                            >
                              <CommonLineChart
                                chartData={availableMemoryBytesChartData}
                                xAxisKey="date"
                                lineKey="Current"
                                lineKey2="Target"
                                factor={memoryFactor}
                              />
                            </div>
                          </div>

                          <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                            <div className="flex items-center gap-x-4 bg-white p-2">
                              <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                                VM Availability Metric
                              </p>
                            </div>
                            <div
                              className="flex shrink-0 items-center gap-x-4"
                              style={{ width: "100%", height: "125px" }}
                            >
                              <CommonLineChart
                                chartData={vmAvailabilityMetricChartData}
                                xAxisKey="date"
                                lineKey="VM Availability Metric"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="divide-y divide-gray-100">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                          <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                            <div className="flex items-center gap-x-4 bg-white p-2">
                              <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                                OS Disk Bytes Read/Write (MB)
                              </p>
                            </div>
                            <div
                              className="flex shrink-0 items-center gap-x-4"
                              style={{ width: "100%", height: "125px" }}
                            >
                              <CommonAreaChart
                                chartData={osDiskBytesChartData}
                                xAxisKey="date"
                                lineKey="Read Bytes/sec (MB)"
                                lineKey2="Write Bytes/sec (MB)"
                                refKey1="Capability (MB) (Current)"
                                refKey2="Capability (MB) (Target)"
                                refVal1={osBytesCurrentCapability}
                                refVal2={osBytesTargetCapability}
                              />
                            </div>
                          </div>

                          <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                            <div className="flex items-center gap-x-4 bg-white p-2">
                              <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                                OS Disk Operations Read/Write
                              </p>
                            </div>
                            <div
                              className="flex shrink-0 items-center gap-x-4"
                              style={{ width: "100%", height: "125px" }}
                            >
                              <CommonAreaChart
                                chartData={osDiskOperationsChartData}
                                xAxisKey="date"
                                lineKey="Read Operations/sec (MB)"
                                lineKey2="Write Operations/sec (MB)"
                                refKey1="Capability (MB) (Current)"
                                refKey2="Capability (MB) (Target)"
                                refVal1={osOperationsCurrentCapability}
                                refVal2={osOperationsTargetCapability}
                              />
                            </div>
                          </div>

                          <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                            <div className="flex items-center gap-x-4 bg-white p-2">
                              <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                                Data Disk Bytes Read/Write (MB)
                              </p>
                            </div>
                            <div
                              className="flex shrink-0 items-center gap-x-4"
                              style={{ width: "100%", height: "125px" }}
                            >
                              <CommonAreaChart
                                chartData={dataDiskBytesChartData}
                                xAxisKey="date"
                                lineKey="Read Bytes/sec (MB)"
                                lineKey2="Write Bytes/sec (MB)"
                                refKey1="Capability (MB) (Current)"
                                refKey2="Capability (MB) (Target)"
                                refVal1={osBytesCurrentCapability}
                                refVal2={osBytesTargetCapability}
                              />
                            </div>
                          </div>

                          <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                            <div className="flex items-center gap-x-4 bg-white p-2">
                              <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                                Data Disk Operations Read/Write
                              </p>
                            </div>
                            <div
                              className="flex shrink-0 items-center gap-x-4"
                              style={{ width: "100%", height: "125px" }}
                            >
                              <CommonAreaChart
                                chartData={dataDiskOperationsChartData}
                                xAxisKey="date"
                                lineKey="Read Operations/sec (MB)"
                                lineKey2="Write Operations/sec (MB)"
                                refKey1="Capability (MB) (Current)"
                                refKey2="Capability (MB) (Target)"
                                refVal1={osOperationsCurrentCapability}
                                refVal2={osOperationsTargetCapability}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
