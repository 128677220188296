import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import CloudResourceService from "../../../app/service/cloudResourceService";
import commonService from "../../../app/service/commonService";
import { toast } from "react-toastify";
import Pulse from "../../../app/pulse/pulse";
import {
  CircleStackIcon,
  ComputerDesktopIcon,
} from "@heroicons/react/20/solid";
import { CommonLineChart } from "../../common/lineChart";
import { CommonAreaChart } from "../../common/areaChart";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  show: boolean;
  closeModal: () => void;
  resourceId: string;
}

export default function VMDetailModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [loading, setLoading] = useState<boolean>(false);

  const [vmData, setVmData] = useState<AzureVirtualMachineItem | any>({});

  const [vmCpuChartData, setVmCpuChartData] = useState<
    Array<{ date: string; cpu: number }>
  >([]);
  const [vmNetworkChartData, setVmNetworkChartData] = useState<
    Array<{ date: string; In: number; Out: number }>
  >([]);
  const [vmAvailableMemoryBytesChartData, setVmAvailableMemoryBytesChartData] =
    useState<Array<{ date: string; availableMemoryBytes: number }>>([]);
  const [vmAvailabilityMetricChartData, setVmAvailabilityMetricChartData] =
    useState<Array<{ date: string; vmAvailabilityMetric: number }>>([]);
  const [vmOsDiskBytesChartData, setVmOsDiskBytesChartData] = useState<
    Array<{
      date: string;
      Read: number;
      Write: number;
    }>
  >([]);
  const [vmOsDiskOperationsChartData, setVmOsDiskOperationsChartData] = useState<
    Array<{
      date: string;
      Read: number;
      Write: number;
    }>
  >([]);
  const [vmDataDiskBytesChartData, setVmDataDiskBytesChartData] = useState<
    Array<{
      date: string;
      Read: number;
      Write: number;
    }>
  >([]);
  const [vmDataDiskOperationsChartData, setVmDataDiskOperationsChartData] =
    useState<
      Array<{
        date: string;
        Read: number;
        Write: number;
      }>
    >([]);

  const vmRangeList: string[] = ["minimum", "average", "maximum"];
  const [vmSelectedRange, setVmSelectedRange] = useState<string>(vmRangeList[0]);

  const cancelButtonRef = useRef(null);

  const [capabilities, setCapabilities] = useState<any[]>([]);

  const [osBytesCapability, setOsBytesCapability] = useState<number>(0);
  const [osOperationsCapability, setOsOperationsCapability] =
    useState<number>(0);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      init();
    }
  }, [props.show]);

  const init = () => {
    setVmSelectedRange(vmRangeList[0]);
    getVM(lookBackPeriod);
  };

  const getVM = (
    lookBackPeriodData: number,
    isLookBackPeriodChange?: boolean
  ) => {
    setLoading(true);
    const customerId = commonService.getAccountId();
    CloudResourceService.getVM(customerId, props.resourceId, lookBackPeriodData)
      .then((response: any) => {
        const result = response?.data?.result || {};
        setVmData(result);
        setRunTime(result);
        if (isLookBackPeriodChange) {
          setChart(result, vmSelectedRange, capabilities);
          setLoading(false);
        } else {
          getSkuInfo(result?.size, result?.skuInfo?.location, result);
        }
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const getSkuInfo = (
    size: string,
    location: string,
    vmResultData: AzureVirtualMachineItem
  ) => {
    if (size && location) {
      CloudResourceService.getSkuInfo(size, location)
        .then((response: any) => {
          const result = response?.data?.result || {};
          const vmCapabilities = result?.capabilities || [];
          setCapabilities(vmCapabilities);
          setChart(vmResultData, vmSelectedRange, vmCapabilities);
          setLoading(false);
        })
        .catch((e: any) => {
          setCapabilities([]);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const setRunTime = (res: any) => {
    const vmAvailabilityMetric: any =
      res.vmMetrics.find((x: any) => x.name === "VmAvailabilityMetric")
        ?.timeSeries || [];
    let count = 0;
    const vmMetricChartData = vmAvailabilityMetric[0]?.values || [];
    vmMetricChartData.forEach((v: any) => {
      count += v[vmSelectedRange] || 0;
    });
    const runTime = count / vmMetricChartData?.length;
    setTotalRunTime(count);
    setTotalHours(vmMetricChartData?.length);
    setRunTimeHours(parseFloat(parseFloat(runTime.toString()).toFixed(1)));
    const hbuCost =
      (res.summary?.ahbuLicenses?.msrp || 0) *
      (res.summary?.ahbuLicenseCount || 0);
    const hbuSavings =
      ((hbuCost || 0) - (res.summary?.osPaygCost || 0)) / (hbuCost || 0);
    setHbuCostPerSavings(Math.abs(hbuSavings || 0));
    const reservationSavings =
      (res.summary?.reservationsCost || 0) /
      ((res.summary?.hardwaretPaygCost || 0) -
        (res.summary?.reservationsCost || 0));
    setReservationsCostPerSavings(Math.abs(reservationSavings || 0));
  };

  const getChartData3 = (
    vmMetric: any,
    range: string,
    key: string,
    memory: number
  ) => {
    const timeSeriesData = vmMetric.timeSeries[0]?.values || [];
    let count = 0;
    const arr: any[] = [];
    timeSeriesData.forEach((t: any) => {
      const currentDate = new Date(t?.timeStamp).toLocaleString();
      count = (commonService.parseBytes(t[range] || 0) / memory) * 100;
      const data: any = {
        date: currentDate,
      };
      data[key] = count;
      arr.push(data);
    });
    return arr || [];
  };

  const setChart = (
    result: AzureVirtualMachineItem,
    range: string,
    vmCapabilities: any[]
  ) => {
    const vmMetrics = result?.vmMetrics || [];
    vmMetrics.forEach((x: any) => {
      if (x.name === "Percentage CPU") {
        setVmCpuChartData(commonService.getChartData(x, range, "Percentage CPU"));
      } else if (x.name === "Network In") {
        const netWorkOutData =
          vmMetrics.find((ts: any) => ts.name === "Network Out")?.timeSeries[0]
            ?.values || [];
        setVmNetworkChartData(
          commonService.getChartData2(
            x,
            netWorkOutData,
            range,
            "Network In",
            "Network Out"
          )
        );
      } else if (x.name === "Available Memory Bytes") {
        const currentCapabilityValue =
          (vmCapabilities || []).find((r) => r.capabilityName === "MemoryGB")
            ?.capabilityValue || 0;
        setVmAvailableMemoryBytesChartData(
          getChartData3(
            x,
            range,
            "Available Memory Bytes",
            currentCapabilityValue * 1000
          )
        );
      } else if (x.name === "VmAvailabilityMetric") {
        setVmAvailabilityMetricChartData(
          commonService.getChartData(x, range, "VM Availability Metric")
        );
      } else if (x.name === "OS Disk Read Bytes/sec") {
        const currentCapabilityValue =
          (vmCapabilities || []).find(
            (r) => r.capabilityName === "UncachedDiskBytesPerSecond"
          )?.capabilityValue || 0;
        setOsBytesCapability(commonService.parseBytes(currentCapabilityValue));
        const osDiskWriteBytesData =
          vmMetrics.find((ts: any) => ts.name === "OS Disk Write Bytes/sec")
            ?.timeSeries[0]?.values || [];
        setVmOsDiskBytesChartData(
          commonService.getChartData2(
            x,
            osDiskWriteBytesData,
            range,
            "Read Bytes/sec (MB)",
            "Write Bytes/sec (MB)",
            true
          )
        );
      } else if (x.name === "OS Disk Read Operations/Sec") {
        const currentCapabilityValue =
          (vmCapabilities || []).find(
            (r) => r.capabilityName === "UncachedDiskIOPS"
          )?.capabilityValue || 0;
        setOsOperationsCapability(
          commonService.parseCount(currentCapabilityValue)
        );
        const osDiskWriteOperationsData =
          vmMetrics.find(
            (ts: any) => ts.name === "OS Disk Write Operations/Sec"
          )?.timeSeries[0]?.values || [];
        setVmOsDiskOperationsChartData(
          commonService.getChartData2(
            x,
            osDiskWriteOperationsData,
            range,
            "Read Operations/sec (MB)",
            "Write Operations/sec (MB)"
          )
        );
      } else if (x.name === "Data Disk Read Bytes/sec") {
        const dataDiskWriteBytesData =
          vmMetrics.find((ts: any) => ts.name === "Data Disk Write Bytes/sec")
            ?.timeSeries[0]?.values || [];
        setVmDataDiskBytesChartData(
          commonService.getChartData2(
            x,
            dataDiskWriteBytesData,
            range,
            "Read Bytes/sec (MB)",
            "Write Bytes/sec (MB)",
            true
          )
        );
      } else if (x.name === "Data Disk Read Operations/Sec") {
        const dataDiskWriteOperationsData =
          vmMetrics.find(
            (ts: any) => ts.name === "Data Disk Write Operations/Sec"
          )?.timeSeries[0]?.values || [];
        setVmDataDiskOperationsChartData(
          commonService.getChartData2(
            x,
            dataDiskWriteOperationsData,
            range,
            "Read Operations/sec (MB)",
            "Write Operations/sec (MB)"
          )
        );
      }
    });
  };

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const onChangeRange = (range: string) => {
    setVmSelectedRange(range);
    setChart(vmData, range, capabilities);
  };

  const lookBackList: number[] = [1, 7, 15, 30, 60, 90];
  const [lookBackPeriod, setLookBackPeriod] = useState<number>(30);
  const [runTimeHours, setRunTimeHours] = useState<number>(0);
  const [totalRunTime, setTotalRunTime] = useState<number>(0);
  const [totalHours, setTotalHours] = useState<number>(0);
  const [hbuCostPerSavings, setHbuCostPerSavings] = useState<number>(0);
  const [reservationsCostPerSavings, setReservationsCostPerSavings] =
    useState<number>(0);

  const onChangeLookback = (e: any) => {
    setLookBackPeriod(Number(e?.target?.value));
    getVM(Number(e?.target?.value), true);
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-7xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              VM Details
            </Dialog.Title>
            <div className="mt-2">
              {loading ? (
                <Pulse show={loading} />
              ) : (
                <>
                  <div>
                    <label
                      htmlFor="lookBackPeriod"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Lookback Period
                    </label>
                    <div className="mt-1 mb-4">
                      <select
                        id="lookBackPeriod"
                        name="lookBackPeriod"
                        className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                        value={lookBackPeriod}
                        onChange={(e) => {
                          onChangeLookback(e);
                        }}
                      >
                        {lookBackList.map((range: number) => (
                          <option value={range} key={range}>
                            {range}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div>
                    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                      <li
                        key="1"
                        className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                      >
                        <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                          <div className="text-sm font-medium leading-6 text-gray-900">
                            General
                          </div>
                        </div>
                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Name</dt>
                            <dd className="text-gray-700">{vmData?.name}</dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Size</dt>
                            <dd className="text-gray-700">{vmData?.size}</dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Location</dt>
                            <dd className="text-gray-700">
                              {vmData?.location}
                            </dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">OS</dt>
                            <dd className="text-gray-700">
                              {vmData?.operativeSystem}
                            </dd>
                          </div>
                        </dl>
                      </li>
                      <li
                        key="2"
                        className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                      >
                        <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                          <div className="text-sm font-medium leading-6 text-gray-900">
                            Cost
                          </div>
                        </div>
                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Total RunTime</dt>
                            <dd className="text-gray-700">{totalRunTime}</dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Total Hours</dt>
                            <dd className="text-gray-700">{totalHours}</dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">RunTime Hours</dt>
                            <dd className="text-gray-700">{runTimeHours}%</dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">OS Cost</dt>
                            <dd className="text-gray-700">
                              {vmData?.summary?.osPaygCost || 0}
                            </dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">
                              HBU Cost / Savings
                            </dt>
                            <dd className="text-gray-700">
                              {hbuCostPerSavings}
                            </dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Hardware Cost</dt>
                            <dd className="text-gray-700">
                              {vmData?.summary?.hardwaretPaygCost || 0}
                            </dd>
                          </div>
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">
                              Reservations Cost / Savings
                            </dt>
                            <dd className="text-gray-700">
                              {reservationsCostPerSavings}
                            </dd>
                          </div>
                        </dl>
                      </li>

                      <li
                        key="3"
                        className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                      >
                        <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                          <div className="text-sm font-medium leading-6 text-gray-900">
                            Tags
                          </div>
                        </div>

                        <p className="gap-x-4 px-6 py-4">
                          {Object.keys(vmData?.data?.tags || {}).map(
                            (key: string) => (
                              <span
                                className="text-sm leading-6 mr-2"
                                key={key}
                              >
                                <span className="mb-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  {`${key} : ${
                                    (vmData?.data?.tags || {})[key]
                                  }`}
                                </span>
                              </span>
                            )
                          )}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-8">
                    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                      {(vmData?.disks || []).map((disk: any) => (
                        <li
                          key={disk.uniqueId}
                          className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                        >
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Disk
                            </div>
                            <div className="relative ml-auto">
                              {disk?.osType ? (
                                <ComputerDesktopIcon
                                  aria-hidden="true"
                                  className="w-5 h-5"
                                />
                              ) : (
                                <CircleStackIcon
                                  aria-hidden="true"
                                  className="w-5 h-5"
                                />
                              )}
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Name</dt>
                              <dd className="text-gray-700">
                                {disk?.sku?.tier}
                              </dd>
                            </div>
                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">SKU</dt>
                              <dd className="text-gray-700">
                                {disk?.diskSizeGB}
                              </dd>
                            </div>
                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Type</dt>
                              <dd className="text-gray-700">
                                {disk?.osType ? "OS Disk" : "Data Disk"}
                              </dd>
                            </div>
                          </dl>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="mt-6 border-t border-gray-100">
                    <div>
                      <label
                        htmlFor="timeZone"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Range
                      </label>
                      <div className="mt-1 mb-1" id="vm-timezone-sec">
                        <select
                          id="timezone"
                          name="timezone"
                          className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                          value={vmSelectedRange}
                          onChange={(e) => onChangeRange(e?.target?.value)}
                        >
                          {vmRangeList.map((range: string) => (
                            <option value={range} key={range}>
                              {range}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 bg-white p-2">
                            <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                              Percentage CPU
                            </p>
                          </div>
                          <div
                            className="flex shrink-0 items-center gap-x-4"
                            style={{ width: "100%", height: "125px" }}
                          >
                            <CommonLineChart
                              chartData={vmCpuChartData}
                              xAxisKey="date"
                              lineKey="Percentage CPU"
                            />
                          </div>
                        </div>

                        <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 bg-white p-2">
                            <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                              Network In/Out
                            </p>
                          </div>
                          <div
                            className="flex shrink-0 items-center gap-x-4"
                            style={{ width: "100%", height: "125px" }}
                          >
                            <CommonLineChart
                              chartData={vmNetworkChartData}
                              xAxisKey="date"
                              lineKey="Network In"
                              lineKey2="Network Out"
                            />
                          </div>
                        </div>

                        <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 bg-white p-2">
                            <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                              Available Memory Bytes (Percentage)
                            </p>
                          </div>
                          <div
                            className="flex shrink-0 items-center gap-x-4"
                            style={{ width: "100%", height: "125px" }}
                          >
                            <CommonLineChart
                              chartData={vmAvailableMemoryBytesChartData}
                              xAxisKey="date"
                              lineKey="Available Memory Bytes"
                            />
                          </div>
                        </div>

                        <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 bg-white p-2">
                            <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                              VM Availability Metric
                            </p>
                          </div>
                          <div
                            className="flex shrink-0 items-center gap-x-4"
                            style={{ width: "100%", height: "125px" }}
                          >
                            <CommonLineChart
                              chartData={vmAvailabilityMetricChartData}
                              xAxisKey="date"
                              lineKey="VM Availability Metric"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                        <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 bg-white p-2">
                            <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                              OS Disk Bytes Read/Write (MB)
                            </p>
                          </div>
                          <div
                            className="flex shrink-0 items-center gap-x-4"
                            style={{ width: "100%", height: "125px" }}
                          >
                            <CommonAreaChart
                              chartData={vmOsDiskBytesChartData}
                              xAxisKey="date"
                              lineKey="Read Bytes/sec (MB)"
                              lineKey2="Write Bytes/sec (MB)"
                              refKey1="Capability (MB)"
                              refVal1={osBytesCapability}
                            />
                          </div>
                        </div>

                        <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 bg-white p-2">
                            <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                              OS Disk Operations Read/Write
                            </p>
                          </div>
                          <div
                            className="flex shrink-0 items-center gap-x-4"
                            style={{ width: "100%", height: "125px" }}
                          >
                            <CommonAreaChart
                              chartData={vmOsDiskOperationsChartData}
                              xAxisKey="date"
                              lineKey="Read Operations/sec (MB)"
                              lineKey2="Write Operations/sec (MB)"
                              refKey1="Capability (MB)"
                              refVal1={osOperationsCapability}
                            />
                          </div>
                        </div>

                        <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 bg-white p-2">
                            <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                              Data Disk Bytes Read/Write (MB)
                            </p>
                          </div>
                          <div
                            className="flex shrink-0 items-center gap-x-4"
                            style={{ width: "100%", height: "125px" }}
                          >
                            <CommonAreaChart
                              chartData={vmDataDiskBytesChartData}
                              xAxisKey="date"
                              lineKey="Read Bytes/sec (MB)"
                              lineKey2="Write Bytes/sec (MB)"
                              refKey1="Capability (MB)"
                              refVal1={osBytesCapability}
                            />
                          </div>
                        </div>

                        <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                          <div className="flex items-center gap-x-4 bg-white p-2">
                            <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                              Data Disk Operations Read/Write
                            </p>
                          </div>
                          <div
                            className="flex shrink-0 items-center gap-x-4"
                            style={{ width: "100%", height: "125px" }}
                          >
                            <CommonAreaChart
                              chartData={vmDataDiskOperationsChartData}
                              xAxisKey="date"
                              lineKey="Read Operations/sec (MB)"
                              lineKey2="Write Operations/sec (MB)"
                              refKey1="Capability (MB)"
                              refVal1={osOperationsCapability}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8">
                    <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                      {(vmData?.data?.resources || []).map((resource: any) => (
                        <li
                          key={resource.uniqueId}
                          className={commonService.classNames(
                            "overflow-hidden rounded-xl border-2 bg-white shadow-md",
                            resource?.provisioningState === "Succeeded"
                              ? "border-green-500"
                              : "border-red-500"
                          )}
                        >
                          <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                            <div className="text-sm font-medium leading-6 text-gray-900">
                              Extension
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Name</dt>
                              <dd className="text-gray-700">
                                {resource?.extensionType}
                              </dd>
                            </div>
                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Publisher</dt>
                              <dd className="text-gray-700">
                                {resource?.publisher}
                              </dd>
                            </div>
                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Version</dt>
                              <dd className="text-gray-700">
                                {resource?.typeHandlerVersion}
                              </dd>
                            </div>
                          </dl>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
