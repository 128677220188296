import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { ModalWrapper } from "../../common/modalWrapper";
import { ResourceLink } from "../../../app/common/commonControls";
interface Props {
  show: boolean;
  closeModal: () => void;
  data: AzureResourceVirtualMachineSQLServerInfoItemDetail;
}

export default function SqlServerInfoModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [sqlData, setSqlData] =
    useState<AzureResourceVirtualMachineSQLServerInfoItemDetail>(props?.data || {});

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    setSqlData(props?.data || {});
  }, [props.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-7xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Sql Info
            </Dialog.Title>
            <div className="mt-2">
              <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                    <div className="sm:grid-cols-1">
                      <div className="flex">
                        <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                          Name:
                        </dt>
                        <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                          {sqlData?.name}
                        </dd>
                      </div>
                      <div className="flex">
                        <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                          License Type:
                        </dt>
                        <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                          {sqlData?.properties?.sqlServerLicenseType}
                        </dd>
                      </div>
                      <div className="flex">
                        <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                          Sql Management:
                        </dt>
                        <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                          {sqlData?.properties?.sqlManagement}
                        </dd>
                      </div>
                      <div className="flex">
                        <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                          Location:
                        </dt>
                        <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                          {sqlData?.location}
                        </dd>
                      </div>
                      <div className="flex">
                        <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                          Resource Group:
                        </dt>
                        <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                          {sqlData?.resourceGroup}
                        </dd>
                      </div>
                      <div className="flex">
                        <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                          Resource ID:
                        </dt>
                        <dd className="text-sm leading-6 text-gray-700 sm:mt-0 break-all">
                          {sqlData?.properties?.virtualMachineResourceId}
                        </dd>
                      </div>
                    </div>
                    <div className="sm:grid-cols-1">
                      <div className="flex">
                        <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                          ID:
                        </dt>
                        <dd className="text-sm leading-6 text-gray-700 sm:mt-0 break-all">
                          {sqlData?.id}
                        </dd>
                      </div>
                      <div className="flex">
                        <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                          Sql Image Offer:
                        </dt>
                        <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                          {sqlData?.properties?.sqlImageOffer}
                        </dd>
                      </div>
                      <div className="flex">
                        <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                          Enable Automatic Upgrade:
                        </dt>
                        <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                          {sqlData?.properties?.enableAutomaticUpgrade
                            ? "Yes"
                            : "No"}
                        </dd>
                      </div>
                      <div className="flex">
                        <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                          Resource Link:
                        </dt>
                        <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                          <ResourceLink
                            link={sqlData?.resourceLink}
                            index={0}
                            tooltipId="-vm-resource-link-tooltip"
                          />
                        </dd>
                      </div>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={closeModal}
            ref={cancelButtonRef}
          >
            Close
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
