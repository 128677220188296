import { InformationCircleIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { StatWithPercentageChange } from "../../app/common/commonControls";
import Pulse from "../../app/pulse/pulse";
import AuthenticationService from "../../app/service/authService";
import commonService from "../../app/service/commonService";
import Spinner from "../../app/spinner/spinner";
import { TableHead } from "../common/sortingControl";
import AccountModal from "../initiatives/modal/accountModal";
import AccountSalesStatusModal from "../initiatives/modal/accountSalesStatusModal";

export default function ResellerAccounts() {
  const [accounts, setAccounts] = useState<AccountItem[]>([]);
  const [accountsFilteredData, setAccountsFilteredData] = useState<
    AccountItem[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const [currentAccountId, setCurrentAccountId] = useState<string>("");

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customerId");
  const [selectedAccount, setSelectedAccount] = useState<AccountItem>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<AccountItem>();
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);

  useEffect(() => {
    getAccount();
  }, []);

  const initAccount = () => {
    setCurrentAccountId(
      commonService.selectedAccount?.id ||
        customerId ||
        commonService.userDetail()?.accountId
    );
    const accounts = parseAccount(commonService.userDetail()?.accounts || []);
    setAccounts(accounts);
    setAccountsFilteredData(accounts);
  };

  const parseAccount = (data: AccountItem[]) => {
    data.forEach(x => {
      x.parentName = data.find(d => d.id === x.parentId)?.name;
      x.subscriptionTooltipText = '';
      x.billingOfLastMonthTotal = 0;
      x.billingOfMonthBeforeLastMonthTotal = 0;
      (x.subscriptions || []).forEach(s => {
        x.subscriptionTooltipText = `${x.subscriptionTooltipText} ${s.name} : ${commonService.getCurrencyValue(s?.billingOfLastMonth?.total)} \n`;
        if(typeof x.billingOfLastMonthTotal !== 'undefined') {
          x.billingOfLastMonthTotal += s.billingOfLastMonth?.total || 0;
        }
        if(typeof x.billingOfMonthBeforeLastMonthTotal !== 'undefined') {
          x.billingOfMonthBeforeLastMonthTotal += s.billingOfMonthBeforeLastMonth?.total || 0;
        }
      });
    });
    return data;
  }

  const getAccount = () => {
    setLoading(true);
    const currentUser =
      JSON.parse(localStorage.getItem("currentUser") ?? "{}") || {};
    AuthenticationService.getUserById(currentUser?.id, true, false)
      .then((response: any) => {
        const result: User = response?.data?.result;
        localStorage.setItem("userDetail", JSON.stringify(result || ""));
        initAccount();
        setLoading(false);
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const refreshAccount = (refresh?: boolean) => {
    if (refresh) {
      getAccount();
    }
  };

  const openCreateAccount = () => {
    setIsEdit(false);
    setShowCreateAccountModal(true);
  };

  const closeCreateAccountModal = () => {
    setShowCreateAccountModal(false);
  };

  const [sortBy, setSortBy] = useState<string>("");
  const [asc, setAsc] = useState<boolean>(true);

  const onSortChange = (newSortBy: string, newAsc: boolean) => {
    setSortBy(newSortBy);
    setAsc(newAsc);
    sortData(accountsFilteredData, newSortBy, newAsc);
  };

  const sortData = (
    accountData: AccountItem[],
    sortByName: string,
    ascVal: boolean
  ) => {
    const sortedData = (accountData || []).sort((a: AccountItem, b: AccountItem) => {
      let nameA: any = "";
      let nameB: any = "";
      if (sortByName === "users") {
        nameA = a?.users?.length || 0;
        nameB = b?.users?.length || 0;
      } else if(sortByName === "subscriptions") {
        nameA = a?.subscriptions?.length || 0;
        nameB = b?.subscriptions?.length || 0;
      } else if(sortByName === "invoice") {
        nameA = a?.billingOfLastMonthTotal || 0;
        nameB = b?.billingOfLastMonthTotal || 0;
      } else {
        nameA = (a as any)[sortByName]?.toString()?.toLowerCase();
        nameB = (b as any)[sortByName]?.toString()?.toLowerCase();
      }

      if (ascVal) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
        return 0;
      }
    });
    setAccountsFilteredData(sortedData);
  };

  const getFilterValue = () => {
    return { name: "", parentId: "", size: "", website: "", users: "", subscriptions: "", invoice: "" };
  };

  const [filterVal, setFilterVal] = useState<any>(getFilterValue());

  const onFilterChange = (name: string, val: string) => {
    filterVal[name] = val;
    const filtered: AccountItem[] = [];
    accounts?.forEach((x) => {
      if (
        (!filterVal?.name ||
          x.name
            ?.toString()
            ?.toLowerCase()
            .includes(filterVal?.name?.toString()?.toLowerCase())) &&
        (!filterVal?.parentId ||
          x.parentId
            ?.toString()
            ?.toLowerCase()
            .includes(filterVal?.parentId?.toString()?.toLowerCase())) &&
        (!filterVal?.size ||
          x.size
            ?.toString()
            ?.toLowerCase()
            .includes(filterVal?.size?.toString()?.toLowerCase())) &&
        (!filterVal?.website ||
          x.website
            ?.toString()
            ?.toLowerCase()
            .includes(filterVal?.website?.toString()?.toLowerCase())) &&
        (filterVal?.users?.toString()?.trim()?.length === 0 ||
          filterVal?.users?.toString() === (x?.users || [])?.length?.toString()) &&
        (filterVal?.subscriptions?.toString()?.trim()?.length === 0 ||
          filterVal?.subscriptions?.toString() === (x?.subscriptions || [])?.length?.toString()) &&
          (!filterVal?.invoice ||
            x.billingOfLastMonthTotal
              ?.toString()
              ?.toLowerCase()
              .includes(filterVal?.invoice?.toString()?.toLowerCase()))
      ) {
        filtered.push(x);
      }
    });
    setAccountsFilteredData(filtered);
  };

  const clearFilters = () => {
    setFilterVal(getFilterValue());
    setAccountsFilteredData(accounts);
  };

  const gotoAccounts = (accountId: string) => {
    if (accountId && currentAccountId !== accountId) {
      window.open(`/?customerId=${accountId}`, "_blank");
    }
  };

  const openEditAccount = (account: AccountItem) => {
    setSelectedAccount(account);
    setIsEdit(true);
    setShowCreateAccountModal(true);
  };

  const openUpdateStatus = (account: AccountItem) => {
    setSelectedUser(account);
    setShowUpdateStatusModal(true);
  };

  const closeUpdateStatusModal = () => {
    setShowUpdateStatusModal(false);
  };
  
  return (
    <>
      {showCreateAccountModal && (
        <AccountModal
          show={showCreateAccountModal}
          account={selectedAccount}
          isEdit={isEdit}
          closeModal={closeCreateAccountModal}
          refreshDetails={refreshAccount}
        />
      )}
      {showUpdateStatusModal && (
        <AccountSalesStatusModal
          show={showUpdateStatusModal}
          account={selectedUser}
          closeModal={closeUpdateStatusModal}
          refreshDetails={refreshAccount}
        />
      )}
      <div className="py-10 mx-auto  px-4 sm:px-6 lg:px-8">
        <main>
          <div className="divide-y divide-white/5">
            <div className="grid  grid-cols-1 gap-x-8 gap-y-10 px-4 py-2 sm:px-6 lg:px-8">
              {loading ? (
                <Pulse show={loading} />
              ) : (
                <>
                  <div className="flex">
                    <button
                      id="reseller-account-btn"
                      type="button"
                      disabled={loading}
                      className={commonService.classNames(
                        "mr-4 block rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                        loading ? "disabled:opacity-75" : ""
                      )}
                      onClick={() => getAccount()}
                    >
                      <Spinner show={loading} />
                      Refresh
                    </button>
                    {accounts?.length > accountsFilteredData?.length && (
                      <button
                        type="button"
                        className="block rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                        onClick={clearFilters}
                      >
                        Clear All Filters
                      </button>
                    )}
                  </div>
                  {accountsFilteredData?.length > 0 ? (
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Name"
                              sortName="name"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.name}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Parent ID"
                              sortName="parentId"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.parentId}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Sales Status"
                              sortName="saleStatus"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.saleStatus}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Size"
                              sortName="size"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.size}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Website"
                              sortName="website"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.website}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Total Users"
                              sortName="users"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.users}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Subscription Count"
                              sortName="subscriptions"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.subscriptions}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            <TableHead
                              name="Invoice"
                              sortName="invoice"
                              sortBy={sortBy}
                              asc={asc}
                              filterVal={filterVal.invoice}
                              onSortChange={onSortChange}
                              onFilterChange={onFilterChange}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {accountsFilteredData.map((account, i: number) => (
                          <tr key={account.id}>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900">
                              <div className="flex items-center">
                                <span
                                  className="flex items-center cursor-pointer"
                                  onClick={() => gotoAccounts(account.id)}
                                >
                                  {account.name}
                                </span>
                                <button
                                  onClick={() => openEditAccount(account)}
                                >
                                  <PencilIcon
                                    aria-hidden="true"
                                    className="w-4 h-4"
                                  />
                                </button>
                                <InformationCircleIcon
                                  aria-hidden="true"
                                  className="w-4 h-4 ml-1"
                                />
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                              {account.parentName || account.parentId}
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                              <button
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                                onClick={() => openUpdateStatus(account)}
                              >
                                {account.saleStatus}
                              </button>
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                              {account.size}
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                              {account.website}
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                              {(account.users || []).length}
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                              <div
                                className="flex items-center cursor-pointer"
                                data-tooltip-id={
                                  "-account-subscription-tooltip" + i
                                }
                              >
                                {(account.subscriptions || []).length}
                                <InformationCircleIcon
                                  aria-hidden="true"
                                  className="w-4 h-4 ml-1"
                                />
                                <Tooltip
                                  id={"-account-subscription-tooltip" + i}
                                  arrowColor="transparent"
                                  place="top"
                                >
                                  <div className="whitespace-pre-line">
                                    {account.subscriptionTooltipText || 'No Item Found.'}
                                  </div>
                                </Tooltip>
                              </div>
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-sm text-gray-500">
                              <StatWithPercentageChange stat={commonService.getCurrencyValue(account.billingOfLastMonthTotal)}  prevValue={account.billingOfMonthBeforeLastMonthTotal || 0} currentValue={account.billingOfLastMonthTotal || 0} />
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td
                            colSpan={8}
                            className="relative whitespace-nowrap py-5 text-sm font-medium sm:pr-0"
                          >
                            <button
                              type="button"
                              className=" w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              onClick={openCreateAccount}
                            >
                              Add
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <button
                      type="button"
                      className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={openCreateAccount}
                    >
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                        />
                      </svg>
                      <span className="mt-2 block text-sm font-semibold text-gray-900">
                        Create Account
                      </span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
