import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { ModalWrapper } from "../../common/modalWrapper";
import commonService from "../../../app/service/commonService";
import JSONViewModal from "./jsonViewModal";
interface Props {
  show: boolean;
  closeModal: () => void;
  complianceData: any[];
}

export default function ComplianceModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);

  const [showJSONViewModal, setShowJSONViewModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>({});

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const openJSONViewModal = (data: any) => {
    setSelectedData(data || {});
    setShowJSONViewModal(true);
  };

  const closeJSONViewModal = () => {
    setShowJSONViewModal(false);
  };

  return (
      <>
      {showJSONViewModal && (
        <JSONViewModal
          show={showJSONViewModal}
          closeModal={closeJSONViewModal}
          data={JSON.stringify(selectedData || {})}
          modalTitle="Additional Info"
          codeTitle="Info"
        />
      )}
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-7xl sm:p-6"
      style={{
        display: showJSONViewModal ? "none" : "initial"
      }}>
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Compliance Data
            </Dialog.Title>
            <div className="mt-2 overflow-x-auto">
              {(props?.complianceData || []).length > 0 ? (
                <table className="min-w-full divide-y divide-gray-300 mt-2">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                      >
                        Scope
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                      >
                        Additional Info
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {(props?.complianceData || [])?.map((item: any) => (
                      <tr key={item.id}>
                        <td className="px-3 py-2 text-sm font-small text-gray-900">
                          {item?.definitionProperties?.displayName}
                        </td>
                        <td className={commonService.classNames("px-3 py-2 text-sm font-small text-gray-900",
                        item?.complianceState === 'Compliant' ? 'text-green-700': 'text-red-700')}>
                          {item?.complianceState}
                        </td>
                        <td className="px-3 py-2 text-sm font-small text-gray-900">
                          {item?.assignmentProperties?.scope}
                        </td>
                        <td className="px-3 py-2 text-sm font-small text-gray-900">
                          <button
                            className="text-indigo-600 hover:text-indigo-900 mr-3"
                            onClick={() => openJSONViewModal(item)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <button
                  type="button"
                  className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                    />
                  </svg>
                  <span className="mt-2 block text-sm font-semibold text-gray-900">
                    No Data Found.
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={closeModal}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
    </>
  );
}
