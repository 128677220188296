import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { ModalWrapper } from "../../common/modalWrapper";
import { EmptyView } from "../../../app/common/commonControls";
interface Props {
  show: boolean;
  closeModal: () => void;
  vmName: string;
  recommendationItems: AzureResourceAdvisorRecommendationItemDetail[];
  recommendationItem: AzureResourceAdvisorRecommendationItemDetail;
  isModalFromTooltip?: boolean;
}

export default function VmAdvisorDetailModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel
        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-7xl sm:p-6"
        id="protected-vm-modal"
      >
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Recommendations: {props?.vmName}
            </Dialog.Title>
            <div className="mt-2 overflow-x-auto">
              {props?.isModalFromTooltip ? (
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                      <div className="sm:grid-cols-1">
                        <div className="flex">
                          <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                            Name:
                          </dt>
                          <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                            {props?.recommendationItem?.name}
                          </dd>
                        </div>
                        <div className="flex">
                          <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                            Category:
                          </dt>
                          <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                            {props?.recommendationItem?.properties?.category}
                          </dd>
                        </div>
                        <div className="flex">
                          <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                            Description:
                          </dt>
                          <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                            {
                              props?.recommendationItem?.properties
                                ?.shortDescription?.problem
                            }
                          </dd>
                        </div>
                      </div>
                      <div className="sm:grid-cols-1">
                        <div className="flex">
                          <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                            ID:
                          </dt>
                          <dd className="text-sm leading-6 text-gray-700 sm:mt-0 break-all">
                            {props?.recommendationItem?.id}
                          </dd>
                        </div>
                        <div className="flex">
                          <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                            Impact:
                          </dt>
                          <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                            {props?.recommendationItem?.properties?.impact}
                          </dd>
                        </div>
                        <div className="flex">
                          <dt className="text-sm font-medium leading-6 text-gray-900 mr-8 w-48">
                            Proposed Solution:
                          </dt>
                          <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                            {
                              props?.recommendationItem?.properties
                                ?.shortDescription?.solution
                            }
                          </dd>
                        </div>
                      </div>
                    </div>
                  </dl>
                </div>
              ) : (
                <>
                  {(props?.recommendationItems || []).length > 0 ? (
                    <table className="min-w-full divide-y divide-gray-300 mt-2">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                          >
                            Category
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                          >
                            Impact
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-2 text-left text-md font-semibold text-gray-900"
                          >
                            Proposed Solution
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {(props?.recommendationItems || [])?.map((item) => (
                          <tr key={item.id}>
                            <td className="px-3 py-2 text-sm font-small text-gray-900">
                              {item?.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {item?.properties?.category}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                              {item?.properties?.impact}
                            </td>
                            <td className="px-3 py-2 text-sm font-small text-gray-900">
                              {item?.properties?.shortDescription?.problem}
                            </td>
                            <td className="px-3 py-2 text-sm font-small text-gray-900">
                              {item?.properties?.shortDescription?.solution}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <EmptyView />
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={closeModal}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
