import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { CommonLineChart } from "../../common/lineChart";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";
import { EmptyView, ResourceLink } from "../../../app/common/commonControls";

interface Props {
  show: boolean;
  closeModal: () => void;
  data: AzureResourceDiskItem;
}

export default function VmStorageViewModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);

  const [diskReadBytesChartData, setDiskReadBytesChartData] = useState<
    Array<{ date: string; "Composite Disk Read Bytes/sec": number }>
  >([]);
  const [diskReadOperationsChartData, setDiskReadOperationsChartData] =
    useState<
      Array<{ date: string; "Composite Disk Read Operations/sec": number }>
    >([]);
  const [diskWriteBytesChartData, setDiskWriteBytesChartData] = useState<
    Array<{ date: string; "Composite Disk Write Bytes/sec": number }>
  >([]);
  const [diskWriteOperationsChartData, setDiskWriteOperationsChartData] =
    useState<
      Array<{ date: string; "Composite Disk Write Operations/sec": number }>
    >([]);

  const [diskPaidBurstIOPSChartData, setDiskPaidBurstIOPSChartData] = useState<
    Array<{ date: string; DiskPaidBurstIOPS: number }>
  >([]);

  const rangeList: string[] = ["minimum", "average", "maximum"];
  const [selectedRange, setSelectedRange] = useState<string>(rangeList[0]);

  const [storageData, setStorageData] = useState<AzureResourceDiskItem>(
    props?.data || {}
  );

  const [storageDetailsData, setStorageDetailsData] = useState<AzureResourceDiskItemDetail[]>([]);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      init();
    }
  }, [props.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const onChangeRange = (range: string) => {
    setSelectedRange(range);
    setChart(storageData, range);
  };

  const init = () => {
    setSelectedRange(rangeList[0]);
    setStorageData(props?.data || {});
    setStorageDetailsData(props?.data?.detail || []);
    setChart(props?.data || {}, rangeList[0]);
  };

  const setChart = (result: any, range: string) => {
    const metrics = result?.metrics || [];
    metrics.forEach((x: any) => {
      if (x.name === "Composite Disk Read Bytes/sec") {
        setDiskReadBytesChartData(
          commonService.getChartData(x, range, "Composite Disk Read Bytes/sec")
        );
      } else if (x.name === "Composite Disk Read Operations/sec") {
        setDiskReadOperationsChartData(
          commonService.getChartData(
            x,
            range,
            "Composite Disk Read Operations/sec"
          )
        );
      } else if (x.name === "Composite Disk Write Bytes/sec") {
        setDiskWriteBytesChartData(
          commonService.getChartData(x, range, "Composite Disk Write Bytes/sec")
        );
      } else if (x.name === "Composite Disk Write Operations/sec") {
        setDiskWriteOperationsChartData(
          commonService.getChartData(
            x,
            range,
            "Composite Disk Write Operations/sec"
          )
        );
      } else if (x.name === "DiskPaidBurstIOPS") {
        setDiskPaidBurstIOPSChartData(
          commonService.getChartData(x, range, "DiskPaidBurstIOPS")
        );
      }
    });
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-7xl sm:p-6"
      id="vm-storage-view-modal">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Managed Disk Details
            </Dialog.Title>
            <div className="mt-2">
              { storageData?.resourceId ? 
              <>
              <div>
                <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
                  <li
                    key="1"
                    className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                  >
                    <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                      <div className="text-sm font-medium leading-6 text-gray-900">
                        General
                      </div>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Name</dt>
                        <dd className="text-gray-700">
                          <div className="flex items-center">
                            {storageDetailsData[0]?.name}

                            <ResourceLink
                              link={storageData?.resourceLink}
                              index={0}
                              tooltipId="-vm-disk-link-tooltip"
                            />
                          </div>
                        </dd>
                      </div>

                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Location</dt>
                        <dd className="text-gray-700">
                          {storageDetailsData[0]?.location}
                        </dd>
                      </div>

                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Resource Group</dt>
                        <dd className="text-gray-700">
                          {storageDetailsData[0]?.resourceGroup}
                        </dd>
                      </div>

                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Storage Type</dt>
                        <dd className="text-gray-700">
                          {storageDetailsData[0]?.storageType}
                        </dd>
                      </div>

                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Disk Size (GB)</dt>
                        <dd className="text-gray-700">
                          {storageDetailsData[0]?.properties?.diskSizeGB}
                        </dd>
                      </div>

                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">OS Type</dt>
                        <dd className="text-gray-700">
                          {storageDetailsData[0]?.properties?.osType}
                        </dd>
                      </div>

                    </dl>
                  </li>

                  <li
                    key="2"
                    className="overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md"
                  >
                    <div className="flex items-center gap-x-4 border-b border-gray-500/3 bg-white p-6">
                      <div className="text-sm font-medium leading-6 text-gray-900">
                        Tags
                      </div>
                    </div>

                    <p className="gap-x-4 px-6 py-4">
                      {Object.keys(storageDetailsData[0]?.tags || {}).map(
                        (key: string) => (
                          <span className="text-sm leading-6 mr-2" key={key}>
                            <span className="mb-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              {`${key} : ${
                                (storageDetailsData[0]?.tags || {})[key as any]
                              }`}
                            </span>
                          </span>
                        )
                      )}
                    </p>
                  </li>
                </ul>
              </div>
              <div className="mt-6 border-t border-gray-100">
                <div>
                  <label
                    htmlFor="storage-timeZone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Range
                  </label>
                  <div className="mt-1 mb-1">
                    <select
                      id="storage-view-timeZone"
                      name="timezone"
                      className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 [&_*]:text-black"
                      value={selectedRange}
                      onChange={(e) => onChangeRange(e?.target?.value)}
                    >
                      {rangeList.map((range: string) => (
                        <option value={range} key={range}>
                          {range}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                      <div className="flex items-center gap-x-4 bg-white p-2">
                        <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                          Composite Disk Read Bytes/sec
                        </p>
                      </div>
                      <div
                        className="flex shrink-0 items-center gap-x-4"
                        style={{ width: "100%", height: "125px" }}
                      >
                        <CommonLineChart
                          chartData={diskReadBytesChartData}
                          xAxisKey="date"
                          lineKey="Composite Disk Read Bytes/sec"
                        />
                      </div>
                    </div>

                    <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                      <div className="flex items-center gap-x-4 bg-white p-2">
                        <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                          Composite Disk Read Operations/sec
                        </p>
                      </div>
                      <div
                        className="flex shrink-0 items-center gap-x-4"
                        style={{ width: "100%", height: "125px" }}
                      >
                        <CommonLineChart
                          chartData={diskReadOperationsChartData}
                          xAxisKey="date"
                          lineKey="Composite Disk Read Operations/sec"
                        />
                      </div>
                    </div>

                    <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                      <div className="flex items-center gap-x-4 bg-white p-2">
                        <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                          Composite Disk Write Bytes/sec
                        </p>
                      </div>
                      <div
                        className="flex shrink-0 items-center gap-x-4"
                        style={{ width: "100%", height: "125px" }}
                      >
                        <CommonLineChart
                          chartData={diskWriteBytesChartData}
                          xAxisKey="date"
                          lineKey="Composite Disk Write Bytes/sec"
                        />
                      </div>
                    </div>

                    <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                      <div className="flex items-center gap-x-4 bg-white p-2">
                        <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                          Composite Disk Write Operations/sec
                        </p>
                      </div>
                      <div
                        className="flex shrink-0 items-center gap-x-4"
                        style={{ width: "100%", height: "125px" }}
                      >
                        <CommonLineChart
                          chartData={diskWriteOperationsChartData}
                          xAxisKey="date"
                          lineKey="Composite Disk Write Operations/sec"
                        />
                      </div>
                    </div>

                    <div className="sm:grid-cols-1 overflow-hidden rounded-xl border border-gray-200 bg-white shadow-md">
                      <div className="flex items-center gap-x-4 bg-white p-2">
                        <p className="text-sm font-medium leading-6 text-gray-900 text-center w-full">
                          DiskPaidBurstIOPS
                        </p>
                      </div>
                      <div
                        className="flex shrink-0 items-center gap-x-4"
                        style={{ width: "100%", height: "125px" }}
                      >
                        <CommonLineChart
                          chartData={diskPaidBurstIOPSChartData}
                          xAxisKey="date"
                          lineKey="DiskPaidBurstIOPS"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </>: 
              <EmptyView /> }
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
