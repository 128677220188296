import { useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import { CodeGroup } from "../../documents";
import { ModalWrapper } from "../../common/modalWrapper";
import commonService from "../../../app/service/commonService";
interface Props {
  show: boolean;
  closeModal: () => void;
  data: any;
  modalTitle: string;
  codeTitle: string;
}

export default function JSONViewModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);

  const [codeText, setCodeText] = useState<string>("<h1>ff</h1>");

  const cancelButtonRef = useRef(null);

  const [infoData, setInfoData] = useState<any>({});

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      try {
        const parseData = JSON.parse(props?.data || {});
        setInfoData(parseData);
        setCodeText(props?.data);
      } catch (_) {
        setInfoData({});
      }
    }
  }, [props?.show]);

  const closeModal = () => {
    setOpen(false);
    props.closeModal();
  };

  const download = () => {
    commonService.downloadJSON(infoData);
  }

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              {props?.modalTitle}
            </Dialog.Title>
            <div className="mt-2 p-4">
              <div className="my-6 max-h-[500px] overflow-y-auto">
                <CodeGroup title={props?.codeTitle} code={codeText} tag="">
                  <div>
                    <pre className="language-json">
                      {JSON.stringify(infoData, null, 2)}
                    </pre>
                  </div>
                </CodeGroup>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-1"
            onClick={download}
          >
            Download
          </button>

          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
