import { useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Note, SelectionDataList } from "../../documents";

import img1 from "../../../assets/document/img1.png";
import img2 from "../../../assets/document/img2.png";
import img3 from "../../../assets/document/img3.png";
import img4 from "../../../assets/document/img4.png";
import img5 from "../../../assets/document/img5.png";
import img6 from "../../../assets/document/img6.png";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ModalWrapper } from "../../common/modalWrapper";

interface Props {
  show: boolean;
  closeModal?: any;
}
export default function ExportDocumentModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
  }, [props?.show]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-5xl sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Configure Export
            </Dialog.Title>
            <div className="mt-2 p-4">
              <div className="mt-4 mb-12">
                <Note type="info">
                  <p className="flex items-center">
                    Create FOCUS cost Export Documentation FinOps hubs:{" "}
                    <a
                      className="underline flex items-center ml-1"
                      href="https://microsoft.github.io/finops-toolkit/hubs#-create-a-new-hub"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <InformationCircleIcon
                        aria-hidden="true"
                        className="w-4 h-4"
                      />
                      finOps hubs
                    </a>
                  </p>
                  <p className="flex items-center">
                    ARM Link:{" "}
                    <a
                      href="https://aka.ms/exportsv2"
                      className="underline flex items-center ml-1"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <InformationCircleIcon
                        aria-hidden="true"
                        className="w-4 h-4"
                      />
                      Microsoft Azure
                    </a>
                  </p>
                </Note>

                <p className="mt-8 mb-4 text-sm text-gray-700">
                  Create a new FOCUS cost export using the following settings:
                </p>
                <p className="mb-4">
                  <SelectionDataList
                    title="Type of data"
                    value="Cost and usage details (FOCUS)"
                  />
                </p>
                <Note type="important">
                  FinOps hubs 0.2 requires FOCUS cost data. While FOCUS is fully
                  supported, the option to export FOCUS cost data from Cost
                  Management is currently in preview and has not rolled out to
                  everyone yet. In order to create and manage FOCUS exports,
                  please use the Exports preview link.
                </Note>
                <p className="mb-4">
                  <SelectionDataList
                    title="Dataset version"
                    value="1.0-preview (v1)"
                  />
                </p>
                <p className="mb-4">
                  <SelectionDataList
                    title="Frequency"
                    value="Daily export of month-to-date costs"
                  />
                </p>
                <Note type="info">
                  Configuring a daily export starts in the current month. If you
                  want to backfill historical data, create a one-time export and
                  set the start/end dates to the desired date range.
                </Note>
                <p className="mb-4">
                  <SelectionDataList
                    title="File partitioning"
                    value="On"
                    hideBackground={true}
                  />
                </p>
                <p className="mb-4">
                  <SelectionDataList
                    title="Storage account"
                    value="(Use subscription/resource from step 1)"
                    hideBackground={true}
                  />
                </p>
                <p className="mb-4">
                  <SelectionDataList title="Container" value="msexports" />
                </p>
                <p className="mb-4">
                  <SelectionDataList
                    title="Directory"
                    value="(Use the resource ID of the scope1 you’re exporting without the first “/”)"
                    hideBackground={true}
                  />
                </p>
                <p className="my-2 text-sm text-gray-700">
                  Run your export.
                  <ul className="ml-4">
                    <li>
                      <span>1.</span> Exports can take up to a day to show up
                      after first created.
                    </li>
                    <li>
                      <span>2.</span> Use the Run now command at the top of the
                      Cost Management Exports page.
                    </li>
                    <li>
                      <span>3.</span> Your data should be available within 15
                      minutes or so, depending on how big your account is.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="mt-4 mb-12">
                <p className="my-2 text-sm text-gray-700">Steps:</p>
                <p className="mt-2 mb-4 text-sm text-gray-700">
                  1. Go to Cost Management{" "}
                  <img src={img1} alt="" className="relative mt-3" />
                </p>
                <Note type="info">
                  Ensure Scope is set to the subscriptions we want to cover
                </Note>
                <p className="mt-8 mb-4 text-sm text-gray-700">
                  2. Navigate to Export{" "}
                  <img src={img2} alt="" className="relative mt-3" />
                </p>
                <p className="mt-8 mb-4 text-sm text-gray-700">
                  3. Click on Create{" "}
                  <img src={img3} alt="" className="relative mt-3" />
                </p>
                <p className="mt-8 mb-4 text-sm text-gray-700">
                  4. Fill the form Type - Cost and Usage Details Export Name -
                  floatfinops-focus-export (or any other name)
                  <p className="mt-4 mb-4">
                    <SelectionDataList
                      title="Dataset Version"
                      value="2019-11-01"
                    />
                  </p>
                  <p className="mb-4">
                    <SelectionDataList
                      title="Frequency"
                      value="Daily export of month to date cost"
                    />
                  </p>
                  <img src={img4} alt="" className="relative mt-3" />
                </p>
                <p className="my-2 text-sm text-gray-700">
                  5. Destination
                  <p className="mt-4 mb-4">
                    <SelectionDataList
                      title="File partitioning"
                      value="On"
                      hideBackground={true}
                    />
                  </p>
                  <p className="mb-4">
                    <SelectionDataList
                      title="Storage account"
                      value="(Use subscription/resource from step 1)"
                      hideBackground={true}
                    />
                  </p>
                  <p className="mb-4">
                    <SelectionDataList title="Container" value="msexports" />
                  </p>
                  <p className="mb-4">
                    <SelectionDataList
                      title="Directory"
                      value="(Use the resource ID of the scope1 you’re exporting without the first “/”)"
                      hideBackground={true}
                    />
                  </p>
                  <p className="my-2 text-sm text-gray-700">
                    Run your export.
                    <ul className="ml-4">
                      <li>
                        <span>1.</span> Exports can take up to a day to show up
                        after first created.
                      </li>
                      <li>
                        <span>2.</span> Use the Run now command at the top of
                        the Cost Management Exports page.
                      </li>
                      <li>
                        <span>3.</span> Your data should be available within 15
                        minutes or so, depending on how big your account is.
                      </li>
                    </ul>
                  </p>
                  <img src={img5} alt="" className="relative mt-3" />
                </p>
                <p className="mt-8 mb-4 text-sm text-gray-700">
                  6. Create and Run Now{" "}
                  <img src={img6} alt="" className="relative mt-3" />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
