import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Pulse from "../pulse/pulse";
import commonService from "../service/commonService";
import MarketPlaceService from "../service/marketplaceService";

export default function MSRegistration() {
  const dbkProfile = commonService.dbkProfile;
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const [subscriptionData, setSubscriptionData] = useState<MarketPlaceSubscriptionResolveItem | any>();

  useEffect(() => {
    if (token) {
      resolveSubscription();
    }
  }, []);

  const resolveSubscription = () => {
    setLoading(true);
    const model: MarketPlaceResolveSubscriptionRequest = {
        ResolveToken: token
    }
    MarketPlaceService.resolveSubscription(model).then((response: any) => {
      commonService.showMessage(response?.data || {});
      const result = response?.data?.isSuccess;
      setLoading(false);
      if(result) {
        setSubscriptionData(response.data.result)
        setSuccess(true);
      } else {
        setSuccess(false);
        setErrorMsg(response?.data?.exceptionMessage);
      }
    }).catch((e: any) => {
      toast.error(`${e?.response?.data?.message || e?.message}`);
      setLoading(false);

      setSuccess(true);
    });
  };

  const onRegister = () => {
    var redirect_uri = "https%3A%2F%2Fglass.floatfinops.io%2Fpostregistration";
    // below is test url
    // var redirect_uri = "http%3A%2F%2Flocalhost%3A3000%2Fpostregistration";
    const subscriptionId = subscriptionData?.subscription?.id || '';
    const url = `https://floatfinopsglass.b2clogin.com/floatfinopsglass.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUp&client_id=fa52bc86-e319-42c1-a89c-32e4b0593e73&nonce=defaultNonce&redirect_uri=${redirect_uri}&scope=openid&response_type=id_token&prompt=login&state=MarketPlace%3A${subscriptionId}&response_mode=query`;
    window.open(url, '_self');
  }

  return (
    <>
      <div className="bg-white shadow">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 justify-between">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <img
                  className="h-12 w-auto mt-4"
                  src={dbkProfile.imgUrl}
                  alt={dbkProfile.name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto px-6 py-8 sm:pt-8 lg:px-8">
        <div>
          <div className="text-center">
            <h1 className="mt-4 text-balance text-2xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Saas Subscribe Summary
            </h1>
            {loading ? (
              <Pulse show={loading} />
            ) : (
              <>
                {success ? 
                <>
                  <div className="p-6 bg-white shadow-lg rounded-lg">

                    {/* Subscription Section */}
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold text-gray-700 mb-4">Subscription</h3>
                      <ul className="divide-y divide-gray-300 bg-gray-50 rounded-lg p-4">
                        <li className="py-2 flex">
                          <span className="font-medium text-gray-600 w-32 text-left">ID:</span>
                          <span className="text-gray-800">{subscriptionData?.subscription?.id}</span>
                        </li>
                        <li className="py-2 flex">
                          <span className="font-medium text-gray-600 w-32 text-left">Name:</span>
                          <span className="text-gray-800">{subscriptionData?.subscription?.name}</span>
                        </li>
                        <li className="py-2 flex">
                          <span className="font-medium text-gray-600 w-32 text-left">Offer ID:</span>
                          <span className="text-gray-800">{subscriptionData?.subscription?.offerId}</span>
                        </li>
                      </ul>
                    </div>

                    {/* Purchaser Section */}
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold text-gray-700 mb-4">Purchaser</h3>
                      <ul className="divide-y divide-gray-300 bg-gray-50 rounded-lg p-4">
                        <li className="py-2 flex">
                          <span className="font-medium text-gray-600 w-32 text-left">Email ID:</span>
                          <span className="text-gray-800">{subscriptionData?.subscription?.purchaser?.emailId}</span>
                        </li>
                        <li className="py-2 flex">
                          <span className="font-medium text-gray-600 w-32 text-left">Object ID:</span>
                          <span className="text-gray-800">{subscriptionData?.subscription?.purchaser?.objectId}</span>
                        </li>
                        <li className="py-2 flex">
                          <span className="font-medium text-gray-600 w-32 text-left">Tenant ID:</span>
                          <span className="text-gray-800">{subscriptionData?.subscription?.purchaser?.tenantId}</span>
                        </li>
                      </ul>
                    </div>

                    {/* Term Section */}
                    <div>
                      <h3 className="text-lg font-semibold text-gray-700 mb-4">Term</h3>
                      <ul className="divide-y divide-gray-300 bg-gray-50 rounded-lg p-4">
                        <li className="py-2 flex">
                          <span className="font-medium text-gray-600 w-32 text-left">Term Unit:</span>
                          <span className="text-gray-800">{subscriptionData?.subscription?.term?.termUnit}</span>
                        </li>
                        <li className="py-2 flex">
                          <span className="font-medium text-gray-600 w-32 text-left">Start Date:</span>
                          <span className="text-gray-800">{subscriptionData?.subscription?.term?.startDate}</span>
                        </li>
                        <li className="py-2 flex">
                          <span className="font-medium text-gray-600 w-32 text-left">End Date:</span>
                          <span className="text-gray-800">{subscriptionData?.subscription?.term?.endDate}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                
                </>
                : 
                <p className="mt-6 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                  {errorMsg}
                </p>}
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <button
                    onClick={onRegister}
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Registraion
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
