import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";
import { StepView } from "../../../app/common/commonControls";
import Pulse from "../../../app/pulse/pulse";
import CloudResourceService from "../../../app/service/cloudResourceService";
import { toast } from "react-toastify";
import GeoMap from "../../../app/common/geo-map";
import PolicyService from "../../../app/service/policyService";
import Spinner from "../../../app/spinner/spinner";
import { CodeGroup } from "../../documents";
interface Props {
  show: boolean;
  closeModal: (refresh?: boolean) => void;
}

const defaultSteps = [
  {
    id: 1,
    name: "Choose Action",
    href: "#",
    status: commonService.stepStatus.CURRENT,
  },
];

const createSteps = [
  {
    id: 2,
    name: "Regions",
    href: "#",
    status: commonService.stepStatus.UPCOMING,
  },
  {
    id: 3,
    name: "Confirmation",
    href: "#",
    status: commonService.stepStatus.UPCOMING,
  },
];

export default function GovernanceOnboardingModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);

  const cancelButtonRef = useRef(null);

  const modalTitle = "Governance Onboarding";

  const [steps, setSteps] = useState<any[]>([]);
  const [selectedStep, setSelectedStep] = useState<any>(defaultSteps[0]);
  const [regions, setRegions] = useState<any>([]);
  const [loadingRegion, setLoadingRegion] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<any[]>([]);

  const [parseRegions, setParseRegions] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [assignmentCommand, setAssignmentCommand] = useState<string>("");
  const [definitionCommand, setDefinitionCommand] = useState<string>("");
  const [isNew, setIsNew] = useState<boolean>(true);

  const [loadingParameters, setLoadingParameters] = useState<boolean>(false);



  useEffect(() => {
    setOpen(props.show);
    if (props?.show) {
      initStep();
      getParameters();
      
    }
  }, [props.show]);

  const initStep = () => {
    setSteps(JSON.parse(JSON.stringify(defaultSteps)));
  };

  const getParameters = () => {
    setLoadingParameters(true);
    PolicyService.getAzurePolicyParameters()
      .then((response: any) => {
        setIsNew(!response?.data?.result);
        setLoadingParameters(false);
        if(!response?.data?.result) {
          getRegions("00000000-0000-0000-0000-000000000000");
        }
      })
      .catch((e: any) => {
        setLoadingParameters(false);
        toast.error(`${e?.response?.data?.message}`);
      });
  };

  const getRegions = (id: string) => {

    const regionApi = CloudResourceService.listAvailableRegionsBySubscription(id);
    const resourceApi = PolicyService.getResourceLocationDistribution();

    setLoadingRegion(true);

    Promise.all([regionApi, resourceApi]).then((responses: any) => {
      const region: any[] = [];
      const regionResult = responses[0]?.data?.result?.value || [];
      const resourceResult: DistributionItem[] = responses[1]?.data?.result?.distribution || [];
      const currentSelectedRegions: any[] = [];
      (regionResult || []).forEach((x: any, i: number) => {
        
        x.count = resourceResult.find(r => r.location === x.name)?.resourceCount || 0;

        let latitude = x.metadata?.latitude;
        let longitude = x.metadata?.longitude;

        const index = regionResult.findIndex((r: any, j: number) => r.name !== x.name && latitude === r.metadata?.latitude && longitude === r.metadata?.longitude && i < j);
        if(index > -1 && i < index) {
          latitude = (parseFloat(latitude) + 20).toString();
          longitude = (parseFloat(longitude) + 20).toString();
        }

        region.push({
          id: x.id,
          name: `${x.displayName} (${x.count})`,
          description: x.regionalDisplayName,
          latitude: latitude,
          longitude: longitude,
          count: x.count || 0,
        });
        if(x.count > 0) {
          currentSelectedRegions.push(x);
        }
      });
      setParseRegions(region);
      setRegions(regionResult);
      setSelectedRegion(currentSelectedRegions);
      setLoadingRegion(false);
    })
    .catch((e: any) => {
      setLoadingRegion(false);
      toast.error(`${e?.response?.data?.message}`);
    });
  };

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const onStepSelect = (step: any) => {
    setSelectedStep(step);
    steps.forEach((x) => {
      if (x.id === step.id) {
        x.status = commonService.stepStatus.CURRENT;
      } else if (x.id < step.id) {
        x.status = commonService.stepStatus.COMPLETE;
      } else {
        x.status = commonService.stepStatus.UPCOMING;
      }
    });
  };

  const onNext = () => {
    onStepSelect(steps[selectedStep.id]);
  };

  const goBack = () => {
    onStepSelect(steps[selectedStep.id - 2]);
  };

  const checkbox = useRef<any>(null);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checked, setChecked] = useState(false);

  const toggleAll = () => {
    setSelectedRegion(checked || indeterminate ? [] : regions || []);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const onCheck = (id: string) => {
    const i = (selectedRegion || []).findIndex((x) => x.id === id);
    const currentRegion = (regions || []).find((r: any) => r.id === id);
    if (i === -1) {
      if (currentRegion) {
        setSelectedRegion([...selectedRegion, currentRegion]);
      }
    } else {
      setTimeout(() => {
        
        const currentSelections: any[] = [];

        (regions || []).forEach((x: any) => {
          if(x.id !== id) {
            const isExist = selectedRegion.find(s => s.id === x.id);
            if(isExist) {
              currentSelections.push(x);
            }
          }
        });
      setSelectedRegion(currentSelections);
      }, 1);
      
    }
  };

  const createPolicyParameters = () => {
    const selectedParameters = (selectedRegion || [])
      .map((x) => x.name)
      .join(",");
    const body: CreateOrUpdateAzurePolicyParameterRequest = {
      azurePolicySetDefinitionName: "FloatFinOpsGovernanceInitiative",
      azurePolicySetCategory: "Location",
      parameters: [
        {
          parameterName: "listOfAllowedLocations",
          parameterValue: selectedParameters,
          parameterType: "Array",
        },
      ],
    };
    setLoading(true);
    PolicyService.createPolicyParameters(body)
      .then((response: any) => {
        setLoading(false);
        commonService.showMessage(response?.data || {});
        if (response?.data?.isSuccess) {
          init();
        }
      })
      .catch((e: any) => {
        toast.error(`${e?.response?.data?.message}`);
        setLoading(false);
      });
  };

  const init = () => {
    setIsNew(false);
    setSteps([...defaultSteps]);
    onStepSelect(defaultSteps[0]);
  }

  const getAssignmentCommand = () => {
    setLoading(true);

    const assignmentCommandApi = PolicyService.getAzurePolicyAssignmentCommand();
    const definitionCommandApi = PolicyService.getAzurePolicyDefinitionCommand();

    Promise.all([assignmentCommandApi, definitionCommandApi]).then((response: any) => {
      setLoading(false);
      if (response[0]?.data?.result) {
        setAssignmentCommand(parseCommand(response[0]?.data?.result));
      } else {
        commonService.showMessage(response?.data || {});
      }
      if (response[1]?.data?.result) {
        setDefinitionCommand(parseCommand(response[1]?.data?.result));
      } else {
        commonService.showMessage(response?.data || {});
      }
    })
    .catch((e: any) => {
      toast.error(`${e?.response?.data?.message}`);
      setLoading(false);
    });
  };

  const parseCommand = (command: AzurePolicyCommandResponse): string => {
    let parsedCommand = command?.baseCommand;
    (command.parameters || []).forEach((x) => {
      let parsedParameterValue = x.parameterValue;
      if (x.wrapSymbol) {
        
        if(x.wrapSymbol  === "\"") {
          x.wrapSymbol.replaceAll("\"",`"`);
        }
        parsedParameterValue = `${x.wrapSymbol}${parsedParameterValue}${x.wrapSymbol}`;
      }
      parsedCommand = `${parsedCommand} ${x.parameterName} ${parsedParameterValue}`;
    });
    return parsedCommand;
  };

  const onCreate = () => {
    setSteps([...defaultSteps, ...createSteps]);
    onStepSelect(createSteps[0]);
  };

  return (
    <>
      <ModalWrapper
        open={open}
        cancelButtonRef={cancelButtonRef}
        closeModal={closeModal}
      >
        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-3xl lg:max-w-7xl sm:p-6">
          <div>
            <div className="mt-3 sm:mt-5">
              <Dialog.Title
                as="h3"
                className="text-base text-center font-semibold leading-6 text-gray-900"
              >
                {modalTitle}
              </Dialog.Title>
              <div className="mt-2">
                <StepView steps={steps} onStepSelect={onStepSelect} />
                <div className="space-y-6 mt-8 px-4">
                  {selectedStep?.id === 1 && (
                    <div>
                      <div className="mt-2">
                        {loadingParameters ? (
                          <Pulse show={loadingParameters} />
                        ) : (
                          <div>
                            {!isNew ? (
                              <>
                                <div className="flex">
                                  <button
                                    type="button"
                                    disabled={loading}
                                    className={commonService.classNames(
                                      "mr-4 flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                                      loading ? "disabled:opacity-75" : ""
                                    )}
                                    onClick={getAssignmentCommand}
                                  >
                                    <Spinner show={loading} />
                                    Get the assignment command
                                  </button>
                                  <button
                                    type="button"
                                    className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    Edit Initiative
                                  </button>
                                </div>

                               {definitionCommand && <div className="mt-4">
                                <h3>Definition Command:</h3>
                                  <CodeGroup
                                    title="Azure CLI"
                                    code={definitionCommand}
                                    tag=""
                                  >
                                      <p>{definitionCommand}</p>
                                  </CodeGroup>
                                </div>}

                                {assignmentCommand && <div className="mt-4">
                                <h3>Assignment Command:</h3>
                                  <CodeGroup
                                    title="Azure CLI"
                                    code={assignmentCommand}
                                    tag=""
                                  >
                                      <p>{assignmentCommand}</p>
                                  </CodeGroup>
                                </div>}
                              </>
                            ) : (
                              <button
                                type="button"
                                className="flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={onCreate}
                              >
                                Create your initiative
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {selectedStep?.id === 2 && (
                    <div>
                      <div className="mt-2">
                        {loadingRegion ? (
                          <Pulse show={loadingRegion} />
                        ) : (
                          <div>
                            <div className="mb-4 flex">
                              <div className="min-w-[15rem] max-h-[30rem] overflow-auto">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="relative px-7 sm:w-12 sm:px-6"
                                      >
                                        <input
                                          type="checkbox"
                                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                          ref={checkbox}
                                          checked={checked}
                                          onChange={toggleAll}
                                        />
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                                      >
                                        Name
                                      </th>
                                    </tr>
                                  </thead>
                                  {(regions || [])?.length > 0 && (
                                    <tbody className="divide-y divide-gray-200">
                                      {(regions || [])?.map(
                                        (region: any, i: number) => (
                                          <tr
                                            key={region.id}
                                            className={
                                              selectedRegion.includes(region)
                                                ? "bg-gray-50"
                                                : undefined
                                            }
                                          >
                                            <td className="relative px-7 sm:w-12 sm:px-6">
                                              {selectedRegion.includes(
                                                region
                                              ) && (
                                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                              )}
                                              <input
                                                type="checkbox"
                                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                value={region.id}
                                                checked={selectedRegion.includes(
                                                  region
                                                )}
                                                onChange={(e) =>
                                                  setSelectedRegion(
                                                    e.target.checked
                                                      ? [
                                                          ...selectedRegion,
                                                          region,
                                                        ]
                                                      : selectedRegion.filter(
                                                          (p) => p !== region
                                                        )
                                                  )
                                                }
                                              />
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                              {`${region.name} (${region.count})`}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  )}
                                </table>
                              </div>

                              <div className="w-full">
                                <GeoMap
                                  regions={parseRegions || []}
                                  selectedRegions={selectedRegion || []}
                                  onCheck={onCheck}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {selectedStep?.id === 3 && (
                    <div>
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-lg font-semibold text-gray-900"
                            >
                              Name
                            </th>
                          </tr>
                        </thead>
                        {(regions || [])?.length > 0 && (
                          <tbody className="divide-y divide-gray-200">
                            {(selectedRegion || [])?.map((region: any) => (
                              <tr
                                key={region.id}
                                className={
                                  selectedRegion.includes(region)
                                    ? "bg-gray-50"
                                    : undefined
                                }
                              >
                                <td className="whitespace-nowrap px-3 py-2 text-sm font-small text-gray-900">
                                  {`${region.name} (${region.count})`}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className={commonService.classNames(
              "mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3",
              selectedStep?.id === 1 ? "sm:grid-cols-2" : "sm:grid-cols-3"
            )}
          >
            {selectedStep?.id === 3 && (
              <button
                type="submit"
                disabled={loading}
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-3",
                  loading ? "disabled:opacity-75" : ""
                )}
                onClick={createPolicyParameters}
              >
                <Spinner show={loading} />
                Save
              </button>
            )}
            {selectedStep?.id < steps.length && (
              <button
                type="button"
                id="cloudResourceNextBtn"
                disabled={loadingRegion || loading}
                className={commonService.classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                  selectedStep?.id === 1 ? "sm:col-start-2" : "sm:col-start-3",
                  loadingRegion || loading ? "disabled:opacity-75" : ""
                )}
                onClick={() => onNext()}
              >
                Next
              </button>
            )}
            {selectedStep?.id > 1 && (
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
                onClick={() => goBack()}
                id="cloudResourceBackBtn"
              >
                Back
              </button>
            )}
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              onClick={() => closeModal()}
              ref={cancelButtonRef}
              id="cloudResourceCancelBtn"
            >
              Cancel
            </button>
          </div>
        </Dialog.Panel>
      </ModalWrapper>
    </>
  );
}
