import { Fragment, useEffect, useState } from "react";
import CloudResourceService from "../../app/service/cloudResourceService";
import { toast } from "react-toastify";
import Pulse from "../../app/pulse/pulse";
import commonService from "../../app/service/commonService";
import Spinner from "../../app/spinner/spinner";
import AccountModal from "../initiatives/modal/accountModal";
import { useSearchParams } from "react-router-dom";

export default function ResellerUnmappedSubscripitons(){
    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [unmappedSubscriptions, setUnmappedSubscriptions] = useState<UnmappedTenantItem[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<AccountItem>();
    const [searchParams] = useSearchParams();
    const customerId = searchParams.get("customerId");
    const [currentAccountId, setCurrentAccountId] = useState<string>("");

    
    useEffect(() => {
        setCurrentAccountId(
            commonService.selectedAccount?.id ||
              customerId ||
              commonService.userDetail()?.accountId
          );
        getAllUnmappedSubscriptions();
        
    }, []);

    const getAllUnmappedSubscriptions = () => {
        setLoading(true);
        CloudResourceService.getListUnbindedSubscriptions()
          .then((response: any) => {
            const result: UnmappedTenantItem[] = response?.data?.result || [];
            setUnmappedSubscriptions(result)
            setLoading(false);
          })
          .catch((e: any) => {
            toast.error(`${e?.response?.data?.message}`);
            setLoading(false);
          });
      };

    const openCreateAccount = (item: UnmappedTenantItem) => {
        const account : AccountItem = {
            parentId: currentAccountId,
            mATenantId: item.tenantId,
            id: "",
            name: "",
            size: "",
            website: "",
            isActive: true,
            users: []
        };
        setSelectedAccount(account);
        setShowCreateAccountModal(true);
    };

    const closeCreateAccountModal = () => {
        setShowCreateAccountModal(false);
    };

    const refreshUnmappedSubscriptions = (refresh?: boolean) => {
        if (refresh) {
            getAllUnmappedSubscriptions();
        }
    };

    return (
        <>
            {showCreateAccountModal && (
                <AccountModal
                show={showCreateAccountModal}
                account={selectedAccount}
                isEdit={false}
                closeModal={closeCreateAccountModal}
                refreshDetails={refreshUnmappedSubscriptions}
                />
            )}

            <div className="py-10 mx-auto  px-4 sm:px-6 lg:px-8">
                <main>
                    <div className="divide-y divide-white/5">
                        <div className="grid  grid-cols-1 gap-x-8 gap-y-10 px-4 py-2 sm:px-6 lg:px-8">
                        {loading ? (
                            <Pulse show={loading} />
                        ) : (
                            <>
                            <div className="flex">
                                <button
                                    id="reseller-account-btn"
                                    type="button"
                                    disabled={loading}
                                    className={commonService.classNames(
                                        "mr-4 block rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
                                        loading ? "disabled:opacity-75" : ""
                                    )}
                                    onClick={() => getAllUnmappedSubscriptions()}
                                    >
                                    <Spinner show={loading} />
                                    Refresh
                                </button>
                                
                            </div>
                            <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-white">
                                <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                                    Subscription Name
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Subscription ID
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Assigned Role
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                    <span className="sr-only">Create</span>
                                </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {unmappedSubscriptions.map((item) => (
                                <Fragment key={item.tenantId}>
                                    <tr className="border-t border-gray-300 bg-gray-50">
                                    <th
                                        colSpan={5}
                                        scope="colgroup"
                                        className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                    >
                                        <div className="flex justify-between items-center">
                                        <span>Tenant ID: {item.tenantId}</span>
                                        <button className="text-indigo-600 hover:text-indigo-900" onClick={() => openCreateAccount(item)}>
                                            Create Account<span className="sr-only">, {item.tenantId}</span>
                                        </button>
                                        </div>
                                    </th>
                                    </tr>
                                    {item.subscriptions.map((subscription, subIdx) => (
                                    <tr
                                        key={subscription.subscriptionId}
                                        className={subIdx === 0 ? 'border-t border-gray-300' : 'border-t border-gray-200'}
                                    >
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                        {subscription.subscriptionName}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{subscription.subscriptionId}</td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xs overflow-hidden overflow-ellipsis"
                                            title={subscription.assignedRoles.length > 0 ? subscription.assignedRoles.sort().join(", ") : ""}>
                                            {subscription.assignedRoles.length > 0 ? subscription.assignedRoles.sort().join(", ") : ""}
                                        </td>
                                    </tr>
                                    ))}
                                </Fragment>
                                ))}
                            </tbody>
                            </table>

                            </>
                        )}
                        </div>
                    </div>
                </main>
            </div>
        </>
      );
}