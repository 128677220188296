import {Chart, registerables} from "chart.js";
import {topojson, GeoFeature, ProjectionScale, ColorScale, BubbleMapController, SizeScale} from "chartjs-chart-geo";
import { useEffect } from "react";

import mapData from '../../assets/json/worldMap.json';

interface Props {
  regions: any[];
  selectedRegions: any[];
  onCheck: (id: string) => void
}

export default function GeoMap(props: Readonly<Props>) {

  useEffect(() => {
    Chart.register(GeoFeature, ProjectionScale, ColorScale, ...registerables, BubbleMapController, SizeScale);

    if(mapData) {
      createChart(mapData);
    }
     
  }, [props?.selectedRegions]);

  const createChart = (data: any) => {
    const div = document.getElementById('geo-map-id') as any;
    const canvas = document.getElementById("canvas") as any;
    div.removeChild(canvas);
    const elemCanvas = document.createElement('canvas');
    elemCanvas.id = 'canvas';
    div.appendChild(elemCanvas);

    const countries = (topojson.feature(data, data.objects.countries) as any).features;
    const regions = props?.regions || [];
    const colors = getColors() || [];
    new Chart((document.getElementById("canvas") as any).getContext("2d"), {
      type: 'bubbleMap',
      data: {
        labels: regions.map((d: any) => d.name),
        datasets: [{
          outline: countries,
          showOutline: true,
          backgroundColor: colors,
          data: regions.map((d: any) => Object.assign(d), {value:0}),
          outlineBackgroundColor: '#f2f2f2',
          showGraticule: false
        }],

      },
      options: {
        elements: {
          point: {
            radius: 4,
            hoverRadius: 7
          }
        },
        showOutline: false,
        showGraticule: false,
        
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: (item: any) => {
                return item?.raw?.name || '';
              }
            }
          }
          
        },
        scales: {
          projection: {
            axis: 'x',
            projection: 'equalEarth'
          },
          size: {
            axis: 'x',
            mode: 'area',
            display: false
          }
        },
        onClick: (evt, elems) => {
          onCheck(evt, elems);
        }
      }
    });
  }

  const onCheck = (_: any, elems: any) => {
    const selectedId = elems[0]?.element?.$context?.raw?.id || 0;
    if(selectedId) {
      props.onCheck(selectedId);
    }
  }

  const getColors = () => {
    let colors: string[] = [];
    (props?.regions || []).forEach(x => {
      let color = '';
      const isSelected = (props?.selectedRegions || []).find(s => s.id === x.id);
      if(isSelected) {
        if(x.count > 0) {
          color = 'green';
        } else {
          color = 'blue';
        }
      } else {
        if(x.count > 0) {
          color = 'red';
        } else {
          color = 'grey';
        }
      }
      colors.push(color);
    });
    return colors;
  }

  return (
    <>
    <div id="geo-map-id">
    <canvas id="canvas"></canvas>
    </div>
    </>
  )
  
}