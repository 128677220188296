import { useRef, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import commonService from "../../../app/service/commonService";
import { ModalWrapper } from "../../common/modalWrapper";
interface Props {
  show: boolean;
  closeModal?: any;
  accounts: AccountItem[];
  selectedAccount: AccountItem | undefined;
}

export default function AccountSelectModal(props: Readonly<Props>) {
  const [open, setOpen] = useState(props.show);
  const [selectedAccount, setSelectedAccount] = useState<
    AccountItem | undefined
  >(props?.selectedAccount);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setOpen(props.show);
    if (props.show) {
      setSelectedAccount(props?.selectedAccount);
    }
  }, [props.show]);

  const closeModal = (refresh?: boolean) => {
    setOpen(false);
    props.closeModal(refresh);
  };

  const onSelectAccount = (account: AccountItem) => {
    setSelectedAccount(account);
  };

  const updateAccount = () => {
    if (selectedAccount && props?.selectedAccount?.id !== selectedAccount?.id) {
      window.open(`/?customerId=${selectedAccount?.id}`, "_blank");
      closeModal();
    }
  };

  return (
    <ModalWrapper
      open={open}
      cancelButtonRef={cancelButtonRef}
      closeModal={closeModal}
    >
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-sm sm:w-full md:max-w-sm lg:max-w-sm sm:p-6">
        <div>
          <div className="mt-3 sm:mt-5">
            <Dialog.Title
              as="h3"
              className="text-base text-center font-semibold leading-6 text-gray-900"
            >
              Update Account
            </Dialog.Title>
            <div className="h-96 overflow-auto">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Select
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {(props?.accounts || []).map((account) => (
                    <tr key={account?.id}>
                      <td className="px-3 py-5 text-sm text-gray-500 w-1/12">
                        <input
                          id={account?.id}
                          name="roles"
                          type="radio"
                          value={account?.id}
                          checked={account?.id === selectedAccount?.id}
                          onChange={() => onSelectAccount(account)}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </td>
                      <td className="px-3 py-5 text-sm text-gray-500">
                        {account?.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            disabled={
              !selectedAccount ||
              props?.selectedAccount?.id === selectedAccount.id
            }
            className={commonService.classNames(
              "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2",
              !selectedAccount ||
                props?.selectedAccount?.id === selectedAccount?.id
                ? "disabled:opacity-75"
                : ""
            )}
            onClick={() => updateAccount()}
          >
            Update
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
            onClick={() => closeModal()}
            ref={cancelButtonRef}
          >
            Cancel
          </button>
        </div>
      </Dialog.Panel>
    </ModalWrapper>
  );
}
